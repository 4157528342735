import Error404 from "@/assets/404-error.png";
import { useTranslation } from "react-i18next";
import { FramerButton } from "@/components/shared";

function NotFound() {
  const { t } = useTranslation();

  return (
    <div className="fixed flex w-screen flex-col h-screen bg-mfpurple/5 place-content-center font-Montserrat">
      <div className="md:flex items-center justify-center select-none mx-4">
        {/* Illustration */}
        <div className="flex justify-center">
          <img
            alt="Error 404 illustration"
            className="max-w-[400px] lg:max-w-[500px]"
            src={Error404}
          />
        </div>
        {/* Text */}
        <div className="flex flex-col justify-center text-center">
          <p className="text-6xl md:text-7xl lg:text-9xl font-bold">
            {t("404page.title")}
          </p>
          <p className="text-xl md:text-2xl lg:text-4xl font-semibold">
            {t("404page.subtitle")}
          </p>
          <p className="text-lg lg:text-xl font-light">
            {t("404page.phrase")}
          </p>
          <div className="flex justify-center">
            <FramerButton
              onClick={() => history.back()}
              className="mf-button mf-primary mt-4 hover:bg-opacity-70 max-w-[100px]"
            >
              {t("404page.back")}
            </FramerButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
