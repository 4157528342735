import { UsersPageModals } from "@/constants/enums";
import { useCreateUserMutation, GetPaginatedUsersDocument } from "@/graphql/__generated__/graphql-operations";
import { FormField } from "@/types";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";
import { UseFormReset } from "react-hook-form";
import { toast } from "react-toastify";

export default function useCreateUserMutationHook<T extends FormField>(setShowCreateUser: Dispatch<SetStateAction<UsersPageModals>>, reset?: UseFormReset<T>) {
  const [ createUserMutation, { loading, error }] = useCreateUserMutation({
    async onCompleted() {
      // Close Modal
      setShowCreateUser(UsersPageModals.NoModal);

      toast.success(t("toasts:createUserSuccessToast"), { toastId: "createUserSuccessToast" });

      // Reset the form
      if(reset) {
        reset();
      }
    }, onError() {
      toast.error(t("toasts:createUserFailureToast"), { toastId: "createUserFailureToast" });
    },
    update(client, { data }) {
      // Update cache with new user
      const paginatedUsersQuery: any = client.readQuery({ query: GetPaginatedUsersDocument });
      if (!paginatedUsersQuery) return;

      const newPaginatedUsersQuery = {
        paginatedUsers: {
          __typename: "UserConnection",
          edges: [
            {
              __typename: "UserEdge",
              node: {
                ...data?.createUser,
              },
              cursor: new Date().toISOString(),
            },
            ...paginatedUsersQuery.paginatedUsers.edges,
          ],
          pageInfo: paginatedUsersQuery.paginatedUsers.pageInfo,
        }
      };

      client.writeQuery({
        query: GetPaginatedUsersDocument,
        data: newPaginatedUsersQuery,
      });
    },
  });

  return {
    createUserMutation,
    loading,
    error,
  };
}
