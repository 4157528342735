import { t } from "i18next";
import countriesData from "@/assets/country-data/country-codes.json";
import FormWrapper from "../FormWrapper";
import { useEditCompanyMutationHook } from "@/hooks/mutations";
import { useAddressData, useCompanyData } from "@/hooks";
import { CompanyAddressFragment } from "@/graphql/__generated__/graphql-operations";
import { AddressDefaultValues, AddressSchema, TAddressSchema } from "@/models";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { onInvalid } from "@/utils/form";
import FormInput from "@/components/shared/form/FormInput";
import FormSelect from "@/components/shared/form/FormSelect";
import { useEffect } from "react";
import { toast } from "react-toastify";

/**
 * Represents a form component for managing address details.
 *
 * @returns {JSX.Element} The rendered AddressForm component.
 */
function AddressForm(): JSX.Element {
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<TAddressSchema>({
    resolver: zodResolver(AddressSchema),
    defaultValues: AddressDefaultValues,
    mode: "onChange",
  });

  // Hooks
  const company = useCompanyData();
  const addressFragmentData: CompanyAddressFragment["address"] | undefined = useAddressData({ id: company?._id as string, typename: company?.__typename as string });
  // Mutations
  const { editCompany } = useEditCompanyMutationHook();

  // Set the form values to the cached address data
  useEffect(() => {
    if (addressFragmentData && company) {
      setFormValues();
    }
  }, [addressFragmentData, setValue]);

  /**
   * Sets the form values to the cached address data.
   */
  const setFormValues = () => {
    Object.entries(addressFragmentData as CompanyAddressFragment["address"]).forEach(([key, value]) => {
      setValue(key as keyof TAddressSchema, value);
    });
  };

  /**
   * Retrieves the changed values between the form values and the cached values.
   *
   * @template T - The type of the form values.
   * @param {T} formValues - The current form values.
   * @param {Partial<T>} cachedValues - The cached form values.
   * @returns {Partial<T>} - The changed values between the form values and the cached values.
   */
  function getChangedValues<T extends object>(
    formValues: T,
    cachedValues: Partial<T>
  ): Partial<T> {
    const changedValues: Partial<T> = {};

    Object.keys(formValues).forEach((key) => {
      if (formValues[key] !== cachedValues[key]) {
        changedValues[key as keyof T] = formValues[key as keyof T];
      }
    });

    return changedValues;
  }

  /**
   * Handles the form submission for updating the company address.
   *
   * @param {TAddressSchema} data - The updated address data.
   */
  const onSubmit: SubmitHandler<TAddressSchema> = (data: TAddressSchema) => {
    if (isDirty && isValid) {
      const changedData = getChangedValues(data, { ...addressFragmentData });

      if (Object.keys(changedData).length > 0) {
        editCompany(
          {
            id: company?._id as string,
            companyInput: {
              address: changedData,
            },
          },
          changedData,
          "Address"
        );
      } else {
        toast.info("No changes detected", { toastId: "noChanges" });
      }
    }
  };

  const topRowFields = [
    {
      key: "address-input-street",
      name: "street",
      title: "Street",
      type: "text",
      label: t("settings.address.street"),
      required: true,
      className: "mf-input-field md:min-w-[150px]",
      divClassName: "w-full md:w-1/2 md:pr-2",
      defaultValue: addressFragmentData?.street ?? "",
    },
    {
      key: "address-input-housenumber",
      name: "housenumber",
      title: "House number",
      type: "text",
      label: t("settings.address.housenumber"),
      required: true,
      className: "mf-input-field md:min-w-[150px]",
      divClassName: "w-full md:w-1/4",
      defaultValue: addressFragmentData?.housenumber ?? "",
    },
  ];

  const middleRowFields = [
    {
      key: "address-input-city",
      name: "city",
      title: "City",
      type: "text",
      label: t("settings.address.city"),
      required: true,
      className: "mf-input-field md:min-w-[150px]",
      divClassName: "w-full md:w-1/2 md:pr-2",
      defaultValue: addressFragmentData?.city ?? "",
    },
    {
      key: "address-input-zipcode",
      name: "zipcode",
      title: "Zip code",
      type: "text",
      label: t("settings.address.zipcode"),
      required: true,
      className: "mf-input-field md:min-w-[150px]",
      divClassName: "w-full md:w-1/4",
      defaultValue: addressFragmentData?.zipcode ?? "",
    },
  ];

  const countryField = {
    key: "address-input-country",
    name: "country",
    title: "Country",
    type: "text",
    label: t("settings.address.country"),
    required: true,
    className: "mf-input-field md:min-w-[150px]",
    divClassName: "w-full",
    defaultValue: addressFragmentData?.country ?? "",
    errorMessage: "",
    dropdownOptions: countriesData?.map(({ name }) => ({
      text: name,
      value: name,
    })),
  };

  return (
    <FormWrapper
      id="address-form"
      className="mt-4"
      onClickReset={setFormValues}
      handleSubmit={handleSubmit(onSubmit, onInvalid)}
    >
      <>
        <h3 className="font-semibold text-sm mb-4 select-none">
          {t("settings.address.title")}
        </h3>
        <div>
          <div className="flex flex-col md:flex-row md:gap-2">
            {topRowFields.map((field) => (
              <div key={field.key} className={field.divClassName}>
                <FormInput
                  field={field}
                  errors={errors}
                  register={register}
                  watch={watch}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col md:flex-row md:gap-2 mt-2">
            {middleRowFields.map((field) => (
              <div key={field.key} className={field.divClassName}>
                <FormInput
                  field={field}
                  errors={errors}
                  register={register}
                  watch={watch}
                />
              </div>
            ))}
          </div>
          <div className="w-full md:w-1/2 md:pr-2">
            <FormSelect
              field={countryField}
              errors={errors}
              register={register}
              watch={watch}
            />
          </div>
        </div>
      </>
    </FormWrapper>
  );
}

export default AddressForm;
