import { SharedModals, TransactionsModals, TxResult } from "@/constants/enums";
import { CompanyDetailsFragment } from "@/graphql/__generated__/graphql-operations";
import { useState } from "react";
import {
  AddTransactionModal,
  WithdrawalTransactionSummaryModal,
  TransactionResultModal
} from "./modals";
import { useAddTokenTransactionMutationHook } from "@/hooks/mutations";
import { OWNER_ADDRESS } from "@/blockchain/connectors";
import { approve } from "@/blockchain";
import { LoadingModal } from "@/components/modals";
import { useTranslation } from "react-i18next";
import { FramerButton } from "../shared";

type Props = {
  company: CompanyDetailsFragment | null;
}

function Transaction({ company }: Props) {
  // States
  const [amount, setAmount] = useState<number>(0);
  const [transactionResultType, setTransactionResultType] = useState<TxResult>(TxResult.SUCCESS);
  const [showModal, setShowModal] = useState<TransactionsModals | SharedModals>(TransactionsModals.NoModal);

  // Mutation hooks
  const { addTokenTransactionMutation } = useAddTokenTransactionMutationHook();

  // Hooks
  const { t } = useTranslation(["components"]);

  /**
   * Handler function to add a token transaction
   * @param type TokenTransactionType
   * @returns
   */
  async function handleTransactionMutation() {
    if(!company) return;
    if(amount === 0) return;

    // Modal logic
    setShowModal(TransactionsModals.Loading);
    const approved = await approve(company.publickey, OWNER_ADDRESS, amount);

    if(!approved) {
      setTransactionResultType(TxResult.ERROR);
      // Modal logic
      setShowModal(TransactionsModals.TransactionResult);
      return;
    }

    addTokenTransactionMutation({
      variables: {
        id: company._id,
        tokenTransactionInput: {
          amount
        }
      },
      onCompleted: (data) => {
        if(data.addTokenTransaction) {
          setTransactionResultType(TxResult.SUCCESS);
        }
      },
      onError: () => {
        setTransactionResultType(TxResult.ERROR);
      }
    }).then(() => {
      // Show result modal
      setShowModal(TransactionsModals.TransactionResult);
    });
  }

  function handleTransactionOpen() {
    setShowModal(TransactionsModals.AddTransaction);
  }

  const sharedProps = {
    amount: amount,
    setAmount: setAmount,
    showModal: showModal,
    setShowModal: setShowModal,
  };

  return (
    <div>
      <FramerButton
        whileHover={{ backgroundColor: "#EE5300", color: "#fff" }}
        onClick={() => handleTransactionOpen()}
        className="mf-eurm-shortcut"
      >
        <p className="lg:flex ml-1 font-light">
          {t("header.deposit.deposit")}<span className="hidden lg:inline">&nbsp;/&nbsp;{t("header.deposit.withdraw")}</span>
        </p>
      </FramerButton>

      {/* MODALS */}
      {// Add transaction modal
        showModal === TransactionsModals.AddTransaction &&
          <AddTransactionModal
            {...sharedProps}
            referencenumber={company?.referencenumber || ""}
          />
      }
      {
        // Withdrawal summary modal
        showModal === TransactionsModals.WithdrawalSummary &&
          <WithdrawalTransactionSummaryModal
            {...sharedProps}
            handleTransactionMutation={handleTransactionMutation}
          />
      }
      {
        // Transaction result modal
        showModal === TransactionsModals.TransactionResult &&
          <TransactionResultModal
            {...sharedProps}
            referencenumber={company?.referencenumber || ""}
            transactionResultType={transactionResultType}
          />
      }
      {
        // Loading modal
        showModal === TransactionsModals.Loading &&
          <LoadingModal
            {...sharedProps}
            loadingText={t("toasts:sendingApproval")}
          />
      }
    </div>
  );
}

export default Transaction;
