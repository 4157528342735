import { inputFields } from "@/pages/ManageUsers/inputfields";
import { DropDownOption, FormField } from "@/types/shared";
import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { useTranslation } from "react-i18next";
import { CompanyType, UserRole, useGetPaginatedCompaniesQuery } from "@/graphql/__generated__/graphql-operations";
import { UserType, UsersPageModals } from "@/constants/enums";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCreateUserMutationHook } from "@/hooks/mutations";
import { CreateUserDefaultValues, CreateUserSchema, TCreateUserSchema } from "@/models/CreateUser";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { onInvalid } from "@/utils/form";
import { FormInput, FormSelect, FramerButton } from "@/components/shared";

type Props = {
  user: any;
  showModal?: boolean;
  hasErrors: boolean;
  setShowModal: Dispatch<SetStateAction<UsersPageModals>>;
  setHasErrors: (_value: boolean) => void;
}

// Constants
const userRoles = {
  dev: [UserRole.User, UserRole.Admin],
  user: [UserRole.User, UserRole.Superuser]
};

/**
 * Renders a modal for creating a new user.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {User} props.user - The currently logged in user object.
 * @param {Function} props.setShowModal - The function to set the modal visibility.
 * @returns {JSX.Element} The rendered CreateUserModal component.
 * @example
 * ```tsx
 *
 * const [showModal, setShowModal] = useState<UsersPageModals>(UsersPageModals.NoModal);
 *
 * // Example usage of CreateUserModal
 * return (
 *  <>
 *    {
 *      showModal === UsersPageModals.CreateUser &&
 *        <CreateUserModal
 *          user={user}
 *          hasErrors={hasErrors}
 *          setHasErrors={setHasErrors}
 *          setShowModal={setShowModal}
 *        />
 *     }
 *  </>
 * )
 * ```
 */
function CreateUserModal(props: Props): JSX.Element {

  // Props
  const { user, setShowModal } = props;

  // Initialize form with react-hook-form
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    watch,
    reset,
    formState: {
      errors,
      isValid
    }
  } = useForm<TCreateUserSchema>({
    resolver: zodResolver(CreateUserSchema),
    defaultValues: CreateUserDefaultValues,
    mode: "onChange"
  });

  // Hooks
  const { t } = useTranslation();

  // States
  const [rolesToDisplay, setRolesToDisplay] = useState<UserRole[]>([]);
  // Form constants
  const selectedCompanyID = watch("employerID");

  // Effect to set roles to display based on selected company
  useEffect(() => {
    if(selectedCompanyID === "DEVELOPER") {
      setRolesToDisplay(userRoles.dev);
    }
    else {
      setRolesToDisplay(userRoles.user);
    }
  }, [selectedCompanyID]);


  // Handle user employerID setting
  useEffect(() => {
    if (user.employer) {
      setValue("employerID", user.employer._id);
    } else {
      setValue("employerID", "");
    }
  }, [getValues]);

  // Fetch companies if the user is a Developer
  const { data: companiesData, loading: companiesLoading } = useGetPaginatedCompaniesQuery({
    variables: { first: 100 },
    skip: user?.__typename !== UserType.Developer,
  });

  const { createUserMutation } = useCreateUserMutationHook<TCreateUserSchema>(setShowModal, reset);

  /**
 * Handles form submission to create a new user.
 *
 * @param {TCreateUserSchema} data - The form data containing user details.
 * @returns {Promise<void>}
 */
  const onSubmit: SubmitHandler<TCreateUserSchema> = async (data: TCreateUserSchema): Promise<void> => {
    if (!user || !isValid) return;

    toast.loading("Creating user", { toastId: "createUserLoading" });

    // Destructure data to separate employerID and the rest of the user data
    const { employerID, ...restUser } = data;

    try {
      const isDeveloper = user.__typename === UserType.Developer;
      const variables = {
        createUserInput: isDeveloper && employerID === "DEVELOPER" ? restUser : { employerID, ...restUser },
      };

      // Execute the mutation to create the user
      await createUserMutation({ variables });

    } catch (error: any) {
      toast.error(error.message, { toastId: "createUserError" });
    } finally {
      toast.dismiss("createUserLoading");
    }
  };

  const companyOptions: DropDownOption[] = [];
  if (user?.__typename === UserType.Developer && !companiesLoading && companiesData) {
    companiesData.paginatedCompanies.edges.forEach(edge => {
      const company = edge.node;
      if (company.companytype !== CompanyType.Customer) {
        companyOptions.push({ text: company.name, value: company._id });
      }
    });
  }

  companyOptions.push({
    text: "Myron Developer",
    value: "DEVELOPER"
  });

  return (
    <BlankModal
      setShowModal={() => setShowModal(UsersPageModals.NoModal)}
      title="Create User"
      className="px-6"
      customContentCSS="mf-modal-content lg:min-w-[580px]"
    >
      <form id="create-user-form" onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <BlankModalContent>
          <div className="pt-2">
            {inputFields.map((field: FormField) => {
              if (field.name === "employerID" && user.__typename !== UserType.Developer) return null;

              if (field.name === "employerID" && (user.__typename === UserType.Developer || user.role === UserRole.Admin)) {
                return (
                  <FormSelect
                    key={`createuser-select-${field.name}`}
                    field={{ ...field, dropdownOptions: companyOptions }}
                    errors={errors}
                    register={register}
                  />
                );
              }

              if (field.name === "role") {
                return (
                  <FormSelect
                    key={`createuser-select-${field.name}`}
                    field={{ ...field, dropdownOptions: rolesToDisplay.map(role => ({ text: role, value: role })) }}
                    errors={errors}
                    register={register}
                  />
                );
              }

              return (
                <FormInput
                  key={`createuser-input-${field.name}`}
                  field={field}
                  errors={errors}
                  register={register}
                />
              );
            })}
          </div>
        </BlankModalContent>
        <BlankModalFooter>
          <div className="flex justify-between">
            <FramerButton
              onClick={() => setShowModal(UsersPageModals.NoModal)}
              className="mf-btn-action-large-primary-outline"
            >
              {t("common:buttons.cancel")}
            </FramerButton>
            <FramerButton
              id="createUserAdminButton"
              form="create-user-form"
              className="mf-btn-action-large-primary-filled"
              type={isValid ? "submit" : "button"}
            >
              {t("manageusers.buttons.createuser")}
            </FramerButton>
          </div>
        </BlankModalFooter>
      </form>
    </BlankModal>
  );
}

export default CreateUserModal;
