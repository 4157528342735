import { useFragment } from "@apollo/client";
import {
  CompanyAddressFragment,
  CompanyAddressFragmentDoc,
} from "@/graphql/__generated__/graphql-operations";

/**
 * Custom hook to retrieve and subscribe to address data for a specific company.
 *
 * This hook uses the `useFragment` hook from Apollo Client to automatically
 * subscribe to changes in the company's address data.
 *
 * @param {{string, string}} {id, typename} - The company from which to retrieve the company details.
 * @returns {CompanyAddressFragment["address"] | undefined} - The address data or undefined if not available.
 *
 * @example
 * const company = useCompanyData(); // Fetch or retrieve the company data
 * const address = useAddressData(company);
 *
 * if (address) {
 *   console.log("Street:", address.street);
 *   console.log("City:", address.city);
 * }
 */
export const useAddressData = ({ id, typename }: {id: string, typename: string}): CompanyAddressFragment["address"] | undefined => {
  const { data } = useFragment({
    fragment: CompanyAddressFragmentDoc,
    from: {
      __typename: typename,
      _id: id,
    }
  });

  return data?.address;
};
