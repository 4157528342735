import {
  AssetFinancingPool,
  AssetFinancingPoolEdge,
  AssetFinancingPoolMoreFragmentDoc,
  GetPaginatedAssetFinancingPoolsQuery,
  GetPaginatedAssetFinancingPoolsQueryVariables,
  useGetPaginatedAssetFinancingPoolsQuery,
} from "@/graphql/__generated__/graphql-operations";
import { InvestmentTransactionModals, SharedModals, TxResult } from "@/constants/enums";
import {
  InvestmentTransactionResultModal,
  InvestmentTransactionSummaryModal,
  LoadingModal,
  MakeInvestmentModal
} from "@/pages/Investment/modals";
import { useCompanyData, useEmployeeData } from "@/hooks";
import { useEffect, useRef, useState } from "react";
import InvestmentFilter from "./InvestmentFilter";
import { InvestmentPageState } from "@/types/makeinvestment";
import { Pagination, PaginationContentWrapper, PaginationContentScrollable } from "@/components/shared";
import SPCardSkeleton from "@/components/skeletons/SPCardSkeleton";
import ServiceProviderInvestCard from "@/pages/Investment/ServiceProviderInvestCard";
import { updateInvestmentState } from "@/cache/appstate/WriteQueries";
import { useInvestmentPageState } from "@/hooks/cache/appState";
import useScrollToTop from "@/hooks/useScrollToTop";
import { ScrollToTopButton } from "@/components/shared";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { client } from "@/client";

interface PaginationRef {
  triggerHandleFilterSubmit: (_newVariables: Partial<GetPaginatedAssetFinancingPoolsQueryVariables>) => void;
}

function Investment() {
  // Ref use for Scroll To Top
  const scrollRef = useRef<HTMLDivElement>(null);

  // Hooks
  const user = useEmployeeData();
  const company = useCompanyData();
  const { isVisible, scrollToTop } = useScrollToTop({ ref: scrollRef });
  const { t } = useTranslation(["components"]);

  // URL params
  const [searchParams] = useSearchParams();
  const afpId = searchParams.get("afpId");

  if(!user && !company) {
    return (
      <p>We are currently loading....</p>
    );
  }
  // Pagination config
  const paginationRef = useRef<PaginationRef | null>(null);
  const paginationName = "paginatedAssetFinancingPools";

  // Holds the current Service Provider onClick
  const [activeAFP, setActiveAFP] = useState<AssetFinancingPool|undefined>(undefined);
  const [showModal, setShowModal] = useState<InvestmentTransactionModals|SharedModals>(InvestmentTransactionModals.NoModal);
  const [resultModalType, setResultModalType] = useState<TxResult>(TxResult.SUCCESS);
  const [transactionHash, setTransactionHash] = useState<string>("");
  const [assetPool, setAssetPool] = useState<AssetFinancingPool | null>(null);

  const state: InvestmentPageState = useInvestmentPageState();

  // Handler that updates the state of the currently selected SP
  function handleAFPClick(assetFinancingPool: AssetFinancingPool) {
    // Open the modal
    setShowModal(InvestmentTransactionModals.MakeInvestment);
    setActiveAFP(assetFinancingPool);
    updateInvestmentState(
      {
        serviceproviderID: assetFinancingPool.serviceproviderID,
        pooladdress: assetFinancingPool.pooladdress as string
      },
    );
  }

  const onFilterSubmit = (variables: GetPaginatedAssetFinancingPoolsQueryVariables) => {
    // When the filter is submitted in the PageComponent,
    // this will call the handleFilterSubmit inside Pagination.
    setAssetPool(null);
    paginationRef.current?.triggerHandleFilterSubmit(variables);
  };

  /**
   * Get companyName from URL if it exists and apply the filter
   */
  useEffect(() => {
    if (afpId) {
      setAssetPool(client.readFragment(
        {
          id: `AssetFinancingPool:${afpId}`,
          fragment: AssetFinancingPoolMoreFragmentDoc,
        }
      ) as AssetFinancingPool);
    }
  }, [location.search]);

  // *** Props
  const sharedProps = {
    state: state,
    assetFinancingPool: activeAFP,
    setShowModal: setShowModal,
  };

  const InvestmentTransactionSummaryModalProps = {
    setResultModalType: setResultModalType,
    setTransactionHash: setTransactionHash,
  };

  const InvestmentTransactionResultModalProps = {
    transactionResultType: resultModalType,
    transactionHash: transactionHash,
  };

  return (
    <div className="mf-flex-y-fill m-4 md:mx-0">
      {/* SP Cards container */}
      <Pagination<AssetFinancingPoolEdge, GetPaginatedAssetFinancingPoolsQuery, GetPaginatedAssetFinancingPoolsQueryVariables>
        ref={paginationRef}
        props={{queryHook: useGetPaginatedAssetFinancingPoolsQuery, paginationName, className: "mt-2"}}
      >
        {(dataSlice, loading, displayAmount) => (
          <PaginationContentWrapper>
            <InvestmentFilter
              onFilterSubmit={onFilterSubmit}
              displayAmount={displayAmount as number}
            />
            <PaginationContentScrollable ref={scrollRef}>
              {
                !afpId && loading ?
                // Show loading skeletons if data loading
                  [...Array(displayAmount).keys()].map((amount) => {
                    return (
                      <SPCardSkeleton
                        key={`spSkeleton:${amount}`}
                      />
                    );
                  })
                  :
                  // Otherwise show data
                  assetPool ? (
                    <ServiceProviderInvestCard
                      assetFinancingPool={assetPool}
                      handleAFPClick={handleAFPClick}
                    />
                  ) : (
                    dataSlice.length > 0 ? (
                      dataSlice.map((afp) => {
                        return(
                          <ServiceProviderInvestCard
                            key={`${afp.node._id}`}
                            assetFinancingPool={afp.node}
                            handleAFPClick={handleAFPClick}
                          />
                        );
                      })
                    ) : (
                      <p className="h-24 text-center dark:text-white">
                        No results.
                      </p>
                    )
                  )
              }
            </PaginationContentScrollable>
          </PaginationContentWrapper>
        )}
      </Pagination>

      {/* MODALS */}
      {// Make Investment Modal
        showModal === InvestmentTransactionModals.MakeInvestment &&
          <MakeInvestmentModal
            {...sharedProps}
          />
      }
      {// Investment Summary Modal
        showModal === InvestmentTransactionModals.InvestmentTransactionSummary &&
          <InvestmentTransactionSummaryModal
            {...sharedProps}
            {...InvestmentTransactionSummaryModalProps}
          />
      }
      {// Loading Modal
        showModal === InvestmentTransactionModals.Loading &&
          <LoadingModal
            setShowModal={setShowModal}
            loadingText={t("toasts:sendingDeposit")}
          />
      }
      {// Success/Failed Modal
        showModal === InvestmentTransactionModals.InvestmentTransactionResult &&
          <InvestmentTransactionResultModal
            {...sharedProps}
            {...InvestmentTransactionResultModalProps}
          />
      }
      <ScrollToTopButton scrollToTop={scrollToTop} isVisible={isVisible} />
    </div>
  );
}

export default Investment;
