import React, { ButtonHTMLAttributes } from "react";
import { motion, MotionProps } from "framer-motion";

// Combine DivHTMLAttributes and MotionProps
type FramerDivProps = ButtonHTMLAttributes<HTMLDivElement> & MotionProps;
/**
 * `FramerDiv` is a custom React div component that combines the
 * HTML div attributes with `framer-motion` animation props.
 * It allows you to add motion effects such as hover and tap animations.
 *
 * The div animates by changing its opacity on hover and shrinking slightly on tap.
 *
 * @property {React.ReactNode} [children] - The content inside the div.
 * @property {DivHTMLAttributes<HTMLDivElement>} ...props - Any additional div HTML attributes.
 * @property {MotionProps} ...props - Props from `framer-motion` for animation controls.
 *
 * @param {FrameDivProps} props - The props for the FramerDiv.
 * @returns {JSX.Element} The animated div component.
 *
 * @example
 * // Basic usage
 * <FramerDiv onClick={() => console.log('Clicked!')}>
 *   Click Me
 * </FramerDiv>
 *
 * @example
 * // With custom motion props
 * <FramerDiv
 *   onClick={() => console.log('Clicked!')}
 *   whileHover={{ scale: 1.1 }}
 *   whileTap={{ scale: 0.9 }}
 * >
 *   Hover Me
 * </FramerDiv>
 */
const FramerDiv: React.FC<FramerDivProps> = ({ children, ...props }: FramerDivProps): JSX.Element => {
  return (
    <motion.div
      whileHover={{ opacity: 0.7 }}
      whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
      {...props} // Spread the remaining props here
    >
      {children}
    </motion.div>
  );
};

export default FramerDiv;
