import { useFragment } from "@apollo/client";
import {
  BankAccountFragment,
  BankAccountFragmentDoc,
} from "@/graphql/__generated__/graphql-operations";

/**
 * Custom hook to retrieve and subscribe to bank details for a specific company.
 *
 * This hook uses the `useFragment` hook from Apollo Client to automatically
 * subscribe to changes in the company's address data.
 *
 * @param {{string, string}} {id, typename} - The company from which to retrieve the company details.
 * @returns {BankAccountFragment["bankaccount"] | undefined} - The bank data or undefined if not available.
 *
 * @example
 * const company = useCompanyData(); // Fetch or retrieve the company data
 * const bankaccount = useBankData({ id: company?._id, typename: company?.__typename });
 *
 * if (bankaccount) {
 *   console.log("Bank details:", bankaccount);
 * }
 */
export const useBankData = ({ id, typename }: {id: string, typename: string}): BankAccountFragment["bankaccount"] | undefined => {
  const { data } = useFragment({
    fragment: BankAccountFragmentDoc,
    from: {
      __typename: typename,
      _id: id,
    }
  });

  return data?.bankaccount;
};
