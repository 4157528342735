import { Withdrawal, WithdrawalType } from "@/graphql/__generated__/graphql-operations";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { GroupedWithdrawals } from "@/types";
import { IntlShape, useIntl } from "react-intl";
import { formatDateLocale, formatNumberLocale } from "@/utils/helpers";
import ServiceProviderDetails from "../ServiceProviderDetails";
import TextInfoGroup from "../common/TextInfoGroup";
import { WithdrawalCardModals } from "@/constants/enums";
import { useState } from "react";
import { propsWithdrawalDetailsCard } from "./props";
import { useTranslation } from "react-i18next";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/Collapsible";
import { motion, AnimatePresence } from "framer-motion";
import { FramerDiv, FramerButton } from "../framer";

function WithdrawalDetailsCard(props: propsWithdrawalDetailsCard) {
  // Props
  const {
    groupedWithdrawals,
    setActiveGroupedWithdrawals,
    disabled,
    setShowModal,
    setActiveTotalWithdrawalAmount
  } = props;

  // Hooks
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const { t } = useTranslation(["modals", "components", "common"]);

  function handleWithdrawClick() {
    if (disabled) return;
    // use first withdrawal
    setActiveGroupedWithdrawals(groupedWithdrawals);
    // set total withdrawal amount relative to the selected withdrawal
    setActiveTotalWithdrawalAmount(calculateTotalWithdrawalAmount(groupedWithdrawals.withdrawals));
    // change modals
    setShowModal(WithdrawalCardModals.WithdrawalSummary);
  }

  function calculateTotalWithdrawalAmount(withdrawals: Withdrawal[]): number {
    return withdrawals.reduce((totalAmount, withdrawal) => totalAmount + withdrawal.amount, 0);
  }

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div data-cy="withdrawaldetails-card" className="bg-gray-100 p-2 rounded-sm dark:bg-mfdarklighter">
        {/* Top container */}
        <div className="flex items-start w-full flex-wrap lg:flex-nowrap">
          {/* SP details */}
          <ServiceProviderDetails
            serviceProviderId={groupedWithdrawals.spid}
          />
          {/* Text group */}
          <div data-cy="withdrawaldetails-card-textinfogroups" className="flex flex-wrap md:flex-nowrap gap-x-8 gap-y-2 lg:gap-y-0 items-center">
            <TextInfoGroup label={t("availablewithdrawals.numberofwithdrawals")}
              content={groupedWithdrawals.withdrawals.length} className="mt-2 md:mt-0"
            />
            <TextInfoGroup label={t("availablewithdrawals.totalwithdrawalamount")}
              content={formatNumberLocale(intl, calculateTotalWithdrawalAmount(groupedWithdrawals.withdrawals), "currency")} className="my-2 md:my-0"
            />
          </div>

          {/* Buttons */}
          <div data-cy="withdrawaldetails-card-buttons" className="flex flex-wrap sm:flex-nowrap gap-2 justify-end lg:justify-normal w-full lg:w-auto mb-4 lg:mb-0 ml-auto">
            <CollapsibleTrigger>
              <FramerDiv
                className="mf-btn-action-large-outline"
              >
                {
                  open ?
                    <div className="flex items-center dark:text-white">
                      <ChevronUpIcon className="w-5 h-5 mr-[2px]" /> {t("availablewithdrawals.buttons.collapse")}
                    </div>:
                    <div className="flex items-center dark:text-white">
                      <ChevronDownIcon className="w-5 h-5 mr-[2px]" /> {t("availablewithdrawals.buttons.expand")}
                    </div>
                }
              </FramerDiv>
            </CollapsibleTrigger>
            <FramerButton
              data-cy="withdrawaldetails-card-withdraw-button"
              onClick={() => handleWithdrawClick()}
              className={disabled ? "mf-btn-action-large-disabled-filled" : "mf-btn-action-large-primary-filled"}
            >
              {t("availablewithdrawals.buttons.withdraw")}
            </FramerButton>
          </div>
        </div>

        {/* Bottom container */}
        <div className={`${open ? "pt-4" : ""}`}>
          <AnimatePresence>
            {open && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ opacity: { duration: 0.5 }, height: { duration: 0.2 } }}
                style={{ overflow: "hidden" }}
              >
                <CollapsibleContent>
                  <ExpandedContent groupedWithdrawals={groupedWithdrawals} intl={intl} />
                </CollapsibleContent>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </Collapsible>
  );
}

// Separated ExpandedContent component to ensure hooks consistency
function ExpandedContent({ groupedWithdrawals, intl }: { groupedWithdrawals: GroupedWithdrawals, intl: IntlShape }) {
  // Hooks
  const { t } = useTranslation(["modals", "components", "common"]);

  return (
    <div data-cy="withdrawaldetails-card-content" className="flex flex-col space-y-2">
      {/* Grouped withdrawals list */}
      {groupedWithdrawals.withdrawals.map((withdrawal) => (
        <div key={`withdrawal-${withdrawal.date}`} className="flex flex-row flex-wrap gap-y-2 md:gap-y-0 lg:space-x-16 lg:grid grid-cols-12 p-2 bg-white rounded-sm dark:bg-mfdarklight">
          <TextInfoGroup label={t("availablewithdrawals.date")}
            content={formatDateLocale(intl, withdrawal.date)}
            className="col-span-3"
          />
          <TextInfoGroup label={t("availablewithdrawals.amount")}
            content={formatNumberLocale(intl, withdrawal.amount, "currency")}
            contentColour="text-green-500"
            className="col-span-3"
          />
          <TextInfoGroup label={t("availablewithdrawals.type")}
            content={withdrawal.type === WithdrawalType.Interest ?
              t("availablewithdrawals.types.interest")
              : t("availablewithdrawals.types.investment")}
            className="col-span-3"
          />
        </div>
      ))}
    </div>
  );
}

export default WithdrawalDetailsCard;
