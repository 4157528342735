import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { FramerButton, TextInfoGroup } from "@/components/shared";
import { TwInput } from "@/components/shared";
import { UsersPageModals } from "@/constants/enums";
import { useUserData } from "@/hooks";
import { useEditPasswordMutationHook, useEditUserMutationHook } from "@/hooks/mutations";
import { UserBasic } from "@/types";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/AlertDialog";
import { useUserDataById } from "@/hooks/cache/useUserDataById";
import { useTheme } from "@/context/ThemeProvider";

type Props = {
  setShowModal: Dispatch<SetStateAction<UsersPageModals>>;
  userId: string;
  onClickDeleteUser: (_user: UserBasic) => void;
}

function ManageUserAdminModal({setShowModal, userId, onClickDeleteUser}: Props) {
  // States
  const [editPassword, setEditPassword] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [newPasswordState, setNewPasswordState] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  // Hooks
  const { t } = useTranslation(["modals", "tables", "common"]);
  const selectedUser = useUserDataById(userId) as UserBasic | null;
  const { darkMode } = useTheme();

  // Mutation hooks
  const loggedInUser = useUserData();
  const { editPasswordMutation } = useEditPasswordMutationHook();
  const { editUser } = useEditUserMutationHook();

  /**
   * Toggle activation status of user
   */
  function toggleActivation() {
    if (!selectedUser) return;
    editUser(selectedUser._id, selectedUser, { active: !selectedUser.active });

    setOpenDialog(false);
  }

  /**
   * OnClick handler for the edit password button
   * @param userObj
   */
  function onClickEditEmployer(userObj: UserBasic) {
    if(newPasswordState != "" && !hasErrors){
      editPasswordMutation({
        variables: {
          id: userObj._id,
          passwordInput: {
            newPassword: newPasswordState
          }
        },
      });
      setNewPasswordState("");
    }
  }

  /**
   * OnClick handler for the deactivate user button
   */
  function onClickDeactivateUser() {
    setOpenDialog(true);
  }

  /**
  * onChange handler for input fields
  * @param event: { name: string, value: string, isValid: boolean }
  */
  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    const { name, value, isValid } = event;

    if(isValid) {
      setHasErrors(false);
      if(name == "newPassword") {
        setNewPasswordState(value);
      }
    }
    else {
      setHasErrors(true);
    }
  }

  return (
    <BlankModal
      setShowModal={() => {
        setShowModal(UsersPageModals.NoModal);
      }}
      title={t("translation:manageusers.title")}
      className="px-6"
      customContentCSS="mf-modal-content lg:min-w-[580px]"
    >
      {/* Main content */}
      {
        selectedUser &&
        <>
          <BlankModalContent>
            {/* Management Buttons */}
            {
              // Hide buttons for the logged in user
              loggedInUser?._id !== selectedUser._id &&
              <div className="flex gap-x-2 justify-end mb-4">
                <FramerButton
                  className="mf-btn-action-small-primary-filled"
                  onClick={onClickDeactivateUser}
                >
                  {selectedUser.active ? t("common:buttons.deactivate"): t("common:buttons.activate")}
                </FramerButton>
                <FramerButton
                  className="mf-btn-action-small-primary-filled"
                  onClick={() => onClickDeleteUser(selectedUser)}
                >
                  {t("common:buttons.delete")}
                </FramerButton>
              </div>
            }
            {/* User details */}
            <div className="flex flex-col gap-2 mb-4">
              {/* First and last name */}
              <div className="flex flex-wrap gap-2">
                <TextInfoGroup label={t("tables:manageusers.tablecard.textinfogroups.firstname")} content={selectedUser.firstname} />
                <TextInfoGroup label={t("tables:manageusers.tablecard.textinfogroups.lastname")} content={selectedUser.lastname} />
              </div>

              {/* Email */}
              <TextInfoGroup label={t("tables:manageusers.tablecard.textinfogroups.email")} content={selectedUser.mail} />
              {/* Employer ID */}
              {
                selectedUser.employer &&
                <TextInfoGroup className="md:whitespace-nowrap" label={t("tables:manageusers.tablecard.textinfogroups.employerid")} content={selectedUser.employer._id} />
              }
              {/* Company details */}
              <div className="flex flex-wrap gap-2">
                <TextInfoGroup label={t("tables:manageusers.tablecard.tags.active")} content={selectedUser.active ? t("common:text.yes"): t("common:text.no")} />
                {
                  selectedUser.employer &&
                  <TextInfoGroup label={t("tables:manageusers.tablecard.textinfogroups.company")} content={selectedUser.employer.name} />
                }
              </div>
              {/* User details */}
              <div className="flex flex-wrap gap-2">
                <TextInfoGroup label={t("tables:manageusers.tablecard.textinfogroups.usertype")} content={selectedUser.__typename} />
                <TextInfoGroup label={t("tables:manageusers.tablecard.textinfogroups.role")} content={selectedUser.role} />
              </div>
              {
                editPassword ?
                  <div className="">
                    <label className=" text-gray-400 text-sm select-none">{t("tables:manageusers.tablecard.textinfogroups.newpassword")}</label>
                    <div className="flex items-center">
                      <div className="w-full">
                        <TwInput
                          autoComplete="new-password"
                          type="password"
                          required
                          name="newPassword"
                          onChange={handleOnChange}
                          className="mf-input-field text-gray-800"
                          defaultValue={""}
                          errorMessage={t("tables:manageusers.expandedrow.inputfields.passwd.errormsg").toString()}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <TextInfoGroup className="h-[58px]" label={t("tables:manageusers.tablecard.textinfogroups.password")} content={"*****"} />
              }
              {
                // Hide buttons for the logged in user
                loggedInUser?._id !== selectedUser._id &&
                  <div className="flex gap-2 justify-between">
                    <FramerButton
                      className="mf-btn-action-small-primary-filled"
                      onClick={() => setEditPassword((prev) => !prev)}
                    >
                      {editPassword ? t("common:buttons.cancel"): t("tables:manageusers.tablecard.buttons.editpassword")}
                    </FramerButton>
                    {
                      editPassword &&
                      <FramerButton
                        onClick={() => onClickEditEmployer(selectedUser)}
                        className="mf-btn-action-small-secondary-filled"
                      >
                        {t("common:buttons.save")}
                      </FramerButton>
                    }
                  </div>
              }
            </div>
          </BlankModalContent>

          <BlankModalFooter>
            {/* Action Buttons */}
            <div>
              <FramerButton
                onClick={() => {
                  setShowModal(UsersPageModals.NoModal);
                }}
                className="mf-btn-action-small-primary-outline"
              >
                {t("common:buttons.close")}
              </FramerButton>
            </div>
          </BlankModalFooter>

          <AlertDialog open={openDialog}>
            <AlertDialogContent className={`${darkMode ? "bg-mfdarklight border-mfdarkbase text-white" : "bg-white"}`}>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {
                    t("manageusers.dialog.userActivation.title",
                      { toggle: selectedUser.active ? t("manageusers.dialog.userActivation.deactivate") : t("manageusers.dialog.userActivation.activate") }
                    )
                  }
                </AlertDialogTitle>
                <AlertDialogDescription className={`${darkMode ? " text-gray-200" : "text-gray-800"}`}>
                  {
                    t("manageusers.dialog.userActivation.subtitle",
                      { toggle: (selectedUser.active ? t("manageusers.dialog.userActivation.deactivate") : t("manageusers.dialog.userActivation.activate")).toLowerCase() }
                    )
                  } &nbsp;
                  <span className="font-semibold">{selectedUser?.firstname} {selectedUser?.lastname}</span>.
                  <br />{t("manageusers.dialog.userActivation.note")}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel
                  onClick={() => setOpenDialog(false)}
                  className={`${darkMode ? "bg-mfdarklight border-gray-600 text-white hover:text-white hover:bg-mfdarklight hover:opacity-90" : "bg-white"}`}
                >
                  {t("common:buttons.cancel")}
                </AlertDialogCancel>
                <AlertDialogAction
                  onClick={() => toggleActivation()}
                  className="bg-mforange hover:bg-mforange hover:opacity-90"
                >
                  {selectedUser.active ? t("manageusers.dialog.userActivation.deactivate") : t("manageusers.dialog.userActivation.activate")}
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </>
      }

    </BlankModal>
  );
}

export default ManageUserAdminModal;
