import { client } from "@/client";
import { toast } from "react-toastify";
import { initializeEmptyCache, loadBlockchainDataToCache } from "@/cache/Init";
import { useUserData } from "@/hooks";
import Web3Singleton from "@/blockchain/Web3Singleton";
import magic from "@/libs/magic";
import { LoginUserQuery } from "@/graphql/__generated__/graphql-operations";

/**
 * This function checks if the user is logged in by validating the user's token.
 *
 * @param {string[]} roles - The roles that the user must have at least one of.
 * @param {string[]} usertypes - The user types that the user must have at least one of.
 * @returns {boolean} - True if there is a valid token in local storage and the user has a required role and type, false otherwise.
 * @example
 * ```javascript
 * const roles = ["Admin", "User"];
 * const usertypes = ["Employee", "Financier"];
 * const isValid = ValidateToken(roles, usertypes);
 * console.log(isValid); // Output: true or false based on the validation.
 * ```
 */
export function ValidateToken(roles: string[], usertypes: string[]): boolean {
  const user = useUserData();
  // If the user is not present, log out
  if(!user) {
    return false;
  }
  const userHasRole = roles.includes(user.role) || roles.includes("ALL");
  const userHasType = usertypes.includes(user.usertype) || usertypes.includes("ALL");
  return userHasRole && userHasType;
}

/**
 * Checks if the user object exists.
 *
 * Used to prevent the app from crashing when trying to visit a protected route after a session has ended by closing the tab.
 * @returns {boolean} - True if the user object exists, false otherwise.
 * @example
 * ```javascript
 * const doesUserExist = userExists();
 * console.log(doesUserExist); // Output: true if the user is logged in, false otherwise.
 * ```
 */
export function userExists(): boolean {
  const user = useUserData();
  return user ? true : false;
}

/**
 * Handler function for logging in a user.
 *
 * Sets the session storage and initializes the Apollo Cache.
 * @param {LoginUserQuery["loginUser"]} result - The result of the loginUser mutation containing user information.
 * @example
 * ```javascript
 * login(loginResult.data.loginUser);
 * ```
 * // User login session is now set, and the blockchain data is loaded to the cache.
 */
export async function login(result: LoginUserQuery["loginUser"]) {
  if(result.user.__typename === "Employee") {
    sessionStorage.setItem("companyId", result.user.employer?._id ?? "");
    sessionStorage.setItem("companyType", result.user.employer?.__typename ?? "");
  }
  // Set session Storage
  sessionStorage.setItem("userId", result.user._id);
  sessionStorage.setItem("userType", result.user.__typename);
  sessionStorage.setItem("loggedIn", Date.now().toString());

  // After successful login, set the token to local Storage
  initializeEmptyCache(result.user);

  await loadBlockchainDataToCache(result.user);
  localStorage.setItem("isConnected", "false");
}

/**
 * Handler function for logging out a user.
 *
 * Clears localStorage, sessionStorage, Apollo Cache, and resets the Web3Singleton instance.
 * @param {boolean} [sessionExpired] - Optional parameter indicating if the session expired.
 * @example
 * ```javascript
 * await logout();
 * // User is now logged out, local and session storage is cleared, and Web3 instance is reset.
 *
 * // Example with sessionExpired flag:
 * await logout(true);
 * // Displays a toast message indicating that the session has expired.
 * ```
 */
export async function logout(sessionExpired?: boolean) {
  try {
    const keysToKeep = ["i18nextLng", "theme"];
    // Clear all localStorage items except for the keys to keep
    Object.keys(localStorage).forEach((key) => {
      if (!keysToKeep.includes(key)) {
        localStorage.removeItem(key);
      }
    });

    // Clear sessionStorage
    sessionStorage.clear();

    // Clear and reset the Apollo Client cache
    await client.clearStore();
    await client.cache.reset();

    // Reset the Web3Singleton instance
    Web3Singleton.resetInstance();

    // Log out from Magic authentication
    await magic.user.logout();

    // Display a session expired message if applicable
    if (sessionExpired) {
      toast.error("Your session has expired", { toastId: "sessionExpiredToast", autoClose: false, delay: 500, position: "top-center" });
    }
  } catch (error) {
    console.error("Error during logout process:", error);
    toast.error("An error occurred while logging out. Please try again.", { position: "top-center" });
  }
}
