import { MakeWithdrawalModal, WithdrawalResultModal, WithdrawalSummaryModal } from "./modals";
import { SharedModals, WithdrawalCardModals } from "@/constants/enums";
import { Withdrawal, WithdrawalType } from "@/graphql/__generated__/graphql-operations";
import { formatDateLocale, formatNumberLocale } from "@/utils/helpers";
import { GroupedWithdrawals } from "@/types";
import { LoadingModal } from "@/components/modals";
import { FramerButton, TextInfoGroup } from "@/components/shared";
import { TxResult } from "@/constants/enums";
import { differenceInDays } from "date-fns";
import { useIntl } from "react-intl";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFinancierData } from "@/hooks";

type Props = {
  reverseLayout?: boolean;
}

function WithdrawalCard({reverseLayout = false} : Props) {
  // State
  const [resultModalType, setResultModalType] = useState(TxResult.ERROR);
  const [activeGroupedWithdrawals, setActiveGroupedWithdrawals] = useState<GroupedWithdrawals | null>(null);
  const [activeTotalWithdrawalAmount, setActiveTotalWithdrawalAmount] = useState<number>(0);
  const [transactionHash, setTransactionHash] = useState<string>("");
  const [showModal, setShowModal] = useState<WithdrawalCardModals|SharedModals>(WithdrawalCardModals.NoModal);

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["components"]);
  const FinancierData = useFinancierData();

  const availableWithdrawals = FinancierData?.availableWithdrawals as Withdrawal[] ?? [];
  const nextWithdrawal = FinancierData?.nextWithdrawal as Withdrawal | undefined;

  // Function to calculate total amount
  function calculateTotalWithdrawalAmount(withdrawals: Withdrawal[]): number {
    return withdrawals.reduce((totalAmount, withdrawal) => totalAmount + withdrawal.amount, 0);
  }

  // Calculates days to next withdrawal
  function countdownToNextWithdrawal(nextWithdrawal: Withdrawal | undefined): string | JSX.Element {

    if (nextWithdrawal) {
      const now = new Date();
      const dateObject = new Date(nextWithdrawal.date);
      // To ignore DST and only measure exact 24-hour periods
      const daysUntilWithdrawal = differenceInDays(dateObject, now);

      return (
        <p>
          {t("interestwithdrawal.message.upcoming")}&nbsp;
          <span className="font-semibold text-mfpurple dark:text-mforange">
            {formatNumberLocale(intl, nextWithdrawal.amount, "currency")}
          </span>
          &nbsp;
          {daysUntilWithdrawal === 1 ? (
            <span>{t("interestwithdrawal.message.tomorrow")}</span>
          ) : (
            <span>
              {t("interestwithdrawal.message.in")}&nbsp;
              <span className="font-semibold text-mfpurple dark:text-mforange">
                {daysUntilWithdrawal}
              </span>
              &nbsp;{t("interestwithdrawal.message.days")}
            </span>
          )}
          &nbsp;
          <span className="font-semibold text-mfpurple dark:text-mforange">
            {formatDateLocale(intl, nextWithdrawal.date)}
          </span>
        </p>
      );
    } else {
      return t("interestwithdrawal.message.nowithdrawal");
    }
  }

  /**
   * Function to group withdrawals by spid
   * @param availableWithdrawals
   * @returns [] of grouped withdrawals
   */
  function groupWithdrawalsBySPId(availableWithdrawals: Withdrawal[]): GroupedWithdrawals[] {
    const grouped: Record<string, { investmentID: string, type: WithdrawalType, withdrawals: Withdrawal[] }> = {};

    // Group withdrawals by spid
    availableWithdrawals.forEach(withdrawal => {
      if (!grouped[withdrawal.spid]) {
        grouped[withdrawal.spid] = {
          investmentID: withdrawal.investmentID,
          type: withdrawal.type,
          withdrawals: []
        };
      }
      if (withdrawal.type === WithdrawalType.Investment) {
        grouped[withdrawal.spid].type = withdrawal.type;
      }
      grouped[withdrawal.spid].withdrawals.push(withdrawal);
    });

    // Convert the grouped object into an array of GroupedWithdrawals
    // Type is determined by withdrawal array's type, Investment type takes preference
    return Object.keys(grouped).map(spid => ({
      spid,
      ...grouped[spid]
    }));
  }

  function WithdrawButton() {
    // Early return if no withdrawals are available
    if (availableWithdrawals.length === 0) {
      return null;
    }

    return (
      <div>
        <FramerButton
          data-cy="withdrawal-btn"
          className="mf-btn-action-small-primary-filled md:mf-btn-action-large-primary-filled"
          onClick={() => setShowModal(WithdrawalCardModals.MakeWithdrawal)}
        >
          <p>
            {t("interestwithdrawal.withdrawnow")}
          </p>
        </FramerButton>
      </div>
    );
  }

  // *** Props
  const sharedProps = {
    setShowModal: setShowModal,
  };

  const withdrawalModalProps = {
    ...sharedProps,
    groupedAvailableWithdrawals: groupWithdrawalsBySPId(availableWithdrawals),
    setActiveGroupedWithdrawals: setActiveGroupedWithdrawals,
    setActiveTotalWithdrawalAmount: setActiveTotalWithdrawalAmount
  };

  const withdrawalSummaryModalProps = {
    ...sharedProps,
    setResultModalType: setResultModalType,
    activeGroupedWithdrawals: activeGroupedWithdrawals,
    setTransactionHash: setTransactionHash,
    activeTotalWithdrawalAmount: activeTotalWithdrawalAmount
  };

  const withdrawalResultModalProps = {
    ...sharedProps,
    transactionResultType: resultModalType,
    activeGroupedWithdrawals: activeGroupedWithdrawals,
    transactionHash: transactionHash,
    activeTotalWithdrawalAmount: activeTotalWithdrawalAmount
  };

  return (
    <div data-cy="withdrawal-card"
      className="bg-white rounded-md flex flex-col md:flex-row flex-wrap select-none
                py-4 px-4 shadow-lg gap-y-3 md:gap-y-4 dark:bg-mfdarklight md:items-center gap-x-4"
    >
      <div className="flex flex-wrap justify-between flex-grow">
        {/* Title */}
        <div className="flex flex-1 w-max min-h-max mr-8">
          <p data-cy="withdrawal-card-title" className="text-sm md:text-lg font-semibold text-gray-800 dark:text-white">
            {t("interestwithdrawal.title")}
          </p>
        </div>

        {/* Action button */}
        <div data-cy="withdrawal-card-button-reverse" className={`${reverseLayout ? "flex md:hidden xl:flex" : "flex md:hidden"}`}>
          {WithdrawButton()}
        </div>
      </div>

      {/* Available Withdrawals */}
      {availableWithdrawals.length > 0 ? (
        <>
          {/* TextInfoGroup Container */}
          <div data-cy="withdrawal-card-textinfogroups" className="flex min-h-max items-center gap-4 md:gap-8">
            {/* Number of withdrawals */}
            <TextInfoGroup
              label={t("interestwithdrawal.numberofwithdrawals")}
              content={availableWithdrawals.length}
              className="w-auto"
              wrapTitle
            />

            {/* Total Amount */}
            <TextInfoGroup
              label={t("interestwithdrawal.totalwithdrawalamount")}
              content={formatNumberLocale(intl, calculateTotalWithdrawalAmount(availableWithdrawals), "currency")}
              className="w-auto"
              wrapTitle
            />
          </div>
        </>
      ) : (
        // Next withdrawal
        <div data-cy="withdrawal-card-nextwithdrawal" className="flex min-h-max items-center dark:text-white">
          {countdownToNextWithdrawal(nextWithdrawal)}
        </div>
      )}

      {/* Action button */}
      <div data-cy="withdrawal-card-button" className={`ml-auto ${reverseLayout ? "hidden md:flex xl:hidden" : "hidden md:flex"}`}>
        {WithdrawButton()}
      </div>

      {/* Modals */}
      {
        showModal === WithdrawalCardModals.MakeWithdrawal &&
        <MakeWithdrawalModal
          {...withdrawalModalProps}
        />
      }
      {
        showModal === WithdrawalCardModals.WithdrawalSummary &&
        <WithdrawalSummaryModal
          {...withdrawalSummaryModalProps}
        />
      }
      {
        showModal === WithdrawalCardModals.Loading &&
        <LoadingModal
          {...sharedProps}
          loadingText={t("toasts:sendingWithdrawal")}
        />
      }
      {
        showModal === WithdrawalCardModals.WithdrawalResult &&
        <WithdrawalResultModal
          {...withdrawalResultModalProps}
        />
      }
    </div>
  );
}

export default WithdrawalCard;
