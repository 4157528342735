import { UserRole } from "@/graphql/__generated__/graphql-operations";
import { passwordSchema } from "@/utils/form";
import { z } from "zod";

export const UserRoleEnum = z.enum([UserRole.Admin, UserRole.Superuser, UserRole.User]);

export const CreateUserSchema = z.object({
  firstname: z.string().min(2, "First name is required"),
  lastname: z.string().min(2, "Last name is required"),
  mail: z.string().email("Please enter a valid email address"),
  password: passwordSchema,
  role: UserRoleEnum,
  employerID: z.string().optional(),
});

export const CreateUserDefaultValues = {
  firstname: "",
  lastname: "",
  mail: "",
  password: "",
  role: undefined,
  employerID: undefined
};

// Infer the TypeScript type from the Zod schema
export type TCreateUserSchema = z.infer<typeof CreateUserSchema>;
