import { gql } from "@apollo/client";

export const CLIENT_CREDIT_DATA = gql`
  query getCreditData($id: ID!) {
    company(ID: $id) @client {
      creditLimit @client
      credit @client
    }
  }
`;

export const CLIENT_GET_BLOCKCHAIN = gql`
  query getBlockchainData @client {
    blockchainData @client {
      initialized
      loading
      balance
      serviceProvider {
        interestPaymentDue
        currentlyOwedInterestAmount
        nextOwedInterestAmount
        nextPaymentDate
        lastPaymentDate
      }
      inventoryProvider {
        criticalInventoryValue
        isEndPaymentDue
        lastInventoryValue
      }
      receivablesProvider {
        residualCash
      }
      admin {
        totalSupply
      }
    }
  }
`;

export const CLIENT_GET_USER = gql`
  query User {
    user @client {
      __typename
      _id
      firstname
      lastname
      mail
      role
      usertype
      ... on Employee {
        employer {
          __typename
          _id
          logo
          name
          companytype
          address {
            street
            housenumber
            zipcode
            country
            city
          }
          bankaccount {
            iban
            bic
            verified
            beneficiaryID
          }
          logo
          publickey
          ... on Financier {
            currentWorthIncludingInterest
            totalInvestedAmountInclInterest
            totalInvestedAmount
            averageInterestRate
            nextWithdrawal {
              investmentID
              date
              amount
              type
              spid
            }
            availableWithdrawals {
              investmentID
              date
              amount
              type
              spid
            }
            investments {
              _id
              amount
              serviceproviderName
              assetfinancingpool {
                status
                pooladdress
              }
              createdAt
              updatedAt
            }
          }
          ... on ServiceProvider {
            companydetails {
              industry
              description
              yearlyrevenue
              foundingyear
              webpresence {
                website
                facebook
                twitter
                linkedin
              }
            }
            assetfinancingpool {
              _id
              financinglimit
              interest
              rewardpayoutperiod
              status
              dayslocked
              amountinvested
              activationdate
              pooladdress
              tokenaddress
              assetType
              floor
              ceiling
              uniqueitems
            }
          }
        }
      }
    }
  }
`;

export const CLIENT_GET_ADMIN_STATE = gql`
  query getAdminState @client {
    adminPageState @client {
      balanceOf {
        address
        output
      }
      totalSupply
      addAuthorizedUser {
        address
        output
      }
      removeAuthorizedUser {
        address
        output
      }
      authorizationStatusOf {
        address
        output
      }
      creditLimitRequestInput {
        status
        requestedAmount
        reasonForRequest
        reasonForDenial
      }
    }
  }
`;

export const CLIENT_GET_PAGINATION_STATE = gql`
  query getPaginationState @client {
    paginationState @client {
      paginatedAssets {
        first
        currentPage
        filters {
          isFinanced
          from
          to
          filter
          assetfinancingpoolId
        }
      }
      paginatedCreditLimitRequests {
        first
        currentPage
        filters {
          customerID
          status
          from
          to
        }
      }
      paginatedCustomers {
        first
        currentPage
        filters {
          filter
        }
      }
      paginatedAssetFinancingRounds {
        first
        currentPage
        filters {
          poolId
        }
      }
      paginatedTokenTransactions {
        first
        currentPage
        filters {
          status
          type
        }
      }
      paginatedUsers {
        first
        currentPage
        filters {
          filter
        }
      }
      paginatedAssetFinancingPools {
        first
        currentPage
        filters {
          status
          companyname
        }
      }
      paginatedInvestments {
        first
        currentPage
        filters {
          status
          companyName
        }
      }
      paginatedCompanies {
        first
        currentPage
        filters {
          status
          companytype
        }
      }
    }
  }
`;

export const CLIENT_GET_CUSTOMERS_STATE = gql`
  query getCustomersState @client {
    customersPageState @client {
      creditLimitRequestInput {
        customerID
        reasonForRequest
        requestedAmount
        serviceproviderID
      },
    }
  }
`;

export const CLIENT_GET_INVESTMENT_STATE = gql`
  query getInvestmentState @client {
    investmentPageState @client {
      serviceproviderID
      amount
      pooladdress
    }
  }
`;
