import { InputGroup } from "@/types";
import _ from "lodash";
import { UseFormRegister, UseFormStateReturn, FieldValues, Path, UseFormWatch } from "react-hook-form";

type Props<T extends FieldValues> = {
  field: InputGroup;
  errors: UseFormStateReturn<T>["errors"];
  register: UseFormRegister<T>;
  watch?: UseFormWatch<T>;
};

/**
 * FormTextArea component for rendering a text area field in a form.
 * Supports different types of text area inputs.
 *
 * @template T - The schema type, such as TLoginSchema or TCreateUserSchema.
 * @param {Props<T>} props - The props object.
 * @param {InputGroup} props.field - The text area field configuration.
 * @param {UseFormStateReturn<T>["errors"]} props.errors - The form errors.
 * @param {UseFormRegister<T>} props.register - The register function from react-hook-form.
 *
 * @returns {JSX.Element} The rendered form text area component.
 *
 * @example
 * ```tsx
 * import { useForm } from "react-hook-form";
 * import { TFeedbackSchema, FeedbackSchema } from "@/schemas/FeedbackSchema";
 * import FormTextArea from "@/components/FormTextArea";
 *
 * const FeedbackForm = () => {
 *   const { register, handleSubmit, formState: { errors } } = useForm<TFeedbackSchema>({
 *     resolver: zodResolver(FeedbackSchema),
 *   });
 *
 *   return (
 *     <form onSubmit={handleSubmit((data) => console.log(data))}>
 *       <FormTextArea
 *         field={{ name: "feedback", title: "Feedback", required: true, rows: 5 }}
 *         errors={errors}
 *         register={register}
 *       />
 *     </form>
 *   );
 * };
 * ```
 */
const FormTextArea = <T extends FieldValues>({ field, errors, register, watch }: Props<T>): JSX.Element => {

  // Use lodash's get to retrieve nested errors safely
  const fieldError = _.get(errors, field.name as Path<T>);

  return (
    <div key={`textarea-field-${field.name}`}>
      {/* Text area label */}
      <label htmlFor={field.name} className={`select-none dark:text-gray-200 ${fieldError  && "text-red-500"}`}>
        {field.title}
      </label>

      {/* Text area field */}
      <textarea
        className={`${field.className} ${fieldError  && "border-red-500"}
                    text-gray-800 rounded-sm focus:border-mfpurple dark:focus:border-mforange`}
        placeholder={field.placeholder}
        autoFocus={field.autoFocus}
        data-cy={`${field.name}-textarea`}
        defaultValue={field.defaultValue}
        value={watch && watch(field.name as Path<T>)}
        rows={3} // Default to 3 rows
        autoComplete={field.autoComplete}
        {...register(field.name as Path<T>, { required: field.required })}
      />

      {/* Error message */}
      {fieldError  && (
        <p className="input-error-message">
          {fieldError ?.message as string}
        </p>
      )}
    </div>
  );
};

export default FormTextArea;
