import { useRef, useState } from "react";
import {
  GetPaginatedUsersQuery,
  GetPaginatedUsersQueryVariables,
  UserEdge,
  UserRole,
  useGetPaginatedUsersQuery
} from "@/graphql/__generated__/graphql-operations";
import { UserBasic } from "@/types";
import { ScrollToTopButton } from "@/components/shared";
import { UsersPageModals } from "@/constants/enums";
import { Pagination, PaginationContentScrollable } from "@/components/shared";
import UsersFilterComponent from "@/pages/ManageUsers/UsersFilter";
import { useUserData } from "@/hooks";
import { useDeleteUserMutationHook } from "@/hooks/mutations";
import { useTranslation } from "react-i18next";
import { ActionButton } from "@/components/shared/table/Table";
import useMdBreakpoint from "@/hooks/useMdBreakpoint";
import { TwTableCardList, DataTable } from "@/components/shared/table";
import { CreateUserModal, ManageUserAdminModal } from "@/pages/ManageUsers/modals";
import useScrollToTop from "@/hooks/useScrollToTop";
import { useIntl } from "react-intl";
import { getColumns } from "./columns";
import { getColumnHeaderValues } from "@/components/shared/table/utils";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/AlertDialog";
import { useTheme } from "@/context/ThemeProvider";

interface PaginationRef {
  triggerHandleFilterSubmit: (_newVariables: Partial<GetPaginatedUsersQueryVariables>) => void;
}

function ManageUsers() {
  // Ref use for Scroll To Top
  const scrollRef = useRef<HTMLDivElement>(null);

  // Hooks
  const intl = useIntl();
  const user = useUserData();
  const { t } = useTranslation(["common", "tables"]);
  const mdBreakpoint = useMdBreakpoint();
  const { isVisible, scrollToTop } = useScrollToTop({ ref: scrollRef });
  const { darkMode } = useTheme();

  if(!user) {
    return (
      <p>We are currently loading....</p>
    );
  }
  // States

  const [hasErrors, setHasErrors] = useState(false);
  const [showModal, setShowModal]  = useState<UsersPageModals>(UsersPageModals.NoModal);
  const [selectedUser, setSelectedUser] = useState<UserBasic | null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  // Pagination config
  const paginationRef = useRef<PaginationRef | null>(null);
  const paginationName = "paginatedUsers";

  // Mutation hooks
  const { deleteUserMutation } = useDeleteUserMutationHook(selectedUser?._id || "", setSelectedUser);

  /**
   * Function to handle the delete user dialog
   * @param user
   */
  function openDeleteUserDialog(userParam: UserBasic) {
    if(user!._id === userParam._id) return;
    setOpenDialog(true);
  }

  /**
   * Function to delete a user
   */
  function deleteUser() {
    deleteUserMutation({
      variables: {
        id: selectedUser!._id
      },
    }).then(() => {
      setOpenDialog(false);
    });
  }

  /**
   * OnClick handler for the details button
   * @param user
   */
  const handleOnClickDetails = () => {
    setShowModal(UsersPageModals.ManageUser);
  };

  const handleOnClickAction = (userParam: UserBasic) => {
    setSelectedUser(userParam);
    if(user!.role === UserRole.Admin) {
      handleOnClickDetails();
    }
    if(user!.role === UserRole.Superuser) {
      openDeleteUserDialog(userParam);
    }
  };

  const onFilterSubmit = (variables: GetPaginatedUsersQueryVariables) => {
    // When the filter is submitted in the PageComponent,
    // this will call the handleFilterSubmit inside Pagination.
    paginationRef.current?.triggerHandleFilterSubmit(variables);
  };

  const getActionButtons = (_index: number, dataObj: UserBasic) => {
    const btnText = user?.role === UserRole.Admin ?
      t("buttons.details") :
      t("buttons.remove");

    return [
      <ActionButton key="actionBtn" dataObj={dataObj} fn={handleOnClickAction} text={btnText} />
    ];
  };

  // Table headers and sort fields for TwTableCardList
  const columnHeaderValues = getColumnHeaderValues(getColumns, intl, t, handleOnClickAction);

  return (
    // Main container
    <div className="mf-table-container mf-flex-y-fill overflow-visible">
      {/* Pagination + Table */}
      <Pagination<UserEdge, GetPaginatedUsersQuery, GetPaginatedUsersQueryVariables>
        ref={paginationRef} props={{queryHook: useGetPaginatedUsersQuery, paginationName, className: "mx-4 mb-4"}}
      >
        {(dataSlice, loading, displayAmount) => (
          <PaginationContentScrollable ref={scrollRef}>
            <div className="mx-4">
              {/* Filter */}
              <UsersFilterComponent
                onFilterSubmit={onFilterSubmit}
                setShowModal={setShowModal}
                displayAmount={displayAmount as number}
              />
              {
                mdBreakpoint ?
                  <DataTable
                    loading={loading}
                    displayAmount={displayAmount as number}
                    data={dataSlice.map(edge => edge.node) ?? []}
                    columns={getColumns(intl, t, handleOnClickAction)}
                  />
                  :
                  <TwTableCardList
                    dataArray={dataSlice.map(edge => edge.node)}
                    displayAmount={displayAmount as number}
                    dataLoading={loading}
                    actionButtons={getActionButtons}
                    tableHeaders={columnHeaderValues}
                    defaultSortField="lastname"
                    dataType="User"
                  />
              }
            </div>
          </PaginationContentScrollable>
        )}
      </Pagination>

      <AlertDialog open={openDialog}>
        <AlertDialogContent className={`${darkMode ? "bg-mfdarklight border-mfdarkbase text-white" : "bg-white"}`}>
          <AlertDialogHeader>
            <AlertDialogTitle>{t("translation:manageusers.dialog.confirmDeleteUser.title")}</AlertDialogTitle>
            <AlertDialogDescription className={`${darkMode ? " text-gray-200" : "text-gray-800"}`}>
              {t("translation:manageusers.dialog.confirmDeleteUser.subtitle")} <span className="font-semibold">{selectedUser?.firstname} {selectedUser?.lastname}</span>.
              <br />{t("translation:manageusers.dialog.confirmDeleteUser.note")}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => setOpenDialog(false)}
              className={`${darkMode ? "bg-mfdarklight border-gray-600 text-white hover:text-white hover:bg-mfdarklight hover:opacity-80" : "bg-white"}`}
            >
              {t("common:buttons.cancel")}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => deleteUser()}
              className="bg-mforange hover:bg-mforange hover:opacity-90"
            >
              {t("common:buttons.delete")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>


      {/* Create user form  --> Modal*/}
      {
        showModal === UsersPageModals.CreateUser &&
          <CreateUserModal
            user={user}
            hasErrors={hasErrors}
            setHasErrors={setHasErrors}
            setShowModal={setShowModal}
          />
      }
      {
        showModal === UsersPageModals.ManageUser &&
          <ManageUserAdminModal
            userId={selectedUser?._id || ""}
            setShowModal={setShowModal}
            onClickDeleteUser={openDeleteUserDialog}
          />
      }
      <ScrollToTopButton isVisible={isVisible} scrollToTop={scrollToTop} />
    </div>
  );
}

export default ManageUsers;
