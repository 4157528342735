import { t } from "i18next";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useCompanyData, useCompanyDetails, useUserData } from "@/hooks";
import { toast } from "react-toastify";
import { useEditCompanyMutationHook } from "@/hooks/mutations";
import { CompanyType } from "@/constants/enums";
import FormWrapper from "../FormWrapper";
import { UserRole } from "@/graphql/__generated__/graphql-operations";
import AddressForm from "./Address";
import FormInput from "@/components/shared/form/FormInput";
import FormTextArea from "@/components/shared/form/FormTextArea";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CompanyDetailsSchema, TCompanyDetailsSchema } from "@/models/Settings/CompanyDetails";
import { onInvalid } from "@/utils/form";
import { FormField } from "@/types";

function CompanyDetails(): JSX.Element {

  // Init form
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isDirty },
    setValue,
    watch,
  } = useForm<TCompanyDetailsSchema>({
    resolver: zodResolver(CompanyDetailsSchema),
    mode: "onChange",
  });

  // Hooks
  const company = useCompanyData();
  const companyDetails = useCompanyDetails({id: company?._id as string, typename: company?.__typename as string});
  const user = useUserData();
  const { editCompany } = useEditCompanyMutationHook();

  /**
   * Handles the form submission for updating company details.
   *
   * @param {TCompanyDetailsSchema} data - The updated company details data.
   */
  const onSubmit: SubmitHandler<TCompanyDetailsSchema> = (data: TCompanyDetailsSchema) => {
    if (isDirty && isValid) {
      if (company && company.__typename === CompanyType.ServiceProvider) {
        editCompany({
          id: company._id,
          companyInput: {
            serviceproviderinput: {
              companydetails: {...data }
            }
          },
        }, data, "Settings");
      } else {
        toast.error("Unable to submit data", { toastId: "addressSubmitError" });
      }
    }
  };

  /**
   * Recursively sets form values for nested objects.
   *
   * @param {object} obj - The object containing form values.
   * @param {string} parentKey - The prefix for nested object keys.
   */
  const setNestedFormValues = (obj: any, parentKey: string = "") => {
    Object.entries(obj).forEach(([key, value]) => {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof value === "object" && value !== null && !Array.isArray(value)) {
        setNestedFormValues(value, fullKey);
      } else {
        setValue(fullKey as keyof TCompanyDetailsSchema, value  as keyof TCompanyDetailsSchema);
      }
    });
  };

  // const setFormValues = () => {
  //   const { webpresence, ...restCompanyDetails } = companyDetails;
  //   Object.entries({...webpresence, ...restCompanyDetails} ).forEach(([key, value]) => {
  //     setValue(key as keyof TCompanyDetailsSchema, value as keyof TCompanyDetailsSchema);
  //   });
  // };


  // Define the fields for the form
  const companyDetailsFormFields: FormField[] = [
    {
      key: "industry",
      name: "industry",
      placeholder: t("settings.companydetails.industry"),
      title: t("settings.companydetails.industry"),
      type: "text",
      component: FormInput,
      className: "mf-input-field mb-2"
    },
    {
      key: "description",
      name: "description",
      placeholder: t("settings.companydetails.description"),
      title: t("settings.companydetails.description"),
      type: "text",
      component: FormTextArea,
      className: "mf-input-field mb-2"
    },
    {
      key: "yearlyrevenue",
      name: "yearlyrevenue",
      placeholder: t("settings.companydetails.yearlyrevenue"),
      title: t("settings.companydetails.yearlyrevenue"),
      type: "number",
      component: FormInput,
      className: "mf-input-field mb-2"
    },
    {
      key: "foundingyear",
      name: "foundingyear",
      placeholder: t("settings.companydetails.foundingyear"),
      title: t("settings.companydetails.foundingyear"),
      type: "number",
      component: FormInput,
      className: "mf-input-field mb-2"
    },
  ];

  const webPresenceFormFields: FormField[] = [
    {
      key: "website",
      name: "website",
      placeholder: t("settings.webpresence.website"),
      title: t("settings.webpresence.website"),
      type: "url",
      className: "mf-input-field mb-2"
    },
    {
      key: "facebook",
      name: "facebook",
      placeholder: t("settings.webpresence.facebook"),
      title: t("settings.webpresence.facebook"),
      type: "url",
      className: "mf-input-field mb-2"
    },
    {
      key: "twitter",
      name: "twitter",
      placeholder: t("settings.webpresence.twitter"),
      title: t("settings.webpresence.twitter"),
      type: "url",
      className: "mf-input-field mb-2"
    },
    {
      key: "linkedin",
      name: "linkedin",
      placeholder: t("settings.webpresence.linkedin"),
      title: t("settings.webpresence.linkedin"),
      type: "url",
      className: "mf-input-field mb-2"
    },
  ];

  return (
    <div>
      <div className="mb-4 select-none">
        <div className="flex space-x-2 items-center mb-2">
          <CheckCircleIcon className="h-6 w-6" />
          <h2 className="font-semibold text-lg">{t("settings.companydetails.title")}</h2>
        </div>
      </div>

      {/* Company details + web presence */}
      {(company?.__typename === CompanyType.ServiceProvider && user!.role === UserRole.Superuser) && (
        <>
          <p className="-mt-2 mb-4">{t("settings.companydetails.subtitle")}</p>
          <FormWrapper
            id="companyDetailsForm"
            onClickReset={() => {setNestedFormValues(companyDetails);}}
            handleSubmit={handleSubmit(onSubmit, onInvalid)}
          >
            <div className="md:flex w-full gap-4">
              <div className="md:w-[49%]">
                {companyDetailsFormFields.map(({ key, component: Component, ...restField }) => (
                  <div key={key} className={`w-full ${restField.type === "text" ? "md:pr-2" : ""}`}>
                    <Component
                      field={{...restField, required: false, defaultValue: companyDetails?.[restField?.name as string]}}
                      register={register}
                      errors={errors}
                      watch={watch}
                    />
                  </div>
                ))}
              </div>
              <div className="md:w-[49%]">
                {webPresenceFormFields.map(({ key,...restField }) => (
                  <div key={key} className={`w-full ${restField.type === "text" ? "md:pr-2" : ""}`}>
                    <FormInput
                      field={{...restField, required: false, defaultValue: companyDetails?.webpresence?.[restField?.name as string]}}
                      register={register}
                      errors={errors}
                      watch={watch}
                    />
                  </div>
                ))}
              </div>
            </div>
          </FormWrapper>
        </>
      )}

      {/* Address */}
      <div className="mt-4">
        <AddressForm />
      </div>
    </div>
  );
}

export default CompanyDetails;
