import { FormField } from "@/types/shared";

export const inputFields: FormField[] = [
  {
    placeholder: "Select a company",
    type: "text",
    required: true,
    name: "employerID",
    className: "mf-input-field",
    isDropdown: true,
    title: "Company",
    defaultValue: ""
  },
  {
    placeholder: "Select a user role",
    type: "text",
    required: true,
    name: "role",
    className: "mf-input-field",
    isDropdown: true,
    title: "User Role",
    defaultValue: ""
  },
  {
    placeholder: "First Name",
    type: "text",
    required: true,
    name: "firstname",
    className: "mf-input-field",
    title: "First Name",
  },
  {
    placeholder: "Last Name",
    type: "text",
    required: true,
    name: "lastname",
    className: "mf-input-field",
    title: "Last Name",
  },
  {
    placeholder: "Email",
    type: "email",
    required: true,
    name: "mail",
    className: "mf-input-field",
    title: "Email",
    autoComplete: "new-password"
  },
  {
    placeholder: "Password",
    type: "password",
    required: true,
    name: "password",
    className: "mf-input-field",
    title: "Password",
    autoComplete: "new-password"
  },
];
