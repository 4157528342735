import React, { createContext, useContext, useEffect, useState } from "react";

export type Theme = "dark" | "light" | "system";

interface ThemeContextProps {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  darkMode: boolean;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const getInitialTheme = (): Theme => {
    const storedTheme = localStorage.getItem("theme") as Theme | null;
    return storedTheme || "system";  // Default to "system" if not set
  };

  const [theme, setTheme] = useState<Theme>(getInitialTheme);

  // Helper function to determine if dark mode is enabled based on the theme
  const isDarkModeEnabled = (selectedTheme: Theme): boolean => {
    if (selectedTheme === "dark") {
      return true;
    }
    if (selectedTheme === "light") {
      return false;
    }
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  };

  const [darkMode, setDarkMode] = useState<boolean>(() => isDarkModeEnabled(getInitialTheme()));

  useEffect(() => {
    const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)");

    const applyTheme = (selectedTheme: Theme) => {
      const isDark = isDarkModeEnabled(selectedTheme);
      setDarkMode(isDark);
    };

    applyTheme(theme);

    // Listen for system theme changes when "system" theme is active
    if (theme === "system") {
      const handleSystemThemeChange = (event: MediaQueryListEvent) => {
        setDarkMode(event.matches);
      };
      prefersDarkMode.addEventListener("change", handleSystemThemeChange);
      return () => prefersDarkMode.removeEventListener("change", handleSystemThemeChange);
    }
  }, [theme]);

  // Store the selected theme in localStorage
  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme, darkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
