import { FormField } from "@/types";
import { UseFormRegister, UseFormStateReturn, FieldValues, Path, UseFormWatch } from "react-hook-form";
import _ from "lodash";

type Props<T extends FieldValues> = {
  className?: string;
  field: FormField;
  errors: UseFormStateReturn<T>["errors"];
  register: UseFormRegister<T>;
  watch?: UseFormWatch<T>;
};

/**
 * FormSelect component for rendering a select dropdown in a form.
 * Supports rendering options from the provided input field configuration.
 *
 * @template T - The schema type, such as TLoginSchema or TCreateUserSchema.
 * @param {Props<T>} props - The props object.
 * @param {InputGroup} props.field - The input field configuration, including options.
 * @param {UseFormStateReturn<T>["errors"]} props.errors - The form errors.
 * @param {UseFormRegister<T>} props.register - The register function from react-hook-form.
 *
 * @returns {JSX.Element} The rendered form select component.
 *
 * @example
 * ```tsx
 * import { useForm } from "react-hook-form";
 * import { TCreateUserSchema, CreateUserSchema } from "@/schemas/CreateUserSchema";
 * import FormSelect from "@/components/FormSelect";
 *
 * const CreateUserForm = () => {
 *   const { register, handleSubmit, formState: { errors } } = useForm<TCreateUserSchema>({
 *     resolver: zodResolver(CreateUserSchema),
 *   });
 *
 *   return (
 *     <form onSubmit={handleSubmit((data) => console.log(data))}>
 *       <FormSelect
 *         field={{ name: "role", title: "Role", options: ["USER", "ADMIN", "SUPERUSER"], required: true }}
 *         errors={errors}
 *         register={register}
 *       />
 *     </form>
 *   );
 * };
 * ```
 */
const FormSelect = <T extends FieldValues>({ field, errors, register, watch }: Props<T>): JSX.Element => {

  // Use lodash's get to retrieve nested errors safely
  const fieldError = _.get(errors, field.name as Path<T>);

  return (
    <div key={`select-input-${field.name}`} className="relative z-10">
      <p className={`select-none dark:text-gray-200 ${fieldError && "text-red-500"}`}>
        {field.title}
      </p>
      <select
        className={`${field.className} ${fieldError && "border-red-500"}
                    text-gray-800 z-1 rounded-sm focus:border-mfpurple dark:focus:border-mforange h-[40px]`}
        data-cy={`${field.name}-select`}
        autoComplete={field.autoComplete}
        defaultValue={field.defaultValue}
        value={watch && watch(field.name as Path<T>)}
        {...register(field.name as Path<T>, { required: field.required })}
      >
        <option value="" disabled>{field.placeholder}</option>
        {field.dropdownOptions?.map((option) => (
          <option key={`${field.name}-select-option-${Math.random()}`} value={option.value as Path<T>}>
            {option.text as Path<T>}
          </option>
        ))}
      </select>
      {fieldError && (
        <p className="input-error-message">
          {fieldError?.message as string}
        </p>
      )}
    </div>
  );
};

export default FormSelect;
