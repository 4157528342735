import { handleBlockchainExplorerLink } from "@/blockchain/helpers";
import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { PaymentModals, TxResult } from "@/constants/enums";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { propsPaymentResultModal } from "../props";
import { FramerButton } from "@/components/shared";

function PaymentResultModal(props: propsPaymentResultModal) {

  // Props
  const {
    pooladdress,
    interestTransactionHash,
    depositTransactionHash,
    transactionResultType,
    setShowModal,
  } = props;

  // Hooks
  const navigate = useNavigate();
  const { t } = useTranslation(["modals"]);

  // Constants
  const isError = TxResult.ERROR === transactionResultType;

  return (
    <BlankModal
      setShowModal={() => { setShowModal(PaymentModals.NoModal); }}
      className="px-6"
      customContentCSS="mf-modal-content"
      title=""
      titleCSS="text-lg dark:text-white"
    >
      <BlankModalContent>
        {/* Heading */}
        <div className={`text-lg font-bold text-center ${isError ? "text-red-500" : "text-green-500"}`}>
          {isError ? t("interestpaymentresult.fail.heading") : t("interestpaymentresult.success.heading")}
        </div>

        {/* Image / animation */}
        <div className="mb-6">
          {!isError && (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" className="w-28 h-28 mx-auto mt-2 text-green-500">
              <circle className="animated-icon-path animated-icon-circle" fill="none" stroke="currentColor" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="animated-icon-path animated-icon-check" fill="none" stroke="currentColor" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          )}
          {isError && (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" className="w-28 h-28 mx-auto mt-2 text-red-500">
              <circle className="animated-icon-path animated-icon-circle" fill="none" stroke="currentColor" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="animated-icon-path animated-icon-line" fill="none" stroke="currentColor" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="animated-icon-path animated-icon-line" fill="none" stroke="currentColor" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </div>

        {/* Subtext */}
        <div className="mb-4">
          <div className="dark:text-white">
            {isError ?
              <p>
                {t("interestpaymentresult.fail.text")}
              </p>:
              (
                <>
                  <p>
                    {t("interestpaymentresult.success.thankyou")} <br /> {t("interestpaymentresult.success.text")}
                  </p><br />
                  <p>{t("interestpaymentresult.success.note")}</p>
                </>
              )}
          </div>
        </div>

        {/* Contract pool address */}
        <div className="mb-4">
          <p className="font-semibold dark:text-gray-200">{t("translation:interestpayment.labels.contractaddress")}</p>
          <a
            href={handleBlockchainExplorerLink("address", pooladdress as string)}
            target="_blank"
            rel="noreferrer"
            className="text-blue-400 break-all"
          >{pooladdress}</a>
        </div>

        {/* Transaction Details */}
        {
          (interestTransactionHash !== "" || depositTransactionHash !== "") && (
            <div className="flex flex-col gap-2 mt-4 mb-8">
              <h2 className="font-semibold dark:text-white">{t("interestpaymentresult.transactiondetails.title")}</h2>
              {
                interestTransactionHash !== "" &&
                <div>
                  <p className="text-xs text-gray-400 mb-1 dark:text-gray-300">{t("interestpaymentresult.transactiondetails.interesthash")}</p>
                  <p className="text-xs dark:text-white break-all">{interestTransactionHash}</p>
                </div>
              }
              {
                depositTransactionHash !== "" &&
                <div>
                  <p className="text-xs text-gray-400 mb-1 dark:text-gray-300">{t("interestpaymentresult.transactiondetails.deposithash")}</p>
                  <p className="text-xs dark:text-white break-all">{depositTransactionHash}</p>
                </div>
              }
            </div>
          )
        }

        {/* Disclaimer */}
        <div className="mb-8 dark:text-white">
          {
            interestTransactionHash !== "" &&
              <p className="text-xs">
                {t("interestpaymentresult.notes.viewinteresttx")}
                <a href={handleBlockchainExplorerLink("tx", interestTransactionHash)} rel="noreferrer" target="_blank" className="text-blue-400"> Explorer</a>
              </p>
          }
          {
            depositTransactionHash !== "" &&
              <p className="text-xs">
                {t("interestpaymentresult.notes.viewdeposittx")}
                <a href={handleBlockchainExplorerLink("tx", depositTransactionHash)} rel="noreferrer" target="_blank" className="text-blue-400"> Explorer</a>
              </p>
          }
          {
            isError &&
              <>
                <p className="text-xs my-2">{t("interestpaymentresult.notes.errormsg")}</p>
                <p className="text-xs">{t("interestpaymentresult.notes.contactsupport")} <a href="mailto:contact@myron.finance" className="text-blue-400">contact@myron.finance</a></p>
              </>
          }
        </div>
      </BlankModalContent>

      <BlankModalFooter>
        {/* Buttons */}
        <div className={`flex items-center ${!isError ? "justify-center" : "justify-between"}`}>
          {!isError && (
            <FramerButton
              onClick={() => {
                setShowModal(PaymentModals.NoModal);
                navigate("/user/dashboard");
              }}
              className="mf-btn-action-small-primary-filled"
            >
              {t("common:buttons.close")}
            </FramerButton>
          )}
          {isError && (
            <>
              <FramerButton
                onClick={() => {
                  setShowModal(PaymentModals.NoModal);
                }}
                className="mf-btn-action-small-primary-outline"
              >
                {t("common:buttons.cancel")}
              </FramerButton>
              <FramerButton
                onClick={() => {
                  setShowModal(PaymentModals.PaymentSummary);
                }}
                className="mf-btn-action-small-primary-filled"
              >
                {t("common:buttons.retry")}
              </FramerButton>
            </>
          )}
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default PaymentResultModal;
