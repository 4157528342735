import { FormInput} from "@/components/shared";
import { useCompanyData } from "@/hooks";
import { useBankData } from "@/hooks/cache/useBankData";
import { BankDetailsSchema, TBankDetailsSchema } from "@/models";
import { FormField } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

function Bank() {

  // Init form
  const {
    register,
    watch,
  } = useForm<TBankDetailsSchema>({
    resolver: zodResolver(BankDetailsSchema),
    mode: "onChange",
  });

  // Hooks
  const { t } = useTranslation();
  const company = useCompanyData();

  const bankDetails = useBankData({ id: company?._id as string, typename: company?.__typename as string });

  const formFields: FormField[] = [
    {
      disabled: true,
      name: "iban",
      placeholder: t("settings.bankdetails.iban"),
      title: t("settings.bankdetails.iban"),
      type: "text",
      required: false,
      className: "mf-input-field mb-2",
      defaultValue: bankDetails?.iban,
    },
    {
      disabled: true,
      name: "bic",
      placeholder: t("settings.bankdetails.bic"),
      title: t("settings.bankdetails.bic"),
      type: "text",
      required: false,
      className: "mf-input-field mb-2",
      defaultValue: bankDetails?.bic,
    },
    {
      disabled: true,
      name: "referencenumber",
      placeholder: t("settings.bankdetails.reference"),
      title: t("settings.bankdetails.reference"),
      type: "text",
      required: false,
      className: "mf-input-field mb-2",
      defaultValue: company?.referencenumber,
    }
  ];

  return (
    <div>
      <div className="flex space-x-2 items-center mb-4">
        {/* <CheckCircleIcon className={`h-6 w-6 ${state.bankaccount.verified ? "text-green-500" : "text-gray-400"}`} /> */}
        <h2 className="font-semibold text-lg select-none">{t("settings.bankdetails.title")}</h2>
        <div className={`rounded-sm text-white px-2 py-[2px] text-xs select-none
                        ${company?.bankaccount.verified ? "bg-green-500" : "bg-red-500"}`}
        >
          {company?.bankaccount.verified ? "Verified" : "Unverified"}
        </div>
      </div>
      <div>
        {formFields.map((field) => (
          <div
            className="w-full md:w-1/2 md:pr-2"
            key={field.name}
          >
            <FormInput
              field={field}
              errors={{}}
              register={register}
              watch={watch}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Bank;
