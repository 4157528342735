import { AssetFinancingRound, useGetPaginatedAssetFinancingRoundsQuery } from "@/graphql/__generated__/graphql-operations";
import InvestmentStatsBar from "./InvestmentStatsBar";
import RecentActivity from "./RecentActivity";
import PortfolioDiversification from "./PortfolioDiversification";
import { WithdrawalCard } from "@/components/shared/withdrawal";
import { useFinancierData } from "@/hooks";
import { ScrollToTopButton } from "@/components/shared";
import useScrollToTop from "@/hooks/useScrollToTop";
import { useRef } from "react";
import SPsOpenToInvest from "./SPsOpenToInvest";

export function FinancierDashboard() {
  // Ref use for Scroll To Top
  const scrollRef = useRef<HTMLDivElement>(null);

  // Hooks
  const financierData = useFinancierData();
  const { isVisible, scrollToTop } = useScrollToTop({ ref: scrollRef});

  const { data, loading } = useGetPaginatedAssetFinancingRoundsQuery({
    variables: {
      first: 5,
      after: null,
      poolId: null
    },
    fetchPolicy: "cache-first"
  });

  if(!financierData) {
    return ( <p> We are currently loading.....</p>);
  }

  return (
    <div ref={scrollRef} className="mf-financier-dashboard-grid-container">
      {/* First Column */}
      <div className="mf-financier-dashboard-grid-column-1">
        {/* Stats container */}
        <div className="row-span-1">
          <InvestmentStatsBar
            totalInvestedAmount={financierData.totalInvestedAmount}
            currentWorthIncludingInterest={financierData.currentWorthIncludingInterest}
            averageInterestRate={financierData.averageInterestRate}
          />
        </div>

        {/* Portfolio Diversification */}
        <div className="row-span-1">
          <PortfolioDiversification />
        </div>

        {/* Recent Activity */}
        <div className="row-span-1">
          <RecentActivity
            loading={loading}
            assetfinancingrounds={data?.paginatedAssetFinancingRounds.edges.map((edge) => edge.node) as AssetFinancingRound[] ?? []}
          />
        </div>
      </div>

      {/* Second Column */}
      <div className="mf-financier-dashboard-grid-column-2">
        {/* Withdrawal Card */}
        <div className="row-span-1">
          <WithdrawalCard reverseLayout />
        </div>

        {/* Available Investments */}
        <div className="row-span-1 flex">
          <SPsOpenToInvest />
        </div>
      </div>

      <ScrollToTopButton scrollToTop={scrollToTop} isVisible={isVisible} />
    </div>
  );
}
