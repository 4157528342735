import { EndpointType } from "@/graphql/__generated__/graphql-operations";
import { FormField } from "@/types";
import { FieldValues } from "react-hook-form";
import { FormGroupProps } from "../props";
import FormGroupWrapper from "./FormGroupWrapper";

/**
 * Component for rendering the Asset Documentary form group.
 *
 * @template T - Type of field values.
 * @param {Props<T>} props - Props for the component.
 * @param {UseFormRegister<T>} props.register - The register function from react-hook-form.
 * @param {UseFormStateReturn<T>["errors"]} props.errors - The errors object from react-hook-form.
 * @param {UseFormWatch<T>} props.watch - The watch function from react-hook-form.
 * @returns {JSX.Element} - The rendered Asset Documentary form group.
 */
const AssetDocumentary = <T extends FieldValues>({ register, errors, watch }: FormGroupProps<T>): JSX.Element => {

  const formFields: FormField[] = [
    {
      key: "asset-documentary-input-endpoint",
      name: "serviceproviderinput.assetdocumentary.endpoint",
      type: "text",
      title: "Endpoint",
      required: true,
      placeholder: "Enter the endpoint URL",
      className: "mf-input-field min-w-[250px]",
    },
    {
      key: "asset-documentary-input-authendpoint",
      name: "serviceproviderinput.assetdocumentary.authendpoint",
      type: "text",
      title: "Auth Endpoint",
      required: true,
      placeholder: "Enter the auth endpoint URL",
      className: "mf-input-field min-w-[250px]",
    },
    {
      key: "asset-documentary-input-username",
      name: "serviceproviderinput.assetdocumentary.username",
      type: "text",
      title: "Username",
      required: true,
      placeholder: "Enter the username",
      className: "mf-input-field min-w-[250px]",
    },
    {
      key: "asset-documentary-input-password",
      name: "serviceproviderinput.assetdocumentary.password",
      type: "password",
      title: "Password",
      required: true,
      autoComplete: "new-password",
      placeholder: "Enter the password",
      className: "mf-input-field min-w-[250px]",
    },
    {
      key: "asset-documentary-input-endpointType",
      name: "serviceproviderinput.assetdocumentary.endpointType",
      isDropdown: true,
      title: "Endpoint Type",
      placeholder: "Select an endpoint type",
      required: true,
      className: "mf-input-field min-w-[250px]",
      dropdownOptions: [
        { text: "Coredinate", value: EndpointType.Coredinate },
        { text: "POP", value: EndpointType.Pop },
        { text: "Plentymarkets", value: EndpointType.Plentymarkets },
      ],
      defaultValue: "",
    },
  ];

  return (
    <FormGroupWrapper
      title="Asset Documentary"
      register={register}
      errors={errors}
      watch={watch}
      formFields={formFields}
    />
  );
};

export default AssetDocumentary;
