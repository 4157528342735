import { GetPaginatedCreditLimitRequestsDocument, useCreateCreditLimitRequestMutation } from "@/graphql/__generated__/graphql-operations";
import { removeToast } from "@/utils/helpers";
import { t } from "i18next";
import { toast } from "react-toastify";


export default function useCreateCreditLimitRequestMutationHook() {
  const [createCreditLimitRequestMutation, {loading, error}] = useCreateCreditLimitRequestMutation({
    onCompleted(result) {
      if(result.createCreditLimitRequest && result.createCreditLimitRequest._id) {
        toast.success(t("tosts:newCreditLimitRequestSuccess"), {toastId:"newCreditLimitRequestSuccess"});
      }
      removeToast(toast, "newCreditLimitRequestSuccess", 3000);
    },
    update(client, { data }) {
      const paginatedCreditLimitRequestsQuery: any = client.readQuery({ query: GetPaginatedCreditLimitRequestsDocument });
      if (!paginatedCreditLimitRequestsQuery) return;

      const newPaginatedCreditLimitRequestsQuery = {
        paginatedCreditLimitRequests: {
          __typename: "CreditLimitRequestConnection",
          edges: [
            {
              __typename: "CreditLimitRequestEdge",
              node: {
                ...data?.createCreditLimitRequest
              },
              cursor: new Date().toISOString(),
            },
            ...paginatedCreditLimitRequestsQuery.paginatedCreditLimitRequests.edges,
          ],
          pageInfo: paginatedCreditLimitRequestsQuery.paginatedCreditLimitRequests.pageInfo,
        }
      };

      client.writeQuery({
        query: GetPaginatedCreditLimitRequestsDocument,
        data: newPaginatedCreditLimitRequestsQuery,
      });
    }
  });

  return {
    createCreditLimitRequestMutation,
    loading,
    error
  };
}
