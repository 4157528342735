import { FormField } from "@/types";
import { FieldValues } from "react-hook-form";
import { FormGroupProps } from "../props";
import FormGroupWrapper from "./FormGroupWrapper";

/**
 * Renders the bank details form group.
 *
 * @template T - The type of field values.
 * @param {Props<T>} props - The component props.
 * @param {Function} props.register - The register function from react-hook-form.
 * @param {Record<string, any>} props.errors - The errors object from react-hook-form.
 * @param {Function} props.watch - The watch function from react-hook-form.
 * @returns {JSX.Element} The bank details form group component.
 */
const BankDetails = <T extends FieldValues>( { register, errors, watch }: FormGroupProps<T>): JSX.Element => {

  const formFields: FormField[] = [
    // *** Note
    // `vatnumber` sits in the GeneralInformationSchema
    // We display it with the Bank Details but the field is still
    // binded to it's correct schema
    {
      key: "bank-details-input-vat-number",
      name: "vatnumber", // No `bankaccount.` prefix
      type: "text",
      title: "VAT number",
      placeholder: "VA123456789",
      required: true,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "bank-details-input-beneficiary-id",
      name: "bankaccount.beneficiaryID",
      type: "text",
      title: "Beneficiary ID",
      placeholder: "Beneficiary ID",
      required: true,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "bank-details-input-iban",
      name: "bankaccount.iban",
      type: "text",
      title: "IBAN",
      placeholder: "IBAN",
      required: true,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "bank-details-input-bic",
      name: "bankaccount.bic",
      type: "text",
      title: "BIC",
      placeholder: "BIC",
      required: true,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "bank-details-input-bankname",
      name: "bankaccount.name",
      type: "text",
      title: "Bank name",
      placeholder: "Bank name",
      required: true,
      className: "mf-input-field md:min-w-[150px]",
    },
  ];

  return (
    <FormGroupWrapper
      title="Bank Details"
      register={register}
      errors={errors}
      watch={watch}
      formFields={formFields}
    />
  );
};

export default BankDetails;
