import { updatePaginationState } from "@/cache/appstate/WriteQueries";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/Popover";
import { useTheme } from "@/context/ThemeProvider";
import { FramerButton, RadioButton, TagItem } from "@/components/shared";
import { AssetFinancingStatus, GetPaginatedCompaniesQueryVariables } from "@/graphql/__generated__/graphql-operations";
import { usePaginationState } from "@/hooks/cache/appState/usePaginationState";
import { PaginationState } from "@/types";
import { ArrowPathIcon, CheckIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

type Props = {
  className?: string;
  displayAmount: number;
  onFilterSubmit: (_variables: GetPaginatedCompaniesQueryVariables) => void;
}

type RadioOption = {
  label: string;
  value: AssetFinancingStatus;
}

function CompanyFilter(props: Props) {
  // Props
  const {
    displayAmount,
    onFilterSubmit
  } = props;

  // Hooks
  const { t } = useTranslation(["common"]);
  const { darkMode } = useTheme();

  const paginationState: PaginationState["paginatedCompanies"] = usePaginationState("paginatedCompanies");
  const { filters: { status } } = paginationState;

  // Constants
  const backgroundColour = darkMode ? "bg-mfdarklighter text-white border-transparent shadow-gray-900": "bg-white";
  const afpStatusOptions: RadioOption[] = [
    { label: "Active", value: AssetFinancingStatus.Active },
    { label: "Open", value: AssetFinancingStatus.Open },
    { label: "Onboarding", value: AssetFinancingStatus.Onboarding },
    { label: "Closed", value: AssetFinancingStatus.Closed },
  ];

  /**
   * OnChange Handler for Radio buttons
   */
  const onChangeRadioButton = (key: string, value: string) => {
    if (key === "status") {
      updatePaginationState({
        paginatedCompanies: {
          filters: {
            status: value as AssetFinancingStatus
          }
        }
      });
    }
  };

  /**
   * OnClick handler for Reset button
   */
  const handleResetClick = () => {
    props.onFilterSubmit({
      first: displayAmount,
      after: null,
      status: null,
    });
    toast.success(t("common:text.filterremoved"), {toastId: "userFilterRemoved"});
  };

  /**
   * OnClick handler for Submit button
   */
  const handleSubmitClick = () => {
    onFilterSubmit({
      first: displayAmount,
      status,
    });
    toast.success(t("common:text.filterapplied"), {toastId: "userFilterApplied"});
  };

  return (
    <div className="mb-1 bg-white dark:bg-mfdarklight">
      {/* Filters container */}
      <div className="md:flex items-center mt-2 justify-between">
        {/* Filter box */}
        <Popover>
          <FramerButton
            whileHover={undefined} // Disable hover effect
          >
            <PopoverTrigger
              className="border-[1px] border-gray-200 rounded-md flex item-center h-[40px] max-md:w-full md:w-[200px] px-2
                  dark:border-white select-none"
            >
              <div className="mr-2 h-full items-center flex">
                <FunnelIcon
                  className="w-5 h-5 flex-shrink-0 dark:text-white cursor-pointer"
                />
                {
                  status === null &&
              <p className="text-sm text-gray-400 pl-2">
                {t("translation:portfoliopage.filter.selectstatus")}
              </p>
                }
              </div>
              <div className="flex items-center select-none h-full">
                {status !== null &&
                <TagItem label={status as string} />
                }
              </div>
            </PopoverTrigger>
          </FramerButton>
          <PopoverContent
            className={`${backgroundColour} shadow-lg h-auto w-full p-6 min-w-[200px]
                      rounded-md dark:text-white`}
          >
            {/* Status filters */}
            {afpStatusOptions.map(option => (
              <RadioButton
                key={option.value}
                option={option}
                checked={status === option.value}
                onChange={(value) => onChangeRadioButton("status", value as string)}
              />
            ))}
          </PopoverContent>
        </Popover>

        {/* Right container --> Action buttons */}
        <div className="flex justify-end mt-4 md:mt-0 md:items-center gap-x-1 md:space-x-2">
          {/* Reset button */}
          <FramerButton
            onClick={handleResetClick}
            className="mf-btn-action-small-primary-outline mr-1 md:mr-0"
          >
            <ArrowPathIcon className="w-5 h-5 md:hidden" />
            <div className="hidden md:flex">
              {t("common:buttons.reset")}
            </div>
          </FramerButton>

          {/* Submit button */}
          <FramerButton
            onClick={handleSubmitClick}
            className="mf-btn-action-small-primary-filled"
          >
            <CheckIcon className="w-5 h-5 md:hidden" />
            <div className="hidden md:flex">
              {t("common:buttons.submit")}
            </div>
          </FramerButton>
        </div>
      </div>
    </div>
  );
}

export default CompanyFilter;
