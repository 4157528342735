import { EndpointType } from "@/graphql/__generated__/graphql-operations";
import { nativeEnumSchema, nonEmptyString } from "@/utils/form";
import { z } from "zod";

export const AssetDocumentarySchema = z.object({
  endpoint: z.string().url("Endpoint is invalid"),
  authendpoint: z.string().url("Auth endpoint is invalid"),
  username: nonEmptyString("Username"),
  password: nonEmptyString("Password"),
  endpointType: nativeEnumSchema(EndpointType, "Endpoint")
});

export const AssetDocumentaryDefaultValues = {
  endpoint: "",
  authendpoint: "",
  username: "",
  password: "",
  endpointType: undefined,
};

export type TAssetDocumentarySchema = z.infer<typeof AssetDocumentarySchema>;
