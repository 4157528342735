import { FormField } from "@/types";
import { FieldValues } from "react-hook-form";
import { FormGroupProps } from "../props";
import FormGroupWrapper from "./FormGroupWrapper";

/**
 * Renders the Web Presence form group.
 *
 * @template T - The type of field values.
 * @param {Props<T>} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
const WebPresence = <T extends FieldValues>( { register, errors, watch }: FormGroupProps<T>): JSX.Element => {

  const formFields: FormField[] = [
    {
      key: "web-presence-input-website",
      name: "serviceproviderinput.companydetails.website",
      type: "url",
      title: "Website",
      placeholder: "https://www.example.com",
      required: false,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "web-presence-input-linkedin",
      name: "serviceproviderinput.companydetails.linkedin",
      type: "url",
      title: "LinkedIn",
      placeholder: "https://www.linkedin.com/in/example",
      required: false,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "web-presence-input-facebook",
      name: "serviceproviderinput.companydetails.facebook",
      type: "url",
      title: "Facebook",
      placeholder: "https://www.facebook.com/example",
      required: false,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "web-presence-input-twitter",
      name: "serviceproviderinput.companydetails.twitter",
      type: "url",
      title: "Twitter",
      placeholder: "https://www.twitter.com/example",
      required: false,
      className: "mf-input-field md:min-w-[150px]",
    }
  ];

  return (
    <FormGroupWrapper
      title="Web Presence"
      register={register}
      errors={errors}
      watch={watch}
      formFields={formFields}
    />
  );
};

export default WebPresence;
