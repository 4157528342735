import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { AssetsPageModals, AssetType, CompanyType } from "@/constants/enums";
import { AttributePattern, FinancingDetails, InventoryAsset, ReceivableAsset, useGetAssetDetailsByIdQuery } from "@/graphql/__generated__/graphql-operations";
import { Dispatch, SetStateAction } from "react";
import { FramerButton, TextInfoGroupAlt } from "@/components/shared";
import { useCompanyData, useEmployeeData } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/Table";
import { formatDateLocale, formatNumberLocale, toNormalCase } from "@/utils/helpers";
import { IntlShape, useIntl } from "react-intl";
import useMdBreakpoint from "@/hooks/useMdBreakpoint";
import { TableRowSkeleton, TextInfoGroupSkeletons } from "@/components/skeletons";

type Props = {
  setShowModal: Dispatch<SetStateAction<AssetsPageModals>>;
  asset: InventoryAsset | ReceivableAsset | undefined;
}

function AssetsDetailsModal({ setShowModal, asset }: Props) {

  // Hooks
  const user = useEmployeeData();
  const company = useCompanyData();
  const navigate = useNavigate();
  const { t } = useTranslation(["modals", "tables", "translation"]);
  const intl = useIntl();
  const mdBreakpoint = useMdBreakpoint();
  const { data, loading } = useGetAssetDetailsByIdQuery({
    variables: {
      assetId: asset?._id || ""
    },
    fetchPolicy: "cache-first"
  });

  const assetDetails = data?.getAssetDetailsByID;
  const isServiceProvider = company?.__typename === "ServiceProvider";

  /**
   * OnClick handler for the customer info button
   * Sends user to Customers Overview page
   * @param {ReceivableAsset} asset - Receivable asset object
   */
  function handleCustomerHyperlink(asset: ReceivableAsset) {
    if ("customer" in asset && asset.customer) {
      const id = asset.customer?._id;
      if (id) {
        navigate(`/user/customersoverview?id=${id}`);
      }
    }
  }

  // If no asset, return null
  if (!asset) return null;

  /**
   * Format additional details content based off type
   * @param {AttributePattern} item
   * @param {IntlShape} intl
   * @returns {string | undefined}
   */
  function getContent(item: AttributePattern, intl: IntlShape): string | undefined {
    switch (item.type) {
    case "date":
      return formatDateLocale(intl, item.value);
    case "number":
      return formatNumberLocale(intl, item.value, "decimal");
    case "string":
      return toNormalCase(item.value);
    default:
      return item.value;
    }
  }

  /**
   * Conditional rendering of financing details based off asset type
   * @param {string | undefined} assetType
   * @param {FinancingDetails} item
   * @param {IntlShape} intl
   * @returns {JSX.Element | null}
   */
  function renderFinancingDetails(assetType: string | undefined, item: FinancingDetails, intl: IntlShape): JSX.Element | null {
    if (!assetType) return null;

    if (assetType === AssetType.Inventory) {
      return (
        <>
          <TextInfoGroupAlt label={t("assetdetails.table.inventory.date")} content={formatDateLocale(intl, item.movementDate)} />
          <TextInfoGroupAlt label={t("assetdetails.table.inventory.quantity")} content={item.movementQuantity} />
          <TextInfoGroupAlt label={t("assetdetails.table.inventory.movement")} content={item.movementType} />
        </>
      );
    }

    if (assetType === AssetType.Receivable) {
      return (
        <>
          <TextInfoGroupAlt label={t("assetdetails.table.receivable.created")} content={formatDateLocale(intl, item.createdAt)} />
          <TextInfoGroupAlt label={t("assetdetails.table.receivable.financed")} content={item.isFinanced ? t("assetdetails.textinfogroups.yes") : t("assetdetails.textinfogroups.no")} />
          <TextInfoGroupAlt label={t("assetdetails.table.receivable.status")} content={item.financingStatus} />
        </>
      );
    }

    return null;
  }

  return (
    <BlankModal
      setShowModal={() => {
        setShowModal(AssetsPageModals.NoModal);
      }}
      title={t("assetdetails.title")}
      className="px-6"
      customContentCSS="mf-modal-content lg:min-w-[580px]"
    >
      <BlankModalContent>
        {/* Base Details */}
        <div className="flex flex-col gap-y-2 mb-4">
          {
            asset.__typename === AssetType.Inventory &&
              <>
                <div className="flex flex-wrap gap-x-8 gap-y-4 justify-between">
                  <TextInfoGroupAlt label={t("assetdetails.textinfogroups.assetid")} content={asset.idFromDocumentary} />
                  <TextInfoGroupAlt label={t("assetdetails.textinfogroups.value")} content={formatNumberLocale(intl, asset.value, "currency")} />
                  <TextInfoGroupAlt label={t("assetdetails.textinfogroups.quantityininventory")} content={asset.quantity || 0} />
                  <TextInfoGroupAlt label={t("assetdetails.textinfogroups.shopurl")} content={asset.shopURL} isLink linkText={t("assetdetails.textinfogroups.view")} />
                </div>
              </>
          }
          {
            asset.__typename === AssetType.Receivable &&
              <>
                <div className="flex flex-wrap gap-x-8 gap-y-4 justify-between">
                  <TextInfoGroupAlt label={t("assetdetails.textinfogroups.assetid")} content={asset.idFromDocumentary} />
                  <TextInfoGroupAlt label={t("assetdetails.textinfogroups.value")} content={formatNumberLocale(intl, asset.value, "currency")} />
                  {
                    isServiceProvider &&
                    <TextInfoGroupAlt label={t("assetdetails.textinfogroups.customername")} content={asset.customer.name} />
                  }
                </div>
              </>
          }
        </div>

        {/* Additional Details */}
        {
          loading ?
            <div className="mb-4">
              <h3 className="text-sm font-semibold">{t("assetdetails.additionaltitle")}</h3>
              <div className="flex gap-y-4 gap-x-8 flex-wrap p-4 rounded-sm bg-gray-100 dark:bg-mfdarklighter overflow-y-auto max-h-[200px] overflow-x-hidden">
                <TextInfoGroupSkeletons count={2} />
              </div>
            </div>
            :
            assetDetails?.additionals && assetDetails?.additionals.length > 0 &&
            <div className="mb-4">
              <h3 className="text-sm font-semibold">{t("assetdetails.additionaltitle")}</h3>
              <div className="flex gap-y-4 gap-x-8 flex-wrap xl:justify-between p-4 rounded-sm bg-gray-100 dark:bg-mfdarklighter overflow-y-auto max-h-[200px] overflow-x-hidden">
                {/* Skeleton */}
                {
                  assetDetails?.additionals.map((item: AttributePattern) => {
                    const content = getContent(item, intl);

                    return (
                      <TextInfoGroupAlt
                        key={item.key}
                        label={item.key}
                        content={content}
                        className="2xl:w-[40%] space-y-1 flex flex-col flex-shrink-0 w-[150px]"
                        isLink={item.type === "url"}
                        linkText={"View"}
                      />
                    );
                  })
                }
              </div>
            </div>
        }

        {/* Asset Proofs*/}
        <div className="mb-4">
          <h3 className="text-sm font-semibold">
            {
              asset.__typename === AssetType.Inventory ?
                t("assetdetails.assetproofinventorytitle") :
                t("assetdetails.assetproofreceivabletitle")
            }
          </h3>

          <div className="flex gap-4 flex-wrap p-2 rounded-sm bg-gray-100 dark:bg-mfdarklighter overflow-x-hidden overflow-y-auto max-h-[200px]">
            {/* Mobile cards */}
            {
              !mdBreakpoint && (
                loading ?
                  <div className="flex flex-wrap gap-2 bg-white dark:bg-mfdarklight p-2 rounded-sm">
                    <TextInfoGroupSkeletons count={3} />
                  </div> :
                  assetDetails?.financingDetails.map((item: FinancingDetails) => (
                    <div className="flex flex-wrap w-full justify-between gap-2 bg-white dark:bg-mfdarklight p-2 rounded-sm" key={item.createdAt}>
                      {renderFinancingDetails(asset.__typename, item, intl)}
                    </div>
                  ))
              )
            }

            {/* Table */}
            {
              mdBreakpoint &&
              <Table>
                {/* Inventory */}
                {
                  asset.__typename === AssetType.Inventory &&
                  <>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="p-2 w-[100px]">{t("assetdetails.table.inventory.date")}</TableHead>
                        <TableHead className="p-2 text-right">{t("assetdetails.table.inventory.quantity")}</TableHead>
                        <TableHead className="p-2 text-right">{t("assetdetails.table.inventory.movement")}</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {
                        loading ?
                          <>
                            <TableRowSkeleton colSpan={3} className="h-[18px]" />
                            <TableRowSkeleton colSpan={3} className="h-[18px]" />
                            <TableRowSkeleton colSpan={3} className="h-[18px]" />
                          </>:
                          assetDetails?.financingDetails.map((item: FinancingDetails) => (
                            <TableRow key={item.createdAt}>
                              <TableCell className="p-2">{formatDateLocale(intl, item.movementDate)}</TableCell>
                              <TableCell className="p-2 text-right">{item.movementQuantity}</TableCell>
                              <TableCell className="p-2 text-right">{item.movementType}</TableCell>
                            </TableRow>
                          ))
                      }
                    </TableBody>
                  </>
                }
                {/* Receivable */}
                {
                  asset.__typename === AssetType.Receivable &&
                  <>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="p-2 w-[100px]">{t("assetdetails.table.receivable.created")}</TableHead>
                        <TableHead className="p-2 text-right">{t("assetdetails.table.receivable.financed")}</TableHead>
                        <TableHead className="p-2 text-right">{t("assetdetails.table.receivable.status")}</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {
                        loading ?
                          <>
                            <TableRowSkeleton colSpan={3} className="h-[18px]" />
                            <TableRowSkeleton colSpan={3} className="h-[18px]" />
                            <TableRowSkeleton colSpan={3} className="h-[18px]" />
                          </>:
                          assetDetails?.financingDetails.map((item) => (
                            <TableRow key={item.createdAt}>
                              <TableCell className="p-2">{formatDateLocale(intl, item.createdAt)}</TableCell>
                              <TableCell className="p-2 text-right">{item.isFinanced ? t("assetdetails.textinfogroups.yes") : t("assetdetails.textinfogroups.no")}</TableCell>
                              <TableCell className="p-2 text-right">{item.financingStatus}</TableCell>
                            </TableRow>
                          ))
                      }
                    </TableBody>
                  </>
                }
              </Table>
            }
          </div>
        </div>
      </BlankModalContent>

      <BlankModalFooter>
        {/* Action Buttons */}
        <div className="flex items-center justify-between">
          <FramerButton
            onClick={() => {
              setShowModal(AssetsPageModals.NoModal);
            }}
            className="mf-btn-action-small-primary-outline"
          >
            {t("common:buttons.close")}
          </FramerButton>
          {
            asset.__typename === AssetType.Receivable && user?.employer.__typename === CompanyType.ServiceProvider &&
              <FramerButton
                onClick={() => {
                  handleCustomerHyperlink(asset);
                }}
                className="mf-btn-action-small-primary-filled"
              >
                {t("translation:assetsoverview.customerinfo")}
              </FramerButton>
          }
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default AssetsDetailsModal;
