import { useFragment } from "@apollo/client";
import { ServiceProviderDataFragment, ServiceProviderDataFragmentDoc } from "@/graphql/__generated__/graphql-operations";

/**
 * Hook for reading and subscribing to the details of the logged-in Service Provider from Cache
 * @returns the data of the logged in Service Provider
 */
export function useServiceProviderData(spID?: string): ServiceProviderDataFragment | undefined {
  const companyId = sessionStorage.getItem("companyId");

  const { data } = useFragment({
    fragment: ServiceProviderDataFragmentDoc,
    from: {
      __typename: "ServiceProvider",
      _id: spID ?? companyId ?? "",  // Provide a fallback empty string for type safety
    },
  });

  return data;
}
