import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { formatNumberLocale } from "@/utils/helpers";
import { useIntl } from "react-intl";
import { Dispatch, SetStateAction } from "react";
import { TokenTransactionType } from "@/graphql/__generated__/graphql-operations";
import { TransactionsModals } from "@/constants/enums";
import { useTranslation } from "react-i18next";
import { FramerButton } from "@/components/shared";

type Props = {
  amount: number;
  setShowModal: Dispatch<SetStateAction<TransactionsModals>>;
  handleTransactionMutation: (_type: TokenTransactionType) => void;
}

function WithdrawalTransactionSummaryModal(props: Props) {
  // Props
  const {
    amount,
    setShowModal,
    handleTransactionMutation,
  } = props;

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["modals"]);

  return (
    <BlankModal
      setShowModal={()=> setShowModal(TransactionsModals.NoModal)}
      className="px-6"
      customContentCSS="mf-modal-content lg:min-w-[640px]"
      title={t("investmenttransactionsummary.title")}
      titleCSS="text-xl dark:text-white text-center font-bold "
    >
      <BlankModalContent>
        {/* Subtext */}
        <div className="mb-4">
          <p className="dark:text-white text-lg">
            {t("withdrawalsummary.subtext")}
            <span className="pl-1 font-semibold text-mforange">
              {formatNumberLocale(intl, amount, "currency")}
            </span>
          </p>
        </div>

        {/* Disclaimer */}
        <div className="rounded-md bg-mfpurple p-4 mt-4 mb-8 text-sm">
          <p className="text-white font-semibold">
            {t("investmenttransactionsummary.note.title")}:
          </p>
          <p className="text-white">
            {t("withdrawalsummary.disclaimer")}
          </p>
        </div>
      </BlankModalContent>
      <BlankModalFooter>
        {/* Buttons */}
        <div className="flex items-center justify-between">
          <FramerButton
            onClick={() => setShowModal(TransactionsModals.AddTransaction)}
            className="mf-btn-action-small-primary-outline"
          >
            {t("common:buttons.back")}
          </FramerButton>
          <FramerButton
            onClick={() => handleTransactionMutation(TokenTransactionType.Sell)}
            className="mf-btn-action-small-primary-filled"
          >
            {t("common:buttons.submit")}
          </FramerButton>
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default WithdrawalTransactionSummaryModal;
