/* eslint-disable @typescript-eslint/no-unused-vars */
import { clientNumberToBlockchain } from "@/utils/helpers";
import { toast } from "react-toastify";
import { getAFTSmartContract, getEURMSmartContract } from "../connectors";
import { t } from "i18next";
import { findEvent, isConnected } from "../helpers";

// Function for handling Approvals of EURM
export async function approve(from: string, to: string, amount: number, aftAddress?: string): Promise<boolean> {
  if(!isConnected()) {
    toast.error(t("header.wallet.walletnotconnected"), { toastId: "notConnected"});
    return false;
  }
  // Get the ERC20 contract depending on the address
  let contract: any;
  let gasPrice: bigint;
  if(aftAddress) {
    const res = await getAFTSmartContract(aftAddress);
    contract = res.contract;
    gasPrice = res.gasPrice;
  } else {
    const res = await getEURMSmartContract();
    gasPrice = res.gasPrice;
    contract = res.contract;
  }

  if (!contract) {
    toast.error(t("toasts:invalidWallet"));
    return false;
  }

  // Check if approval is already there
  const approved = await contract?.methods.allowance(from, to).call();
  if(Number(approved) >= clientNumberToBlockchain(amount)) {
    return true;
  }

  // Send Approval
  try {
    const tx = await contract.methods.approve(to, clientNumberToBlockchain(amount))
      .send({
        from, gasPrice
      });
    const event = findEvent("Approval", tx);
    // Find the Approval event in the receipt
    if (!event) {
      toast.error(t("toasts:approvalError"), { toastId: "approvalError"});
      return false;
    }
    if(Number(event.value) < clientNumberToBlockchain(amount)) {
      toast.error(t("toasts:approvalTooLittle"), { toastId: "approvalTooLittle"});
      return false;
    }
    toast.success(t("toasts:approvalSuccess"), { toastId: "approvalSuccess"});
    return true;
  } catch (error) {
    toast.dismiss();
    toast.error(t("toasts:unknownError"), { toastId: "unknownError"});
    return false;
  }
}
