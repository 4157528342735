import { formatDaysString, formatNumberLocale } from "@/utils/helpers";
import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals/BlankModal";
import { InvestmentTransactionModals } from "@/constants/enums";
import { FramerButton, ProgressBar } from "@/components/shared";
import { ServiceProviderDetails } from "@/components/shared";
import { TwInput } from "@/components/shared";
import { useTranslation } from "react-i18next";
import { updateInvestmentState } from "@/cache/appstate/WriteQueries";
import { useBlockchainData } from "@/hooks";
import { useIntl } from "react-intl";
import { useState } from "react";
import { useWeb3 } from "@/context/Web3Context";
import { propsMakeInvestmentModal } from "../props";

function MakeInvestmentModal(props: propsMakeInvestmentModal) {

  // Props
  const { assetFinancingPool, state, setShowModal } = props;

  // States
  const [inputErrorText, setInputErrorText] = useState("");
  const [init, setInit] = useState(true);

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["modals"]);
  const blockchainData = useBlockchainData();

  // Magic SDK
  const {
    connectWallet,
    isConnected,
  } = useWeb3();

  /**
   * OnChange handler for investment amount input field
   */
  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    setInit(false);

    const { name, value, isValid } = event;
    let errorText = "";

    const parsedValue = parseFloat(value);
    const isMultipleOf500 = parsedValue % 500 === 0;

    if (!isValid) {
      errorText = t("makeinvestment.input.error.invalid");
    } else if (parsedValue <= 0) {
      errorText = t("makeinvestment.input.error.zero");
    } else if (parsedValue > blockchainData.balance) {
      errorText = t("makeinvestment.input.error.insufficient");
    } else if (!isMultipleOf500) {
      errorText = t("makeinvestment.input.error.invalidstep");
    } else if (parsedValue > assetFinancingPool!.financinglimit - assetFinancingPool!.amountinvested) {
      errorText = t("makeinvestment.input.error.exceedsfinancinglimit");
    }

    setInputErrorText(errorText);

    if ((isValid || value.length === 0) && isMultipleOf500) {
      const finalValue = value.length === 0 ? 0 : parsedValue;
      updateInvestmentState({ [name]: finalValue });
    }
  }

  /**
   * OnClick handle for MAX button
   */
  const handleMaxClick = () => {
    const input = document.getElementById("investmentAmount") as HTMLInputElement;
    if (input) {
      const balance = blockchainData.balance;
      const remainingInvestmentCapacity = assetFinancingPool!.financinglimit - assetFinancingPool!.amountinvested;
      const inputValue = Math.min(Math.floor(balance / 500) * 500, remainingInvestmentCapacity);

      input.value = `${inputValue}`;
      updateInvestmentState({ amount: inputValue });

      // Trigger input validation after updating the value
      handleOnChange({
        name: "amount",
        value: `${inputValue}`,
        isValid: input.checkValidity(),
      });
    }
  };

  return (
    <BlankModal
      setShowModal={() => { setShowModal(InvestmentTransactionModals.NoModal); }}
      className="px-6"
      customContentCSS="mf-modal-content"
      title={t("makeinvestment.title")}
      additionalCloseAction={() => updateInvestmentState({ amount: 0 })}
    >
      <BlankModalContent>
        {/* SP Details */}
        <div>
          <ServiceProviderDetails
            serviceProviderId={assetFinancingPool!.spdetails._id}
          />
        </div>
        {/* Funds locked */}
        <div>
          <p className="text-sm mt-6 dark:text-white">
            {t("makeinvestment.text")}
            <span className="font-semibold">
              {` ${formatDaysString(assetFinancingPool!.dayslocked)}`}
            </span>
          </p>
        </div>

        <div className="my-4 space-y-2">
          {/* Financing Limit */}
          <div className="flex items-center">
            <p className="text-sm font-semibold w-[200px] text-gray-800 dark:text-white">
              {t("makeinvestment.financinglimit")}
            </p>
            <p className="font-semibold text-base text-mfpurple dark:text-mforange">
              {formatNumberLocale(intl, assetFinancingPool!.financinglimit, "currency")}
            </p>
          </div>
          {/* Total Invested */}
          <div className="flex items-center">
            <p className="text-sm font-semibold w-[200px] text-gray-800 dark:text-white">
              {t("makeinvestment.totalinvested")}
            </p>
            <p className="font-semibold text-base text-mfpurple dark:text-mforange">
              {formatNumberLocale(intl, assetFinancingPool!.amountinvested, "currency")}
            </p>
          </div>
        </div>

        {/* Progress bar */}
        <div className="md:mr-[100px]">
          <ProgressBar
            label={t("components:serviceproviderinfo.totalinvested")}
            percentage={Math.round(assetFinancingPool!.amountinvested/assetFinancingPool!.financinglimit*100)}
          />
        </div>

        {
          isConnected ?
            <>
              {/* Available balance */}
              <div className="flex items-center mb-4 mt-8">
                <p className="text-sm font-semibold w-[200px] text-gray-800 dark:text-white">
                  {t("makeinvestment.availablebalance")}:
                </p>
                <p className="text-mforange font-semibold">
                  {formatNumberLocale(intl, blockchainData.balance, "currency")}
                </p>
              </div>
              {/* Input box label */}
              <p className="text-lg font-bold text-gray-800 dark:text-white">
                {t("makeinvestment.input.label")}
              </p>
              {/* Input field and buttons */}
              <div className="flex flex-col sm:flex-row flex-wrap w-full gap-y-4">
                <div className="flex-1 bg-gray-100 dark:bg-mfdarklighter rounded-sm p-2 w-full sm:mr-2">
                  {/* Currency symbol */}
                  <div className="flex items-center">
                    <p className="font-semibold text-4xl text-gray-500 dark:text-white pl-2">
                      €
                    </p>
                    {/* Input box */}
                    <div className="w-full">
                      <TwInput
                        id="investmentAmount"
                        onChange={handleOnChange}
                        type="float"
                        name="amount"
                        placeholder={"0.00"}
                        labelAfter="hidden"
                        labelBefore={state.amount && state.amount > 0 ? "hidden" : "text-4xl font-bold -top-1 dark:text-gray-100"}
                        className="mf-input-field-large"
                      />
                    </div>
                    {/* Max button */}
                    <div>
                      <FramerButton
                        onClick={handleMaxClick}
                        className="text-xs font-bold px-[10px] py-[4px] bg-white dark:bg-mfdarklighter
                          dark:text-white text-gray-800 rounded-sm"
                      >
                        {t("makeinvestment.input.max")}
                      </FramerButton>
                    </div>
                  </div>
                </div>
                {/* Invest button */}
                <div className="">
                  <FramerButton
                    disabled={init || inputErrorText.length > 0}
                    onClick={() => {
                      setShowModal(InvestmentTransactionModals.InvestmentTransactionSummary);
                    }}
                    className={`${init || inputErrorText.length > 0 ? "mf-btn-action-xl-disabled-filled" : "mf-btn-action-xl-primary-filled"} w-full`}
                  >
                    {t("makeinvestment.input.button")}
                  </FramerButton>
                </div>
              </div>
              <p className="h-4 text-xs text-red-600 mt-1">{inputErrorText}</p>
            </>:
            <div className="flex items-center mt-8 space-x-2">
              <p className="text-sm font-semibold dark:text-white">
                {t("common:text.investconnectwallet")}.
              </p>
              <FramerButton
                className="mf-btn-action-small-primary-filled"
                onClick={connectWallet}
              >
                {t("common:text.connectwallet")}
              </FramerButton>
            </div>
        }
      </BlankModalContent>

      <BlankModalFooter>
        {/* Cancel button */}
        <div className="mt-8">
          <FramerButton
            onClick={() => setShowModal(InvestmentTransactionModals.NoModal)}
            className="mf-btn-action-small-primary-outline"
          >
            {t("common:buttons.cancel")}
          </FramerButton>
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default MakeInvestmentModal;
