import { makeInterestPayment, makePayment } from "@/blockchain";
import { handleBlockchainExplorerLink } from "@/blockchain/helpers";
import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { PaymentModals, TxResult } from "@/constants/enums";
import { useCompanyData } from "@/hooks";
import { formatNumberLocale } from "@/utils/helpers";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import { propsPaymentSummaryModal } from "../props";
import { FramerButton } from "@/components/shared";

function PaymentSummaryModal(props : propsPaymentSummaryModal) {
  // Props
  const {
    setShowModal,
    isInterestPayment,
    owedAmount,
    pooladdress,
    setDepositTransactionHash,
    setInterestTransactionHash,
    setTransactionResultType,
    financinglimit
  } = props;

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["modals"]);
  const company = useCompanyData();

  const handlePaymentLogic = async (isOnlyInterestPayment: boolean) => {
    setShowModal(PaymentModals.Loading);
    const [interestPaymentMade, interestTransactionHash] = await makeInterestPayment(
      owedAmount,
      company!.publickey,
      pooladdress!);

    setInterestTransactionHash(interestTransactionHash);

    if (!interestPaymentMade) {
      setTransactionResultType(TxResult.ERROR);
      setShowModal(PaymentModals.PaymentResult);
      return;
    }

    // If it is only interest payment, we do not need the paymentMade
    let paymentMade = isOnlyInterestPayment;
    let depositTransactionHash = "";

    if(!isOnlyInterestPayment) {
      [paymentMade, depositTransactionHash] = await makePayment(
        financinglimit,
        company!.publickey,
        pooladdress!
      );
      setDepositTransactionHash(depositTransactionHash);
    }

    if(interestPaymentMade && paymentMade) {
      setTransactionResultType(TxResult.SUCCESS);
    } else {
      setTransactionResultType(TxResult.ERROR);
    }

    setShowModal(PaymentModals.PaymentResult);
  };

  return (
    <BlankModal
      setShowModal={() => { setShowModal(PaymentModals.NoModal); }}
      className="px-6"
      customContentCSS="mf-modal-content"
      title={t("interestpaymentsummary.title")}
      titleCSS="text-lg dark:text-white"
    >
      <BlankModalContent>
        {/* Subtext */}
        <div className="mb-4">
          <p className="dark:text-white">
            {t("interestpaymentsummary.textbefore")}
            <span className="pl-1 font-semibold">
              {formatNumberLocale(intl, owedAmount, "currency")}
            </span> {t("interestpaymentsummary.textafter")}
          </p>
        </div>

        {/* Contract pool address */}
        <div>
          <p className="font-semibold dark:text-gray-200">{t("translation:interestpayment.labels.contractaddress")}</p>
          <a
            href={handleBlockchainExplorerLink("address", pooladdress as string)}
            target="_blank"
            rel="noreferrer"
            className="text-blue-400 break-all"
          >{pooladdress}</a>
        </div>

        {/* Disclaimer */}
        <div className="rounded-md bg-mfpurple p-4 mt-4 mb-8 text-sm">
          <p className="text-white font-semibold">
            {t("interestpaymentsummary.note.title")}
          </p>
          <p className="text-white">
            {t("interestpaymentsummary.note.text")}
          </p>
        </div>
      </BlankModalContent>

      <BlankModalFooter>
        {/* Buttons */}
        <div className="flex items-center justify-between">
          <FramerButton
            onClick={() => {
              setShowModal(PaymentModals.NoModal);
            }}
            className="mf-btn-action-small-primary-outline"
          >
            {t("common:buttons.close")}
          </FramerButton>
          <FramerButton
            onClick={() => handlePaymentLogic(isInterestPayment)}
            className="mf-btn-action-small-primary-filled"
          >
            {t("common:buttons.confirm")}
          </FramerButton>
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default PaymentSummaryModal;
