import { CLIENT_CREDIT_DATA } from "@/graphql/operations/client.queries";
import { useRef, useState } from "react";
import {
  CustomerEdge,
  CustomerOnServiceProvider,
  GetPaginatedCustomersQuery,
  GetPaginatedCustomersQueryVariables,
  useGetPaginatedCustomersQuery} from "@/graphql/__generated__/graphql-operations";
import { Pagination, PaginationContentScrollable, ScrollToTopButton } from "@/components/shared";
import { formatNumberLocale } from "@/utils/helpers";
import { CustomerDetailsModal, CustomerFilterComponent } from "@/pages/CustomerOverview";
import { CustomerPageModals } from "@/constants/enums";
import { TwTableCardList, DataTable } from "@/components/shared/table";
import { client } from "@/client";
import { useIntl } from "react-intl";
import { creditLimitOf, creditOf } from "@/blockchain/CreditManager";
import { useEmployeeData } from "@/hooks";
import useMdBreakpoint from "@/hooks/useMdBreakpoint";
import { useTranslation } from "react-i18next";
import { getColumns } from "./columns";
import { getColumnHeaderValues } from "@/components/shared/table/utils";
import { ActionButton } from "@/components/shared/table/Table";
import useScrollToTop from "@/hooks/useScrollToTop";

interface PaginationRefCustomers {
  triggerHandleFilterSubmit: (_newVariables: Partial<GetPaginatedCustomersQueryVariables>) => void;
  getData: () => CustomerEdge[];
  getLoading: () => boolean;
}
export interface EnhancedCustomerEdge extends CustomerEdge {
  _id: string;
  credit: number;
  creditLimit: number;
}

function Customers() {
  // Ref use for Scroll To Top
  const scrollRef = useRef<HTMLDivElement>(null);

  // Hooks
  const mdBreakpoint = useMdBreakpoint();
  const user = useEmployeeData();
  const { t } = useTranslation(["tables"]);
  const intl = useIntl();
  const { isVisible, scrollToTop } = useScrollToTop({ ref: scrollRef });

  if(!user) {
    return (
      <p>We are currently loading....</p>
    );
  }
  // Pagination config
  const paginationRefCustomers = useRef<PaginationRefCustomers | null>(null);
  const customerPaginationName = "paginatedCustomers";

  const previousDataSlice = useRef<CustomerEdge[]>([]);

  // States
  const [currentCustomer, setCurrentCustomer] = useState<CustomerOnServiceProvider|EnhancedCustomerEdge>();
  const [showModal, setShowModal]  = useState<CustomerPageModals>(CustomerPageModals.NoModal);
  const [newDataSlice, setNewDataSlice] = useState<EnhancedCustomerEdge[]>([]);
  const [creditLoadeding, setCreditLoadeding] = useState<boolean>(true);

  /**
   * Function to fetch credit and credit limit from blockchain for each customer
   * @param dataSlice data slice from the current query
   */
  const fetchData = async (dataSlice: CustomerEdge[]) => {
    setCreditLoadeding(true);

    try {
      const creditData = await getCreditDataFromBlockchain(dataSlice);

      const newDataSlice: CustomerEdge[] = dataSlice.map((data, index) => ({
        ...data,
        node: {
          ...data.node,
          _id: data.node._id,
          credit: creditData[index]?.credit || 0,
          creditLimit: creditData[index]?.creditLimit || 0
        }
      }));

      setNewDataSlice(newDataSlice as EnhancedCustomerEdge[]);
    } catch (error) {
      console.error("Error fetching credit data", error);
    } finally {
      setCreditLoadeding(false);
    }
  };

  function handleModalOpen(customer?: EnhancedCustomerEdge | CustomerOnServiceProvider) {
    setCurrentCustomer(customer);
    setShowModal(CustomerPageModals.CustomerDetails);
  }

  /**
   * Function to calculate the credit usage of a customer
   * @param customer customer object
   * @returns credit usage percentage
   */
  function handleCreditLimit(customer: EnhancedCustomerEdge) {
    const creditData = client.readQuery<{ company: { credit: number; creditLimit: number } }>({
      query: CLIENT_CREDIT_DATA,
      variables: { id: customer._id },
    });

    // Constants
    const credit = creditData?.company?.credit as number ?? 0;
    const creditLimit = creditData?.company?.creditLimit as number ?? 0;

    if(creditLimit === 0) {
      return formatNumberLocale(intl, 0, "percent");
    }
    else {
      const creditUsage = credit / creditLimit;
      return (creditUsage*100).toFixed(2)+"%";
    }
  }

  const onFilterSubmitCustomers = (variables: GetPaginatedCustomersQueryVariables) => {
    // When the filter is submitted in the PageComponent,
    // this will call the handleFilterSubmit inside Pagination.
    paginationRefCustomers.current?.triggerHandleFilterSubmit(variables);
  };

  /**
   * Handler for fetching credit and credit limit from blockchain for each customer
   * and writing it to cache
   */
  async function getCreditDataFromBlockchain(dataSlice: CustomerEdge[]) {
    if (dataSlice.length > 0) {
      const creditData: { id: string, credit: number, creditLimit: number }[] = [];

      for(const customer of dataSlice) {
        // Check if we have the data cached already
        const customerCreditData = client.readQuery<{ company: { credit: number; creditLimit: number } }>({
          query: CLIENT_CREDIT_DATA,
          variables: { id: customer.node._id },
        });

        if(!customerCreditData) {
          // Fetch data if we don't have it in cache
          const credit = await creditOf(customer.node.publickey);
          const creditLimit = await creditLimitOf(customer.node.publickey);

          // Write data to cache
          client.writeQuery({
            query: CLIENT_CREDIT_DATA,
            variables: { id: customer.node._id },
            data: { company: { credit, creditLimit } },
          });
          creditData.push({ id: customer.node._id, credit, creditLimit });
        }
        else { // Return the already cached data
          const credit = await customerCreditData.company.credit;
          const creditLimit = await customerCreditData.company.creditLimit;
          creditData.push({ id: customer.node._id, credit, creditLimit });
        }
      }

      return creditData;
    } else {
      return [];
    }
  }

  /**
   * Function to check if the data slice has changed
   * @param newDataSlice data slice from the current query
   * @param prevDataSlice data slice from the previous query
   * @returns boolean indicating if the data slice has changed
   */
  const hasDataSliceChanged = (newDataSlice: CustomerEdge[], prevDataSlice: CustomerEdge[]): boolean => {
    // Check if the length of the data slice has changed,
    // if different, return true, else continue with the shallow comparison
    if (newDataSlice.length !== prevDataSlice.length) return true;

    // Shallow comparison is needed when the length of the data slice is the same
    // Edge case: length can be the same but different data inside
    // Only shallow comparison since possibility of other fields changing is low, else will need deep comparison
    // Perform a shallow comparison based on unique keys (_id) and return early on the first mismatch
    return newDataSlice.some((item, index) => item.node._id !== prevDataSlice[index].node._id);
  };

  const getActionButtonsCustomers = (index: number, dataObj: CustomerOnServiceProvider | EnhancedCustomerEdge) => {
    return [
      <ActionButton key={"customer-action-btn-"+index} dataObj={dataObj} fn={handleModalOpen} text={t("common:buttons.details")} />
    ];
  };

  // Table headers and sort fields for TwTableCardList
  const columnHeaderValues = getColumnHeaderValues(getColumns, intl, t, handleModalOpen);

  return (
    <>
      <div className="mf-table-container mf-flex-y-fill">
        {/* Filter component */}
        <CustomerFilterComponent onFilterSubmitCustomers={onFilterSubmitCustomers} />

        <div className="mf-text-primary transition-all flex">
          {/* MODALS */}
          {
            showModal === CustomerPageModals.CustomerDetails &&
            <CustomerDetailsModal
              setShowModal={setShowModal}
              currentCustomer={currentCustomer as CustomerOnServiceProvider}
            />
          }
        </div>

        {/* Customers Table */}
        <div className={"mf-customers-table-2 flex-col flex-grow mt-2 -mb-2 flex w-full"}>
          <Pagination<CustomerEdge, GetPaginatedCustomersQuery, GetPaginatedCustomersQueryVariables>
            ref={paginationRefCustomers} props={{queryHook: useGetPaginatedCustomersQuery, paginationName: customerPaginationName, className: "mx-4 mb-4"}}
          >
            {(dataSlice, loading, displayAmount) => {
              // Check if dataSlice has changed compared to the previousDataSlice
              if (hasDataSliceChanged(dataSlice, previousDataSlice.current)) {
                fetchData(dataSlice);
                previousDataSlice.current = dataSlice; // Store the current data for future comparison
              }

              const loadingCondition = loading || creditLoadeding;

              return (
                <PaginationContentScrollable ref={scrollRef}>
                  <div className="mx-4 mt-4">
                    {
                      mdBreakpoint ?
                        <DataTable
                          loading={loadingCondition}
                          displayAmount={displayAmount as number}
                          data={newDataSlice.map((edge) => edge.node) ?? []}
                          columns={getColumns(intl, t, handleModalOpen, handleCreditLimit)}
                        />
                        :
                        <TwTableCardList
                          dataArray={newDataSlice.map((edge) => edge.node) ?? []}
                          displayAmount={displayAmount as number}
                          actionButtons={getActionButtonsCustomers}
                          defaultSortField="createdAt"
                          tableHeaders={columnHeaderValues}
                          dataType="Customer"
                          dataLoading={loadingCondition}
                        />
                    }
                  </div>
                </PaginationContentScrollable>
              );
            }}
          </Pagination>
          <ScrollToTopButton scrollToTop={scrollToTop} isVisible={isVisible} />
        </div>
      </div>
    </>
  );
}

export default Customers;
