/* eslint-disable indent */
import { formatDateLocale, formatNumberLocale } from "@/utils/helpers";
import { useIntl } from "react-intl";
import { useBlockchainData, useCompanyData, useEmployeeData, useServiceProviderData } from "@/hooks";
import { useTranslation } from "react-i18next";
import { useWeb3 } from "@/context/Web3Context";
import { PaymentModals, SharedModals, TxResult } from "@/constants/enums";
import { useState } from "react";
import { PaymentResultModal, PaymentSummaryModal } from "./modals";
import { handleBlockchainExplorerLink } from "@/blockchain/helpers";
import { LoadingModal } from "../Investment/modals";
import { FramerButton } from "@/components/shared";

function MakePayment() {
  // Magic SDK
  const {
    connectWallet,
    isConnected,
  } = useWeb3();

  // Queries
  const user = useEmployeeData();
  const blockchainData = useBlockchainData();
  const company = useCompanyData();
  const serviceProvider = useServiceProviderData();

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState<PaymentModals | SharedModals>(PaymentModals.NoModal);
  const [transactionResultType, setTransactionResultType] = useState<TxResult>(TxResult.SUCCESS);
  const [interestTransactionHash, setInterestTransactionHash] = useState<string>("");
  const [depositTransactionHash, setDepositTransactionHash] = useState<string>("");

  if(!user && !blockchainData && !company && !serviceProvider) {
    return (
      <p>We are currently loading....</p>
    );
  }

  // Constants
  const {
    balance,
    serviceProvider: {
      lastPaymentDate,
      currentlyOwedInterestAmount: owedInterestAmount,
    },
    inventoryProvider : {
      isEndPaymentDue
    }
  } = blockchainData;

  const { pooladdress, financinglimit } = serviceProvider!.assetfinancingpool;
  const isInterestPayment = !isEndPaymentDue;
  const owedAmount = isInterestPayment ? owedInterestAmount : financinglimit + owedInterestAmount;
  const isInsufficientBalance = balance < owedAmount;

  const handleDepositOnClick = () => {
    setShowModal(PaymentModals.PaymentSummary);
  };

  const sharedModalProps = {
    setShowModal: setShowModal,
    owedAmount,
    pooladdress,
  };

  const paymentSummaryModalProps = {
    ...sharedModalProps,
    isInterestPayment: isInterestPayment,
    setInterestTransactionHash: setInterestTransactionHash,
    setDepositTransactionHash: setDepositTransactionHash,
    setTransactionResultType: setTransactionResultType,
    financinglimit: financinglimit,
  };

  const paymentResultModalProps = {
    ...sharedModalProps,
    interestTransactionHash: interestTransactionHash,
    depositTransactionHash: depositTransactionHash,
    transactionResultType: transactionResultType,
  };

  return (
    // Container
    <div className="flex justify-center">
      {/* Card */}
      <div className="flex flex-col gap-y-4 shadow-lg rounded-lg bg-white dark:bg-mfdarklight max-w-[600px] m-4 mt-8 p-8 dark:text-white">
        {/* Title */}
        <h1 className="text-xl font-semibold">
          {
            isInterestPayment ?
              t("interestpayment.titleInterest"):
              t("interestpayment.titleIFP")
          }
        </h1>

        {
          isInterestPayment ?
            <p>
              {t("interestpayment.descriptionInterest")}
            </p> :
            <p>
              {t("interestpayment.descriptionIFPbefore")}&nbsp;
              <span className="font-semibold text-mfpurple dark:text-mforange">{formatDateLocale(intl, new Date(lastPaymentDate ?? ""))}</span>.<br />
              {t("interestpayment.descriptionIFPafter")}
            </p>
        }

        {/* Contract pool address */}
        <div>
          <p className="font-semibold">{t("interestpayment.labels.contractaddress")}</p>
          <a
            href={handleBlockchainExplorerLink("address", pooladdress as string)}
            target="_blank"
            rel="noreferrer"
            className="text-blue-400 break-all"
          >{pooladdress}</a>
        </div>

        {/* Transaction Details */}
        <p>{t("interestpayment.reminder")}</p>
        <div className="rounded-lg bg-gray-100 p-4 dark:bg-mfdarklighter">
          <div className="flex flex-col sm:flex-row flex-wrap justify-between gap-x-4 font-semibold mb-4">
            <p>{t("interestpayment.labels.amountdue")}</p>
            <p className="text-mforange">
              {formatNumberLocale(intl, owedAmount, "currency")}
            </p>
          </div>
          <div className="flex flex-col sm:flex-row flex-wrap justify-between gap-x-4 font-semibold">
            <p>{t("interestpayment.labels.duedate")}</p>
            {
              lastPaymentDate != 0 &&
                <p className="font-base"> {formatDateLocale(intl, new Date(lastPaymentDate))}</p>
            }
          </div>
        </div>

        {/* Wallet */}
        {
          isConnected ?
            <div className="font-semibold">
              {t("interestpayment.labels.balance")}
                <span className="text-mfpurple dark:text-mforange ml-1">
                  {formatNumberLocale(intl, balance, "currency")}
                </span>
            </div>
            :
            <div className="flex items-center space-x-2 justify-between">
              <p className="text-sm font-semibold dark:text-white">
                {t("interestpayment.connectwallet.text")}
              </p>
              <FramerButton
                className="mf-btn-action-small-primary-filled"
                onClick={connectWallet}
              >
                {t("interestpayment.connectwallet.button")}
              </FramerButton>
            </div>
        }

        {/* Insufficient Funds */}
        {
          isInsufficientBalance && isConnected &&
            <div className="text-sm font-semibold text-red-600">
              {t("interestpayment.insufficientbalance")}
            </div>
        }

        {/* Submit Button */}
        <FramerButton
          className={`text-center ${isInsufficientBalance || !isConnected ?
            "mf-btn-action-xl-disabled-filled" : "mf-btn-action-xl-primary-filled"}`}
          disabled={isInsufficientBalance || !isConnected}
          onClick={handleDepositOnClick}
        >
          {t("interestpayment.buttons.proceedtopayment")}
        </FramerButton>

        {/* Support */}
        <div>
          {t("interestpayment.contact")}&nbsp;
          <a href="mailto:contact@myron.finance" className="text-blue-400">
            contact@myron.finance
          </a>
        </div>
      </div>

      {/* MODALS */}
      {// Payment Summary Modal
        showModal === PaymentModals.PaymentSummary &&
          <PaymentSummaryModal
            {...paymentSummaryModalProps}
          />
      }
      {// Payment Result Modal
        showModal === PaymentModals.PaymentResult &&
          <PaymentResultModal
            {...paymentResultModalProps}
          />
      }
      {// Loading Modal
        showModal === PaymentModals.Loading &&
          <LoadingModal
            setShowModal={setShowModal}
            loadingText={t("toasts:sendingPayment")}
          />
      }
    </div>
  );
}

export default MakePayment;
