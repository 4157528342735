import { z } from "zod";
import { CompanyDetailsSchema } from "../Settings";
import { AssetDocumentaryDefaultValues, AssetDocumentarySchema } from "./AssetDocumentary";
import { AssetFinancingPoolDefaultValues, AssetFinancingPoolSchema } from "./AssetFinancingPool";
import { InvoiceDocumentaryDefaultValues, InvoiceDocumentarySchema } from "./InvoiceDocumentary";

export const ServiceProviderInputSchema = z.object({
  assetdocumentary: AssetDocumentarySchema,
  assetfinancingpool: AssetFinancingPoolSchema,
  companydetails: CompanyDetailsSchema,
  invoicedocumentary: InvoiceDocumentarySchema,
});

export const ServiceProviderInputDefaultValues = {
  assetdocumentary: AssetDocumentaryDefaultValues,
  assetfinancingpool: AssetFinancingPoolDefaultValues,
  invoicedocumentary: InvoiceDocumentaryDefaultValues,
  companydetails: undefined,
};

// Type inference
export type TServiceProviderInputSchema = z.infer<typeof ServiceProviderInputSchema>;
