import { FramerButton, TwGlobalFilter } from "@/components/shared";
import { toast } from "react-toastify";
import { ChangeEvent, useEffect } from "react";
import { updatePaginationState } from "@/cache/appstate/WriteQueries";
import { usePaginationState } from "@/hooks/cache/appState/usePaginationState";
import { PaginationState } from "@/types";
import { useSearchParams } from "react-router-dom";
import { propsCustomersFilter } from "./props";
import { useTranslation } from "react-i18next";
import { ArrowPathIcon, CheckIcon } from "@heroicons/react/24/outline";

/**
 * CustomerFilterComponent
 *
 * This component provides filtering functionality for customers by text.
 *
 * @param {propsCustomersFilter} props - The props for the component.
 * @param {(_variables: GetPaginatedCustomersQueryVariables) => void} props.onFilterSubmitCustomers - The function to call when the filter is submitted.
 * @returns {JSX.Element} The CustomerFilterComponent component.
 *
 * @component
 * @example
 * ```tsx
 * return (
 *   <CustomerFilterComponent onFilterSubmitCustomers={handleFilterSubmitCustomers} />
 * )
 * ```
 *
 */
function CustomerFilterComponent(props: propsCustomersFilter): JSX.Element {

  // URL params
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const {
    onFilterSubmitCustomers
  } = props;

  // Hooks
  const { t } = useTranslation();
  const paginationState: PaginationState["paginatedCustomers"] = usePaginationState("paginatedCustomers");
  const { filters: { filter }, first } = paginationState;

  /**
   * OnChange Handler for Filter input field.
   * @param {ChangeEvent<HTMLInputElement>} event - The change event.
   */
  function handleFilterOnChange(event: ChangeEvent<HTMLInputElement>) {
    updatePaginationState({paginatedCustomers: {filters: {filter: event.target.value}}});
  }

  /**
   * OnClick Handler for Submit button.
   */
  const handleSubmitClick = () => {
    onFilterSubmitCustomers({ first, filter });
    toast.success(t("common:text.filterapplied"), { toastId: "userFilterApplied" });
  };

  /**
   * OnClick Handler for Reset button.
   */
  const handleResetClick = () => {
    onFilterSubmitCustomers({
      first,
      filter: null
    });
    toast.success(t("common:text.filterremoved"), { toastId: "userFilterRemoved" });
  };

  /**
   * Checks for customer id in url and sets the filter string
   */
  useEffect(() => {
    if (id) updatePaginationState({paginatedCustomers: {filters: {filter: id}}});
  }, [location.search]);

  return (
    <div>
      {
        <div className="mx-4 mt-4 flex items-center gap-2">
          <TwGlobalFilter
            value={filter ?? ""}
            placeholder={t("customeroverview.filter").toString()}
            onChange={handleFilterOnChange}
            className="mf-global-filter flex-1"
          />
          {/* Right container --> Action buttons */}
          <div className="justify-end flex items-center gap-2">
            {/* Reset button */}
            <FramerButton
              onClick={handleResetClick}
              className="mf-btn-action-small-primary-outline min-w-fit md:min-w-[100px] px-2"
            >
              <div><ArrowPathIcon className="w-5 h-5 md:hidden" /></div>
              <div className="hidden md:block">
                {t("common:buttons.reset")}
              </div>
            </FramerButton>

            {/* Submit button */}
            <FramerButton
              onClick={handleSubmitClick}
              className="mf-btn-action-small-primary-filled min-w-fit md:min-w-[100px] px-2"
            >
              <div><CheckIcon className="w-5 h-5 md:hidden" /></div>
              <div className="hidden md:block">
                {t("common:buttons.submit")}
              </div>
            </FramerButton>
          </div>
        </div>
      }
    </div>
  );
}

export default CustomerFilterComponent;
