import { ChevronDoubleDownIcon, ChevronDoubleUpIcon } from "@heroicons/react/24/outline";
import { Dispatch, SetStateAction } from "react";
import { motion } from "framer-motion";

type Props = {
  showContent: boolean;
  setShowContent: Dispatch<SetStateAction<boolean>>
  title: string;
}

/**
 * A toggle bar to show or hide content
 * @param {Props} props
 * @returns {JSX.Element}
 */
function VisibilityBarToggle(props: Props): JSX.Element {
  return (
    <motion.div
      whileHover={{ opacity: 0.7 }}
      whileTap={{ scale: 0.99 }}
      transition={{ duration: 0.1 }}
      data-cy="visibilityBarToggle"
      onClick={() => props.setShowContent((prev) => !prev)}
      className={`
          mf-admin-visibility-toggle
          ${props.showContent? "bg-gray-200 dark:bg-mfdarklighter":"bg-white dark:bg-mfdarklight"}
        `}
    >
      {/* Text Container */}
      <div className="font-light mf-text-primary">
        <p>
          {props.title}
        </p>
      </div>

      {/* Icons Container */}
      <div>
        {
          props.showContent ?
            <ChevronDoubleUpIcon data-cy="chevronDoubleUpIcon" className="w-6 mf-text-secondary" />:
            <ChevronDoubleDownIcon data-cy="chevronDoubleDownIcon" className="w-6 mf-text-secondary" />
        }
      </div>
    </motion.div>
  );
}

export default VisibilityBarToggle;
