import { CompanyType } from "@/graphql/__generated__/graphql-operations";
import { AddressSchema, BankDetailsSchema, GeneralInformationSchema } from "..";
import { z } from "zod";
import { ServiceProviderInputSchema } from "./ServiceProviderInput";

// Company schema
export const CreateCompanySchema = z.object({
  address: AddressSchema,
  bankaccount: BankDetailsSchema,
  ...GeneralInformationSchema.shape,
  serviceproviderinput: ServiceProviderInputSchema.optional(),
}).refine(
  (data) => {
    if(data.companytype === CompanyType.Serviceprovider && data.serviceproviderinput === undefined){
      return false;
    }
    return true;
  },
  {
    message:
      "Service Provider input is required for Service Providers",
    path: ["companytype"],
  }
);

// Default values for CreateCompany
export const CreateCompanyDefaultValues = {
  address: { street: "", housenumber: "", zipcode: "", city: "", country: "" },
  bankaccount: { vatnumber: "", iban: "", bic: "", verified: false },
  companytype: CompanyType.Financier, // Default to Financier
  name: "",
  phone: "",
  serviceproviderinput: undefined,
  vatnumber: "",
};

// Types
export type TCreateCompanySchema = z.infer<typeof CreateCompanySchema>;
export type TServiceProviderInputSchema = z.infer<typeof ServiceProviderInputSchema>;
