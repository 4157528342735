/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from "chart.js";
import { AssetFinancingRound, AssetType } from "@/graphql/__generated__/graphql-operations";
import { Bar } from "react-chartjs-2";
import { optionsDark } from "@/pages/Dashboard/ServiceProvider/Graph/graphoptionsDark";
import { optionsLight } from "@/pages/Dashboard/ServiceProvider/Graph/graphoptionsLight";
import { useNavigate } from "react-router-dom";
import { formatDateLocale, formatNumberLocale } from "@/utils/helpers";
import { useIntl } from "react-intl";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useTheme } from "@/context/ThemeProvider";
import { merge } from "lodash";

// Graph options
ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
  PointElement,
  LineElement,
  LineController
);

type Props = {
  className?: string;
  data: AssetFinancingRound[];
  assetType: AssetType | null | undefined;
}

function FinancialStatsGraph(props: Props) {
  // Hooks
  const intl = useIntl();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { darkMode: isDarkMode } = useTheme();

  // Assign empty options object
  // Assigned values depending on current theme
  let options;

  const additionalOptions = {
    onClick: handleClick,
    locale: intl.locale,
    layout: {
      padding: 10,
    },
    plugins: {
      title: {
        display: true,
        text: t("dashboard.graph.title"),
        color: isDarkMode ? "#fff" : "#212121",
        font: {
          size: 18,
          weight: "500"
        },
        padding: {
          bottom: 24,
        },
        align: "start",
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: isDarkMode ? "#fff": "#212121",
          fontColor: isDarkMode ? "#212121": "#fff",
          font: {
            size: 14,
            weight: "normal",
          },
          boxWidth: 16,
          boxHeight: 16,
          useBorderRadius: true,
        },
        align: "center",
      },
      tooltip: {
        backgroundColor: isDarkMode ? "#2b2b2b" : "#fafafa",
        titleColor: isDarkMode ? "#fff" : "#212121",
        bodyColor: isDarkMode ? "#fff" : "#212121",
        padding: 16,
        borderColor: isDarkMode ? "#2b2b2b" : "#e6e6e6",
        borderWidth: 0.5,
        xAlign: "center",
        yAlign: "bottom",
        callbacks: { // render the tooltip content
          label: function (context: any) {
            return (context.dataset.label + ":") || "";
          },
          afterLabel: function (context: any) {
            return formatNumberLocale(intl, context.parsed.y, "currency");
          }
        }
      }
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback: function(value: string | number) {
            return formatNumberLocale(intl, value, "currency");
          },
          color: "#a6a6a6"
        },
        border: {
          display: false
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: "#a6a6a6"
        },
        border: {
          display: false
        }
      },
    }
  };

  const labels: string[] = [];
  const dataInventoryValue: number[] = []; // AF = Amount Financed
  const dataNotFinanced: number[] = []; // ANF = Amount Not Financed
  const financingRounds = props.data;

  // Initial filtering
  // Store date labels
  // Populate 'Bar' datasets
  financingRounds.map((round) => {
    // Date labels
    labels.push(formatDateLocale(intl, round.createdAt, { day: "numeric", month: "short", year: "numeric" }));

    dataInventoryValue.push(round.amountFinanced);
    // If assetType is RECEIVABLE, populate dataNotFinanced
    if (props.assetType === "RECEIVABLE") {
      dataNotFinanced.push(round.amountNotFinanced);
    }
  });

  const data: any = {
    labels: labels,
    datasets: [
      // Additional dataset for "not financed" when assetType is RECEIVABLE
      props.assetType === "RECEIVABLE" && {
        label: t("dashboard.graph.notfinanced"),
        data: dataNotFinanced,
        borderColor: "transparent", // Set the border color to transparent
        backgroundColor: "#EE5300",
        borderWidth: 0, // Set the border width to 0 to remove the borders
        barPercentage: 0.9, // Adjust the bar thickness as needed (value between 0 and 1)
        maxBarThickness: 30,
        borderRadius: 2, // Set the border radius to round the corners of the bars
      },
      {
        label: props.assetType === "RECEIVABLE" ? t("dashboard.graph.financed") : t("dashboard.graph.inventoryvalue"),
        data: dataInventoryValue,
        borderColor: "transparent", // Set the border color to transparent
        backgroundColor: "#320085",
        borderWidth: 0, // Set the border width to 0 to remove the borders
        barPercentage: 0.9, // Adjust the bar thickness as needed (value between 0 and 1)
        maxBarThickness: 30,
        borderRadius: 2, // Set the border radius to round the corners of the bars
      },
    ].filter(Boolean), // Remove falsy values (for when assetType is not RECEIVABLE)
  };

  /**
   * onClick Handler for the graph.
   * Extracts the date when clicking on a bar in the graph.
   * Navigates to the 'Assets Overview' page when the date filter is applied
   * using the date extracted from the graph.
   * @param event
   * @param activeElements
   */
  function handleClick (event: MouseEvent, activeElements: any[]) {
    if (activeElements.length > 0) {
      const index = activeElements[0].index;
      const date = format(new Date(labels[index]), "yyyy-MM-dd");
      navigate(`/user/assetoverview?date=${date}`);
    }
  }
  if (isDarkMode) {
    options = merge({}, optionsDark, additionalOptions);
  } else {
    options = merge({}, optionsLight, additionalOptions);
  }

  return (
    <div className="dark:bg-mfdarklight">
      {/* Placeholder div "hack" to make graph responsive */}
      <div className="flex h-1"  />
      <Bar
        height={130}
        options={options}
        data={data}
      />
    </div>
  );
}

export default FinancialStatsGraph;
