
import { useState, ChangeEvent, useEffect } from "react";
import { toast } from "react-toastify";

type State = {
  name: string;
  isValid?: boolean;
  value?: string | undefined;
}

type Props = {
  id?: string;
  type?: string;
  label?: string;
  className?: string;
  errorMessage?: string;
  name?: string | undefined;
  value?: string | number | undefined;
  isValid?: boolean | undefined;
  required?: boolean | undefined;
  placeholder?: string | undefined;
  disabled?: boolean;
  defaultValue?: string | number | undefined;
  autoComplete?: string;
  labelAfter?: string;
  labelBefore?: string;
  labelPlacement?: string;
  useErrorText?: boolean;
  onChange: (_event?: State) => void;
  maxLength?: number;
}


/**
 * Reusable text area component that can be styled with Tailwind CSS.
 * Includes input validation and error handling.
 *
 * @param {Props} props - The properties for the text area component.
 * @returns {JSX.Element} The rendered text area component.
 *
 * @example
 * ```tsx
 * import TwTextArea from "./TwTextArea";
 *
 * function MyComponent() {
 *   const handleChange = (event: State) => {
 *     console.log(event);
 *   };
 *
 *   return (
 *     <TwTextArea
 *       label="Description"
 *       placeholder="Enter your description here"
 *       required
 *       onChange={handleChange}
 *       errorMessage="This field is required."
 *     />
 *   );
 * }
 * ```
 */
function TwTextArea(props: Props): JSX.Element {

  const [value, setValue] = useState<State>({
    name: props.name || "",
    value: "",
    isValid: true,
  });

  const [active, setActive] = useState(false);
  const [focus, setFocus] = useState(false);
  const [count, setCount] = useState(0);

  function handleValidation(value: string): boolean {
    if(!props.required && value === "") return true;
    if(props.required && value === "") return false;

    const regex = /^[\s\S]+$/;

    return regex.test(value);
  }
  /**
  * Multi event handler for the input field
  */
  function handleInputOnActive() {
    setActive(true);
    setFocus(true);
  }

  /**
   * OnBlur handler for the input field
   */
  function handleOnBlur() {
    if(count > 0) setActive(true);
    setActive(false);
    setFocus(false);
    if(!value.isValid && !props.useErrorText) {
      toast.error(`${props.errorMessage}`, { toastId: `${props.name}Toast` });
    }
  }

  function handleOnChange(event: ChangeEvent<HTMLTextAreaElement>): void {
    const { name } = event.target;
    const value = event.target.value;

    const updatedState = {
      name,
      value,
      isValid: handleValidation(value),
    };
    setFocus(true);
    setCount(value.length);
    setValue(updatedState);
    props.onChange(updatedState);
  }

  useEffect(() => {
    if(props.defaultValue) {
      setFocus(true);setActive(true);setCount(1);
    }
  }, [handleOnChange]);

  return (
    <>
      <div data-cy="twtextarea" className="relative z-10">
        {
          props.placeholder && props.labelPlacement !== "stacked" &&
          <label data-cy="twtextarea-label" className={`mf-input-label ${active ? `-transform-y-4 mf-input-label-after ${props.labelAfter}` : `mf-input-label-before ${props.labelBefore}`}`}>
            {props.placeholder}
          </label>
        }
        {
          props.labelPlacement === "stacked" &&
          <label data-cy="twtextarea-label-stacked" className="">
            {props.label}
          </label>
        }
        <textarea
          data-cy="twtextarea-textarea"
          id={props.id}
          name={props.name}
          onClick={handleInputOnActive}
          onChange={(event)=>handleOnChange(event)}
          required={props.required}
          onBlur={handleOnBlur}
          onFocus={handleInputOnActive}
          onKeyDownCapture={handleInputOnActive}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
          maxLength={props.maxLength}
          autoComplete={props.autoComplete}
          placeholder={props.labelPlacement === "stacked" ? props.placeholder : ""}
          className={`z-1 dark:focus:border-mforange ${props.className} ${!value.isValid && focus === false ? "border-red-500" : "border-gray-200 dark:border-gray-500"} ${props.useErrorText ? "mb-[0.25rem]" : ""}`}
        />
        {
          <p data-cy="twtextarea-error" className={`
            ${props.useErrorText && !value.isValid && focus === false ? "scale-100": "scale-0"}
            transition-transform duration-200 text-red-500 text-xs h-auto mb-2 origin-top-left
        `}
          >
            {!value.isValid && focus === false ? props.errorMessage : ""}
          </p>
        }
      </div>
    </>
  );
}

export default TwTextArea;
