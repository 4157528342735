import { FormField } from "@/types";
import { CheckIcon, EyeIcon, EyeSlashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { UseFormRegister, UseFormStateReturn, FieldValues, Path, UseFormWatch } from "react-hook-form";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { getPasswordStrength } from "@/utils/form";

type Props<T extends FieldValues> = {
  field: FormField;
  errors: UseFormStateReturn<T>["errors"];
  register: UseFormRegister<T>;
  watch?: UseFormWatch<T>;
  usePasswordRequirements?: boolean;
};

/**
 * FormInput component for rendering an input field in a form.
 * Supports text, password, and other input types.
 *
 * @template T - The schema type, such as TLoginSchema or TCreateUserSchema.
 * @param {Props<T>} props - The props object.
 * @param {InputGroup} props.field - The input field configuration.
 * @param {UseFormStateReturn<T>["errors"]} props.errors - The form errors.
 * @param {UseFormRegister<T>} props.register - The register function from react-hook-form.
 *
 * @returns {JSX.Element} The rendered form input component.
 *
 * @example
 * ```tsx
 * import { useForm } from "react-hook-form";
 * import { TLoginSchema, LoginSchema } from "@/schemas/LoginSchema";
 * import FormInput from "@/components/FormInput";
 *
 * const LoginForm = () => {
 *   const { register, handleSubmit, formState: { errors } } = useForm<TLoginSchema>({
 *     resolver: zodResolver(LoginSchema),
 *   });
 *
 *   return (
 *     <form onSubmit={handleSubmit((data) => console.log(data))}>
 *       <FormInput
 *         field={{ name: "mail", title: "Email", type: "email", required: true }}
 *         errors={errors}
 *         register={register}
 *       />
 *       <FormInput
 *         field={{ name: "password", title: "Password", type: "password", required: true }}
 *         errors={errors}
 *         register={register}
 *       />
 *     </form>
 *   );
 * };
 * ```
 */
const FormInput = <T extends FieldValues>(props: Props<T>): JSX.Element => {

  // Props
  const { field, errors, register, watch, usePasswordRequirements } = props;

  // Hooks
  const { t } =  useTranslation();

  // States
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // Get the current value of the input field using watch
  const currentValue = watch ? watch(field.name as Path<T>) : "";

  // Calculate password strength
  const passwordStrength = field.type === "password" && usePasswordRequirements
    ? getPasswordStrength(currentValue)
    :  { id: 0, strength: "0", requiredLength: false, hasNumberOrSpecial: false, hasUppercase: false };

  /**
   * Toggles the visibility of the password input field.
   */
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  // Use lodash's get to retrieve nested errors safely
  const fieldError = _.get(errors, field.name as Path<T>);

  return (
    <div key={`input-field-${field.name}`} className="mb-2">
      {/* Input field label */}
      <label htmlFor={field.name} className={`select-none dark:text-gray-200 ${fieldError && "text-red-500"}`}>
        {field.title}
      </label>

      {/* Wrapper for input field and password icon */}
      <div className="relative z-10">
        {/* Input field */}
        <input
          className={`${field.className} ${fieldError && "border-red-500"}
                    text-gray-800 z-1 rounded-sm focus:border-mfpurple dark:focus:border-mforange h-[40px]`}
          placeholder={field.placeholder}
          autoFocus={field.autoFocus}
          data-cy={`${field.name}-input`}
          defaultValue={field.defaultValue}
          value={watch && watch(field.name as Path<T>)}
          disabled={field.disabled}
          min={field.min}
          type={
            field.type === "password"
              ? showPassword ? "text" : "password"
              : field.type
          }
          autoComplete={field.autoComplete}
          {...register(field.name as Path<T>, { required: field.required })}
        />

        {/* Eye icon for password */}
        {field.type === "password" && (
          <div
            data-cy="twinput-view-password"
            onClick={togglePasswordVisibility}
            className="absolute w-6 h-6 right-3 text-gray-600 cursor-pointer dark:text-gray-200 top-[30%]"
          >
            {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
          </div>
        )}
      </div>

      {/* Error message */}
      {fieldError && !usePasswordRequirements && (
        <p className="input-error-message">
          {fieldError?.message as string}
        </p>
      )}

      {/* Password Requirements */}
      {field.type === "password" && usePasswordRequirements && (
        <div data-cy="twinput-password-req" className="text-sm my-2 select-none">
          <p className="font-semibold mb-1">
            <span className="mr-2">{t("components:input.password.title")}</span>
            <span
              data-cy="twinput-password-req-label"
              className={`text-${
                passwordStrength.id === 0
                  ? "red"
                  : passwordStrength.id === 1
                    ? "orange"
                    : passwordStrength.id === 2
                      ? "yellow"
                      : "green"}-500`}
            >
              {passwordStrength.id === 0
                ? t("components:input.password.enterpassword")
                : t(`components:input.password.strength${passwordStrength.id}`)}
            </span>
          </p>
          <PasswordRequirement requirementMet={passwordStrength.requiredLength}>
            {t("components:input.password.lengthcheck")}
          </PasswordRequirement>
          <PasswordRequirement requirementMet={passwordStrength.hasNumberOrSpecial}>
            {t("components:input.password.numberandspecialcheck")}
          </PasswordRequirement>
          <PasswordRequirement requirementMet={passwordStrength.hasUppercase}>
            {t("components:input.password.uppercasecheck")}
          </PasswordRequirement>
        </div>
      )}
    </div>
  );
};

/**
 * Renders a password requirement component.
 *
 * @param requirementMet - A boolean indicating whether the password requirement is met.
 * @param children - The content to be rendered within the component.
 * @returns The rendered password requirement component.
 */
export const PasswordRequirement = ({ requirementMet, children }: { requirementMet: boolean, children: React.ReactNode }) => (
  <div className={`flex items-center ${requirementMet ? "text-green-500" : ""}`}>
    <div className="w-4 h-4 inline-block mr-2">
      {requirementMet ? <CheckIcon /> : <XMarkIcon />}
    </div>
    {children}
  </div>
);

export default FormInput;
