import { SetPasswordResult } from "@/constants/enums";
import { useNavigate } from "react-router-dom";
import { FramerButton } from "@/components/shared";

type Props = {
  result: SetPasswordResult;
}

type MessageProps = {
  title: string;
  color: string;
  children: React.ReactNode;
}

const Message = ({ title, color, children }: MessageProps) => (
  <div className="mb-4">
    <p className={`text-xl md:text-2xl font-semibold mb-1 ${color}`}>
      {title}
    </p>
    <p className="text-sm md:text-base">
      {children}
    </p>
  </div>
);

const Support = () => (
  <div className="mt-12">
    Should you have any further questions or require further assistance, please do not hesitate to contact our customer support team at&nbsp;
    <a href="mailto:contact@myron.finance" className="text-blue-400">
      contact@myron.finance
    </a>
  </div>
);

function ResultComponent({result}: Props) {
  const navigate = useNavigate();

  const messages = {
    [SetPasswordResult.Successful]: {
      title: "Password Successfully Changed",
      color: "text-green-500",
      message: "Your password has been successfully updated. You can now use your new password to log in."
    },
    [SetPasswordResult.Failed]: {
      title: "Failed to Change Password",
      color: "text-red-500",
      message: "We're sorry, but there was an error while setting your password. Please try again later or contact our support team for assistance."
    },
    [SetPasswordResult.Invalid]: {
      title: "Link Expired or Invalid",
      color: "text-red-500",
      message: "We apologise, but the link used to reset your password has expired or is invalid. Please try again, if this issue keeps occurring please contact our support team for assistance in resolving this issue."
    }
  };

  return (
    <>
      <div className="mb-[50px] md:mb-0 mt-4 md:mt-8">
        <Message title={messages[result].title} color={messages[result].color}>
          {messages[result].message}
        </Message>
        <FramerButton
          className="rounded-sm mf-primary text-white mf-button w-full"
          onClick={() => { navigate("/login"); }}
        >
          Back to Login
        </FramerButton>
        <Support />
        {
          result === SetPasswordResult.Invalid &&
          <p className="mt-4 text-sm md:text-base">
            <span onClick={() => {navigate("/login");}} className="text-blue-400 cursor-pointer">Back to Login</span>
          </p>
        }
      </div>
    </>
  );
}

export default ResultComponent;
