/* eslint-disable @typescript-eslint/no-unused-vars */
import { getEURMSmartContract } from "@/blockchain/connectors";
import { blockchainNumberToClient } from "@/utils/helpers";
import { t } from "i18next";
import { toast } from "react-toastify";
import { findEvent, isConnected } from "../helpers";

export async function addAuthorizedUser(address: string): Promise<any> {
  // const t = useCustomTranslation(["toasts", "components"]);
  if(!isConnected()) {
    toast.error(t("header.wallet.walletnotconnected"), { toastId: "notConnected"});
    return undefined;
  }

  const { contract, gasPrice } = await getEURMSmartContract();

  try {
    toast.loading(t("toasts:sendingAddAuthorizedUser"), { toastId: "sendingAddAuthorizedUser"});
    const tx = await contract.methods.addAuthorizedAddress(address)
      .send({
        from: sessionStorage.getItem("wallet"),
        gasPrice
      });
    toast.dismiss("sendingAddAuthorizedUser");
    // Find the "AddAuthorizedUser" event in the receipt
    const event = findEvent("AddAuthorizedUser", tx);
    if (!event) {
      toast.error(t("toasts:addAuthorizedUserError"), { toastId: "addAuthorizedUserError", autoClose: false});
      return false;
    }
    toast.success(t("toasts:addAuthorizedUserSuccess"), { toastId: "addAuthorizedUserSuccess", autoClose: false});
    return true;
  } catch(error) {
    toast.dismiss();
    toast.error(t("toasts:unknownError"), { toastId: "unknownError", autoClose: false});
    return false;
  }
}

export async function removeAuthorizedUser(address: string): Promise<boolean|undefined> {
  if(!isConnected()) {
    toast.error(t("header.wallet.walletnotconnected"), { toastId: "notConnected"});
    return undefined;
  }
  const { contract, gasPrice } = await getEURMSmartContract();

  try {
    toast.loading(t("toasts:sendingRemoveAuthorizedUser"), { toastId: "sendingRemoveAuthorizedUser"});
    const tx = await contract.methods.removeAuthorizedAddress(address)
      .send({
        from: sessionStorage.getItem("wallet"),
        gasPrice
      });

    toast.dismiss("sendingRemoveAuthorizedUser");
    // Find the "RemoveAuthorizedUser" event in the receipt
    const event = findEvent("RemoveAuthorizedUser", tx);
    if (!event) {
      toast.error(t("toasts:removeAuthorizedUserError"), { toastId: "removeAuthorizedUserError", autoClose: false});
      return false;
    }
    toast.success(t("toasts:removeAuthorizedUserSuccess"), { toastId: "removeAuthorizedUserSuccess", autoClose: false});
    return true;
  } catch(error) {
    toast.dismiss();
    toast.error(t("toasts:unknownError"), { toastId: "unknownError", autoClose: false});
    return false;
  }
}

export async function balanceOf(address: string) {
  const { contract } = await getEURMSmartContract();
  const balance = await contract.methods.balanceOf(address).call();
  return blockchainNumberToClient(balance);
}

export async function authorizationStatusOf(address: string) {
  const { contract } = await getEURMSmartContract();
  const authStatusOf =  await contract.methods.authorizationStatusOf(address).call();
  return authStatusOf;
}

export async function totalSupply() {
  const { contract } = await getEURMSmartContract();
  const totalSupply = await contract.methods.totalSupply().call();
  return blockchainNumberToClient(totalSupply);
}
