import { useCreateCompanyMutation } from "@/graphql/__generated__/graphql-operations";
import { FormField } from "@/types";
import { UseFormReset } from "react-hook-form";
import { toast } from "react-toastify";


/**
 * Custom hook for creating a company.
 *
 * @param {UseFormReset<FormField>} reset - Function to reset the form fields.
 * @returns An object containing the createCompanyMutation function, loading state, and error state.
 */
export default function useCreateCompanyMutationHook(reset: UseFormReset<FormField>) {
  const [createCompanyMutation, { loading, error }] = useCreateCompanyMutation({
    onCompleted(data) {
      if(data.createCompany) {
        toast.success("Successfully created company", {toastId: "createCompanySuccess"});
        // Reset form after successful submission
        reset();
      }
    },
    onError(error) {
      toast.error(error.toString(), {toastId: "createCompanyError"});
    }
  });

  return {
    createCompanyMutation,
    loading,
    error
  };
}
