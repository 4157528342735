import { z } from "zod";
import { AssetType } from "@/graphql/__generated__/graphql-operations";
import { nativeEnumSchema, positiveInteger } from "@/utils/form";

export const AssetFinancingPoolSchema = z.object({
  assetType: nativeEnumSchema(AssetType, "Asset type"),
  contractCID: z.string().optional(),
  uniqueitems: z.preprocess(
    (value) => {
      if (typeof value === "string") {
        return value === "true";
      }
      return value;
    },
    z.boolean().optional() // The actual validation after preprocessing
  ),
  ceiling: positiveInteger("Ceiling").optional(),
  floor: positiveInteger("Floor").optional(),
  financinglimit: positiveInteger("Financing limit"),
  interest: positiveInteger("Interest"),
  rewardpayoutperiod: positiveInteger("Reward payout period"),
  dayslocked: positiveInteger("Days locked"),
}).refine(
  (data) => {
    if(data.assetType === AssetType.Inventory && data.ceiling === undefined) {
      return false;
    }
    return true; // Allow validation to pass for Receivable
  },
  {
    message: "Ceiling is required when asset type is Inventory",
    path: ["ceiling"],
  }
).refine(
  (data) => {
    if(data.assetType === AssetType.Inventory && data.floor === undefined) {
      return false;
    }
    return true; // Allow validation to pass for Receivable
  },
  {
    message: "Floor is required when asset type is Inventory",
    path: ["floor"],
  }
).refine(
  (data) => { // Ceiling must be more than floor when both are defined
    if (data.assetType === AssetType.Inventory && data.floor !== undefined && data.ceiling !== undefined) {
      return data.ceiling > data.floor;
    }
    return true; // Allow validation to pass for Receivable
  },
  {
    message: "Ceiling must be higher than floor",
    path: ["ceiling"],
  }
).refine(
  (data) => { // Floor must be less than ceiling when both are defined
    if (data.assetType === AssetType.Inventory && data.floor !== undefined && data.ceiling !== undefined) {
      return data.floor < data.ceiling;
    }
    return true; // Allow validation to pass for Receivable
  },
  {
    message: "Floor must be lower than ceiling",
    path: ["floor"],
  }
).refine(
  (data) => {
    if(data.assetType === AssetType.Inventory && data.uniqueitems === undefined) {
      return false;
    }
    return true; // Allow validation to pass for Receivable
  },
  {
    message: "Unique items is required when asset type is Inventory",
    path: ["uniqueitems"],
  }
).refine(
  (data) => data.dayslocked % data.rewardpayoutperiod === 0,
  {
    message: "Reward payout period must be a divisor of Days locked",
    path: ["rewardpayoutperiod"],
  }
).refine(
  (data) => data.dayslocked % data.rewardpayoutperiod === 0,
  {
    message: "Days locked must be a multiple of Reward payout period",
    path: ["dayslocked"],
  }
);

export const AssetFinancingPoolDefaultValues = {
  assetType: undefined,
  uniqueitems: undefined,
  ceiling: undefined,
  floor: undefined,
  financinglimit: undefined,
  interest: undefined,
  rewardpayoutperiod: undefined,
  dayslocked: undefined,
};

export type TAssetFinancingPoolSchema = z.infer<typeof AssetFinancingPoolSchema>;
