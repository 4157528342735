
import { useState, ChangeEvent, useEffect } from "react";
import { EyeIcon, EyeSlashIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { passwordStrength as checkPasswordStrength} from "check-password-strength";
import { useTranslation } from "react-i18next";

type State = {
  name: string;
  isValid?: boolean;
  value?: string | undefined;
}

type Props = {
  id?: string;
  type?: string;
  label?: string;
  className?: string;
  errorMessage?: string;
  name?: string | undefined;
  value?: string | number | undefined;
  isValid?: boolean | undefined;
  required?: boolean | undefined;
  placeholder?: string | undefined;
  disabled?: boolean;
  defaultValue?: string | number | undefined;
  autoComplete?: string;
  labelAfter?: string;
  labelBefore?: string;
  additionalValidation?: (_value: string) => boolean;
  onChange: (_event?: State) => void;
  doNotUseRegex?: boolean;
  step?: string;
  min?: string | number | undefined;
  labelPlacement?: string;
  autoFocus?: boolean;
  useErrorText?: boolean;
  usePasswordRequirements?: boolean;
  resetFields?: boolean;
  customHasError?: boolean | null;
  customErrorMessage?: string | null;
  "data-cy"?: string;
}

type PasswordStrengh = {
  id: number | null;
  strength: string;
  requiredLength: boolean;
  hasNumberOrSpecial: boolean;
  hasUppercase: boolean;
}

export const PasswordRequirement = ({ requirementMet, children }: { requirementMet: boolean, children: React.ReactNode }) => (
  <div className={`flex items-center ${requirementMet ? "text-green-500" : ""}`}>
    <div className="w-4 h-4 inline-block mr-2">
      {requirementMet ? <CheckIcon /> : <XMarkIcon />}
    </div>
    {children}
  </div>
);

/**
 * Reusable input component with Tailwind CSS styling and validation.
 * Supports various input types and displays password requirements.
 *
 * @param {Props} props - The properties for the input component.
 * @returns {JSX.Element} The rendered input component with validation and optional password requirements.
 *
 * @example
 * ```tsx
 * import { useState } from "react";
 * import TwInput from "./TwInput";
 *
 * function MyComponent() {
 *   const [inputValue, setInputValue] = useState<string>("");
 *
 *   const handleChange = (state: State) => {
 *     setInputValue(state.value as string);
 *   };
 *
 *   return (
 *     <TwInput
 *       type="text"
 *       placeholder="Enter text"
 *       value={inputValue}
 *       onChange={handleChange}
 *     />
 *   );
 * }
 * ```
 */
function TwInput(props: Props): JSX.Element {
  const { t } = useTranslation();

  const [value, setValue] = useState({
    name: props.name,
    isValid: true
  });

  const [active, setActive] = useState(false);
  const [focus, setFocus] = useState(false);
  const [count, setCount] = useState(0);
  const [showPassword, setShowPassword] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState<PasswordStrengh>({
    id: null,
    strength: "",
    requiredLength: false,
    hasNumberOrSpecial: false,
    hasUppercase: false
  });

  function handleValidation(value: string): boolean {
    if (!props.required && value === "") return true;
    if (props.required && value === "" && props.type !== "password") return false;

    let regex = /^[\s\S]+$/;

    if (!props.doNotUseRegex) {
      switch (props.type) {
      case "number":
        regex = /^[0-9]+$/;
        break;
      case "float":
        regex = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
        break;
      case "email":
        regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        break;
      case "ID":
        regex = /^[0-9a-fA-F]{24}$/;
        break;
      case "phone":
        regex = /^[/+]?[(]?[0-9]{3}[)]?[-\s/.]?[0-9]{3}[-\s/.]?[0-9]{4,6}$/;
        break;
      case "publickey":
        regex = /^0x[a-fA-F0-9]{40}$/;
        break;
      case "password":
        if (props.usePasswordRequirements) {
          const strength = checkPasswordStrength(value);
          const passwordStrength = {
            id: strength.id,
            strength: strength.value,
            requiredLength: strength.length >= 8,
            hasNumberOrSpecial: strength.contains.includes("symbol") || strength.contains.includes("number"),
            hasUppercase: strength.contains.includes("uppercase"),
          };

          setPasswordStrength(passwordStrength);

          return Object.values(passwordStrength).every(Boolean);
        }
        break;
      }
    }

    return regex.test(value) && (!props.additionalValidation || props.additionalValidation(value));
  }

  /**
  * Multi event handler for the input field
  */
  function handleInputOnActive() {
    setActive(true);
    setFocus(true);
  }

  /**
  * OnBlur handler for the input field
  */
  function handleOnBlur() {
    if(count > 0) {
      setActive(true);
    }
    setActive(false);
    setFocus(false);
  }

  function handleOnChange(event: ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;
    let parsedValue: any;


    if(props.type == "number") {
      parsedValue = parseInt(value);
    } else if(props.type == "float") {
      parsedValue = parseFloat(value);
    } else {
      parsedValue = value;
    }

    const values = {
      name,
      value: parsedValue,
      isValid: handleValidation(value)
    };
    setFocus(true);
    setValue(values);
    setCount(value.length);
    props?.onChange(values);
  }

  function handlePasswordIconClick() {
    setShowPassword((prev) => !prev);
  }

  useEffect(() => {
    if(props.defaultValue && value.isValid) {
      setFocus(true);setActive(true);setCount(1);
    }
  }, [handleOnChange]);


  return (
    <div data-cy="twinput" className="relative z-10">
      {/* Floating Label */}
      {
        props.placeholder && props.labelPlacement !== "stacked" &&
          <label data-cy="twinput-label" className={`mf-input-label
            ${active ? `-transform-y-4 mf-input-label-after ${props.labelAfter}` : `mf-input-label-before ${props.labelBefore}`}`}
          >
            {props.placeholder}
          </label>
      }

      {/* Stacked Label */}
      {
        props.labelPlacement === "stacked" &&
        <label data-cy="twinput-label-stacked" className="select-none dark:text-gray-200">
          {props.label}
        </label>
      }

      {/* Input */}
      <input
        data-cy={props["data-cy"]}
        id={props.id}
        type={!showPassword? "text": props.type}
        name={props.name}
        onClick={handleInputOnActive}
        onChange={handleOnChange}
        required={props.required}
        onBlur={handleOnBlur}
        onFocus={handleInputOnActive}
        onKeyDownCapture={handleInputOnActive}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        autoComplete={props.autoComplete}
        placeholder={props.labelPlacement === "stacked" ? props.placeholder : ""}
        step={props.step}
        min={props.min}
        autoFocus={props.autoFocus}
        className={`z-1 rounded-sm focus:border-mfpurple dark:focus:border-mforange h-[40px]
          ${props.className} ${!value.isValid && focus === false || props.customHasError ? "border-red-500" : "border-gray-200 dark:border-gray-500"} ${props.useErrorText ? "mb-[0.25rem]" : ""}`}
      />

      {/* View Password */}
      {
        props.type === "password" &&
        <div
          data-cy="twinput-view-password"
          onClick={handlePasswordIconClick}
          className={`
            absolute w-6 h-6 right-3 text-gray-600 cursor-pointer dark:text-gray-200
            ${props.labelPlacement === "stacked" ? "top-11" : "top-4"}
          `}
        >
          {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
        </div>
      }

      {/* Error Message */}
      {
        !props.usePasswordRequirements && props.useErrorText &&
        <p data-cy="twinput-error" className={`
            ${props.customHasError || (!value.isValid && focus === false) ? "scale-100": "scale-0"}
            transition-transform duration-200 text-red-500 text-xs h-auto mb-2 origin-top-left
        `}
        >
          {!value.isValid && focus === false ? props.errorMessage : props.customHasError ? props.customErrorMessage : ""}
        </p>
      }

      {/* Password Requirements */}
      {
        props.type === "password" && props.usePasswordRequirements &&
        <div data-cy="twinput-password-req" className="text-sm my-2 select-none">
          <p className="font-semibold mb-1">
            <span className="mr-2">{t("components:input.password.title")}</span>
            <span data-cy="twinput-password-req-label" className={`text-${passwordStrength.id === null || passwordStrength.id === 0 ? "red" :
              passwordStrength.id === 1 ? "orange" :
                passwordStrength.id === 2 ? "yellow" : "green"}-500`}
            >
              {passwordStrength.strength ? t("components:input.password.strength" + passwordStrength.id) : t("components:input.password.enterpassword")}
            </span>
          </p>
          <PasswordRequirement requirementMet={passwordStrength.requiredLength}>
            {t("components:input.password.lengthcheck")}
          </PasswordRequirement>
          <PasswordRequirement requirementMet={passwordStrength.hasNumberOrSpecial}>
            {t("components:input.password.numberandspecialcheck")}
          </PasswordRequirement>
          <PasswordRequirement requirementMet={passwordStrength.hasUppercase}>
            {t("components:input.password.uppercasecheck")}
          </PasswordRequirement>
        </div>
      }
    </div>
  );
}

export default TwInput;
