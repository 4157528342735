import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { TokenTransaction, TokenTransactionStatus, TokenTransactionType } from "@/graphql/__generated__/graphql-operations";
import { copyTextToClipboard, formatDateLocale, formatNumberLocale, toNormalCase } from "@/utils/helpers";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import { Dispatch, SetStateAction } from "react";
import { useIntl } from "react-intl";
import { useTranslation } from "react-i18next";
import { TransactionsPageModals } from "@/constants/enums";
import { handleBlockchainExplorerLink } from "@/blockchain/helpers";
import { FramerButton } from "@/components/shared";

type Props = {
  transaction: TokenTransaction;
  setShowModal: Dispatch<SetStateAction<TransactionsPageModals>>;
}

function TransactionDetailsModal({ transaction, setShowModal }: Props) {

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["modals", "common"]);

  // Constants
  const isDeposit = transaction.type === TokenTransactionType.Buy;
  const isPending = transaction.status === TokenTransactionStatus.Pending;

  // Bank Account details --> Replace with actual Myron data
  const bankDetails = [
    {
      title: t("transactiondetails.bankdetails.accountholder"),
      value: "Myron AG"
    },
    {
      title: t("transactiondetails.bankdetails.bankname"),
      value: "Deutschland Bank"
    },
    {
      title: t("transactiondetails.bankdetails.bankaddress"),
      value: "Deutsche Straße, 1"
    },
  ];

  const accountDetails = [
    {
      title: "IBAN",
      value: "IBAN123456789"
    },
    {
      title: "BIC",
      value: "BIC123456789"
    },
    {
      title: t("transactiondetails.bankdetails.reference"),
      value: transaction.referencenumber
    }
  ];

  const tokenTransactionDetails = [
    {
      title: t("transactiondetails.amount"),
      value: formatNumberLocale(intl, transaction.amount, "currency")
    },
    {
      title: t("transactiondetails.type"),
      value: isDeposit ? t("transactiondetails.deposit") : t("transactiondetails.withdrawal")
    },
    {
      title: t("transactiondetails.status"),
      value: toNormalCase(transaction.status)
    },
    {
      title: "Bank Transfer Date",
      value: formatDateLocale(intl, transaction.banktransferdate)
    }
  ];

  return (
    <BlankModal
      setShowModal={() => setShowModal(TransactionsPageModals.NoModal)}
      className="px-6"
      customContentCSS="mf-modal-content p-8"
      title={t("transactiondetails.title")}
      titleCSS={"mt-0 text-xl font-semibold dark:text-white"}
    >
      <BlankModalContent>
        <div className="dark:text-white">
          {/* Transaction details */}
          <div className="space-y-1">
            {
              tokenTransactionDetails.map((item) => item.value && (
                <div key={`transactiondetails:${item.title}`} className="flex items-center">
                  <p className="w-[200px] text-sm text-gray-400">
                    {item.title}
                  </p>
                  <p>
                    {item.value}
                  </p>
                </div>
              ))
            }
            <div>
              <p className="text-sm text-gray-400">
                Transaction Hash
              </p>
              <p className="break-all">
                <a href={handleBlockchainExplorerLink("tx", transaction.txhash || "")} rel="noreferrer" target="_blank" className="text-blue-400 text-xs">
                  {transaction.txhash}
                </a>
              </p>
            </div>
          </div>

          {
            isPending && !isDeposit &&
            <div className="my-4">
              <p className="font-semibold text-mfpurple dark:text-mforange">
                {t("transactiondetails.processing")}
              </p>
            </div>
          }

          {
            isPending && isDeposit &&
            <>
              <div className="mb-2 mt-4">
                <p className="font-semibold text-mfpurple dark:text-mforange">
                  {t("transactiondetails.awaitingpayment")}
                </p>
              </div>
              {/* Bank details */}
              <div>
                <p className="text-lg font-semibold mb-2">
                  {t("transactiondetails.bankdetails.title")}
                </p>
                <div className="rounded-md bg-gray-100 dark:bg-mfdarklighter p-4 space-y-1 select-none">
                  {bankDetails.map((item) => (
                    <div key={`bankdetails:${item.title}`}>
                      <p className="text-sm text-gray-400 pb-1">{item.title}</p>
                      <p>{item.value}</p>
                    </div>
                  ))}
                  {accountDetails.map((item) => (
                    <div
                      key={`accountdetails:${item.title}`}
                      className="flex items-center justify-between"
                    >
                      {/* Details */}
                      <div>
                        <p className="text-sm text-gray-400 pb-1">{item.title}</p>
                        <p className="text-lg font-semibold">{item.value}</p>
                      </div>
                      {/* Copy to clipboard */}
                      <div>
                        <FramerButton
                          onClick={() => copyTextToClipboard(item.value as string)}
                        >
                          <ClipboardIcon
                            className="w-6 h-6 cursor-pointer active:scale-95"
                          />
                        </FramerButton>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          }
        </div>

        {/* Contact subtext */}
        <div className="mt-4">
          <p className="text-xs dark:text-white" data-cy="transaction-details-modal-subtext">
            {t("transactiondetails.queries")}
            <a href="mailto:contact@myron.finance" className="text-blue-400">
              contact@myron.finance
            </a>
          </p>
        </div>
      </BlankModalContent>

      <BlankModalFooter>
        {/* Action buttons */}
        <div className="mt-4">
          <div className="flex items-center justify-between">
            <FramerButton
              onClick={() => setShowModal(TransactionsPageModals.NoModal)}
              className="mf-btn-action-small-primary-outline"
            >
              {t("common:buttons.cancel")}
            </FramerButton>
          </div>
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default TransactionDetailsModal;
