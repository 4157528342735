import {
  CompanyAddressFragmentDoc,
  CompanyDetailsOnlyFragmentDoc,
  CompanyPublicKeyFragmentDoc,
  EditCompanyMutationFn,
  EditCompanyMutationVariables,
  useEditCompanyMutation
} from "@/graphql/__generated__/graphql-operations";
import { t } from "i18next";
import { toast } from "react-toastify";
import { client } from "@/client";
import merge from "lodash.merge";
import { useCompanyData } from "../cache/useCompanyData";
import { ApolloError } from "@apollo/client";

/**
 * Custom hook to edit company details, address, or public key.
 * It uses the `useEditCompanyMutation` from Apollo Client to perform the mutation
 * and updates the Apollo cache accordingly.
 *
 * @returns {{ editCompanyMutation, editCompany, loading, error}} Object containing `editCompany` function, mutation state, and error state.
 *
 * @example
 * ```typescript
 * const { editCompany, loading, error } = useEditCompanyMutationHook();
 *
 * const handleEditCompany = async () => {
 *   const variables: EditCompanyMutationVariables = { /* mutation variables here *\/ };
 *   const newData = { /* new data to update *\/ };
 *   const type: "Settings" | "PublicKey" | "Address" = "Settings";
 *
 *   await editCompany(variables, newData, type);
 * };
 * ```
 */
export default function useEditCompanyMutationHook() : {
  editCompanyMutation: EditCompanyMutationFn;

  editCompany: (variables: EditCompanyMutationVariables, newData: any, type: "Settings" | "PublicKey" | "Address" | "", publicKeyInput?: any) => Promise<void>;
  loading: boolean;
  error: ApolloError | undefined;
  } {
  const [editCompanyMutation, { loading, error }] = useEditCompanyMutation();
  const company = useCompanyData();

  const updateCache = (id: string, fragmentDoc: any, data: any) => {
    client.writeFragment({
      id,
      fragment: fragmentDoc,
      data,
    });
  };

  const editCompany = async (
    variables: EditCompanyMutationVariables,
    newData: any,
    type: "Settings" | "PublicKey" | "Address" | "",
    publicKeyInput?: any
  ) => {
    try {
      const result = await editCompanyMutation({ variables });

      if (!result.data?.editCompany) return;

      toast.success(t("toasts:editCmpnyStngsSuccess"), { toastId: "editCmpnyStngsSuccess" });

      const cacheId = `${company?.__typename}:${company?._id}`;

      if (type === "Settings" && newData) {
        const { facebook, twitter, linkedin, website, ...companyDetails } = newData ?? {};

        const existingData = client.readFragment({
          id: cacheId,
          fragment: CompanyDetailsOnlyFragmentDoc,
        });

        const mergedCompanyDetails = merge({}, existingData?.companydetails, companyDetails);
        const mergedWebPresence = merge({}, existingData?.companydetails?.webpresence, { facebook, twitter, linkedin, website });

        updateCache(cacheId, CompanyDetailsOnlyFragmentDoc, {
          __typename: "ServiceProvider",
          companydetails: {
            ...mergedCompanyDetails,
            webpresence: mergedWebPresence,
          },
        });
      }

      if (type === "Address") {
        updateCache(cacheId, CompanyAddressFragmentDoc, {
          __typename: company?.__typename,
          address: {
            ...merge({}, company?.address, newData),
          },
        });
      }

      if (type === "PublicKey") {
        updateCache(cacheId, CompanyPublicKeyFragmentDoc, {
          __typename: company?.__typename,
          publickey: publicKeyInput ?? company?.publickey,
        });
      }
    } catch (error) {
      console.error("Error editing company:", error);
    }
  };

  return {
    editCompanyMutation,
    editCompany,
    loading,
    error,
  };
}
