import { FormInput, FormSelect } from "@/components/shared";
import { FieldValues } from "react-hook-form";
import { FormGroupProps } from "../props";
import { FormField } from "@/types";

type WrapperProps<T extends FieldValues> = FormGroupProps<T> & {
  title: string;
  formFields: FormField[];
};

/**
 * Wrapper component for form groups.
 *
 * @template T - Type of field values.
 * @param {WrapperProps<T>} props - Props for the component.
 * @example
 * ```tsx
 * const formFields: FormField[] = [] // Form fields
 *
 * const { register, errors, watch } = useForm();
 *
 * return (
 *  <FormGroupWrapper
 *   register={register}
 *    errors={errors}
 *    watch={watch}
 *    formFields={formFields}
 *    title="Form Title"
 *  />
 * )
 * ```
 * @returns {JSX.Element} - The rendered JSX element.
 */
const FormGroupWrapper = <T extends FieldValues>({ register, errors, watch, formFields, title }: WrapperProps<T>): JSX.Element => {
  return (
    <div className="mf-create-company-input-container">
      <h1 className="text-xl font-semibold text-mfpurple dark:text-mforange">
        {title}
      </h1>
      {formFields.map((field) => (
        field.isDropdown ? (
          <FormSelect
            key={field.key}
            register={register}
            errors={errors}
            field={field}
            watch={watch}
          />
        ) : (
          <FormInput
            key={field.key}
            register={register}
            errors={errors}
            field={field}
            watch={watch}
          />
        )
      ))}
    </div>
  );
};

export default FormGroupWrapper;
