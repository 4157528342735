import { nonEmptyString, nonNegativeIntegerWithRange, platformUrlSchema, positiveInteger, urlSchema } from "@/utils/form";
import { z } from "zod";

// Validation schema for company details
export const CompanyDetailsSchema = z.object({
  industry: nonEmptyString("Industry"),
  description: nonEmptyString("Description"),
  yearlyrevenue: positiveInteger("Yearly revenue"),
  foundingyear: nonNegativeIntegerWithRange(
    "Founding year",
    1900, // min
    new Date().getFullYear(), // max
    "Year must be between 1900 and current year",
    "Year cannot be in the future"
  ),
  website: urlSchema(),
  facebook: platformUrlSchema("facebook"),
  twitter: platformUrlSchema("twitter"),
  linkedin: platformUrlSchema("linkedin"),
});

export const CompanyDetailsDefaultValues = {
  industry: "",
  description: "",
  yearlyrevenue: undefined,
  foundingyear: undefined,
  website: undefined,
  facebook: undefined,
  twitter: undefined,
  linkedin: undefined,
};

export type TCompanyDetailsSchema = z.infer<typeof CompanyDetailsSchema>;
