import { DeveloperDetailsFragmentDoc, EditUserInput, EmployeeDetailsFragmentDoc, useEditUserMutation } from "@/graphql/__generated__/graphql-operations";
import { t } from "i18next";
import { toast } from "react-toastify";

export default function useEditUserMutationHook() {
  const [ editUserMutation, { loading, error }] = useEditUserMutation();

  const editUser = (id: string, user: any, input: EditUserInput) => {
    return editUserMutation({
      variables: {
        id,
        userInput: input
      },
      onCompleted(result: { editUser: any }) {
        if(result.editUser === true) toast.success(t("toasts:editUserSuccess"), {toastId: "editUserSuccess"});
        else toast.error(t("toasts:editUserFailed"), {toastId: "editUserFailed"});
      },
      update(client) {
        client.writeFragment({
          id: `${user.__typename}:${user!._id}`,
          fragment: user.__typename == "Developer" ? DeveloperDetailsFragmentDoc : EmployeeDetailsFragmentDoc,
          data: {
            ...user,
            ...input
          }
        });
      }
    });
  };

  return {
    editUser,
    loading,
    error
  };
}
