import { CompanyType } from "@/graphql/__generated__/graphql-operations";
import { FormField } from "@/types";
import { FieldValues } from "react-hook-form";
import { FormGroupProps } from "../props";
import FormGroupWrapper from "./FormGroupWrapper";

/**
 * Renders the General Information form group.
 *
 * @template T - The type of field values.
 * @param {Props<T>} props - The component props.
 * @param {Function} props.register - The register function from react-hook-form.
 * @param {Record<string, any>} props.errors - The errors object from react-hook-form.
 * @param {Function} props.watch - The watch function from react-hook-form.
 * @returns {JSX.Element} The rendered General Information form group.
 */
const GeneralInformation = <T extends FieldValues>( { register, errors, watch }: FormGroupProps<T>): JSX.Element => {

  const formFields: FormField[] = [
    {
      title: "Company type",
      key: "create-company-company-type",
      name: "companytype",
      placeholder: "Select company type",
      isDropdown: true,
      className: "mf-input-field md:min-w-[150px]",
      required: true,
      dropdownOptions: [
        { text: "Financier", value: CompanyType.Financier },
        { text: "Service Provider", value: CompanyType.Serviceprovider }
      ],
    },
    {
      title: "Company name",
      key: "create-company-company-name",
      name: "name",
      className: "mf-input-field md:min-w-[150px]",
      required: true,
      placeholder: "Enter company name",
      type: "text",
    },
    {
      title: "Phone number",
      key: "create-company-phone-number",
      name: "phone",
      className: "mf-input-field md:min-w-[150px]",
      required: true,
      placeholder: "+1234567890",
      type: "text",
    }
  ];

  return (
    <FormGroupWrapper
      title="General Information"
      register={register}
      errors={errors}
      watch={watch}
      formFields={formFields}
    />
  );
};

export default GeneralInformation;
