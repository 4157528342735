import { passwordSchema } from "@/utils/form";
import { z } from "zod";


export const PasswordSchema = z.object({
  password: passwordSchema,
  newPassword: passwordSchema,
  confirmNewPassword: passwordSchema,
}).superRefine(({ newPassword, confirmNewPassword }, ctx) => {
  if (newPassword !== confirmNewPassword) {
    ctx.addIssue({
      code: "custom",
      message: "New password fields do not match",
      path: ["confirmNewPassword"], // Error will be added to confirmNewPassword field
    });
  }
});

export const PasswordDefaultValues = {
  password: "",
  newPassword: "",
  confirmNewPassword: "",
};

export type TPasswordSchema = z.infer<typeof PasswordSchema>;
