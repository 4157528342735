import { useCompanyData, useUserData } from "@/hooks";
import { ChangeEvent, useRef, useState } from "react";
import { useChangeLogoMutationHook } from "@/hooks/mutations";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { useTranslation } from "react-i18next";
import { AvatarComponent, FramerButton } from "@/components/shared";

function Profile() {

  // Refs
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const logoInputElement = document.getElementById("logoInput") as HTMLInputElement;

  // States
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [previewURL, setPreviewURL] = useState<string | null>(null);
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false);
  const [logoIsTooBig, setLogoIsTooBig] = useState<boolean>(false);

  // Hooks
  const company = useCompanyData();
  const user = useUserData();
  const { t } = useTranslation(["translation", "common"]);

  const userName = `${user?.firstname} ${user?.lastname}`;

  // Mutation hooks
  const { changeLogoMutation } = useChangeLogoMutationHook();

  function handleLogoSubmit(deleteLogo?: boolean) {
    if (company && (logoFile || deleteLogo)) {
      changeLogoMutation({
        variables: {
          id: company._id,
          newPicture: deleteLogo ? null : logoFile as File
        },
      }).then(() => setShowSubmitButton(false));
    }
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { validity, files } } = event;

    if (files && files[0] && validity.valid && company) {
      // Check image size
      if(files[0].size > 1048576) { // 1MB
        setLogoIsTooBig(true);
        return;
      }
      setLogoIsTooBig(false);
      setLogoFile(files[0]);
      setShowSubmitButton(true);

      // Display file preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result as string);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  function handleInputFieldClick() {
    hiddenFileInput!.current!.click();
  }

  const handleDiscardClick = () => {
    setLogoFile(null);
    setPreviewURL(null);
    setShowSubmitButton(false);

    (logoInputElement as HTMLInputElement).value = "";
  };

  return (
    <div>
      <div className="mb-4 select-none">
        <div className="flex space-x-2 items-center mb-2">
          <CheckCircleIcon className="h-6 w-6" />
          <h2 className="font-semibold text-lg">{t("settings.profile.title")}</h2>
        </div>
        <p>{t("settings.profile.description")}</p>
      </div>

      {/* Logo and name details */}
      <div>
        <div className="flex flex-col-reverse md:flex-row md:items-center gap-y-4 gap-x-10">
          {/* Logo */}
          <div className="flex-shrink-0 select-none">
            <AvatarComponent size="120" userName={userName} logo={previewURL ?? company?.logo} />
            <input
              id="logoInput"
              className="hidden"
              onChange={(event) => handleFileChange(event)}
              ref={hiddenFileInput}
              type="file"
              accept="image/*"
            />
          </div>
          {/* Name and email */}
          <div>
            <p className="text-md font-semibold">{user?.firstname} {user?.lastname}</p>
            <p className="font-light text-gray-500">{user?.mail}</p>
          </div>
        </div>

        {
          logoIsTooBig &&
          <p className="text-sm text-red-500 mt-2 select-none">{t("settings.profile.error")}</p>
        }
        <p className="text-xs text-gray-500 my-2 select-none">{t("settings.profile.note")}</p>

        {/* Buttons */}
        <div className="flex space-x-2 mt-2">
          {/* Delete button */}
          {
            company?.logo &&
            <FramerButton
              onClick={() => handleLogoSubmit(true)}
              className="mf-btn-action-small-red-filled"
            >
              {t("settings.profile.deletepicturebtn")}
            </FramerButton>
          }

          {/* Change logo button */}
          <FramerButton
            onClick={handleInputFieldClick}
            className="mf-btn-action-small-primary-outline"
          >
            {t("settings.profile.changepicturebtn")}
          </FramerButton>
        </div>

        {showSubmitButton &&
          <div className="flex space-x-2 mt-2">
            {/* Discard button */}
            <FramerButton
              onClick={handleDiscardClick}
              className="mf-btn-action-small-red-filled"
            >
              {t("common:buttons.discard")}
            </FramerButton>

            {/* Submit button */}
            <FramerButton
              onClick={() => handleLogoSubmit()}
              className="mf-btn-action-small-primary-filled"
            >
              {t("common:buttons.submit")}
            </FramerButton>
          </div>
        }
      </div>
    </div>
  );
}

export default Profile;
