import {
  ColumnDef, flexRender, getCoreRowModel,
  useReactTable, SortingState, getPaginationRowModel,
  getSortedRowModel, VisibilityState, Column,
} from "@tanstack/react-table";
import {
  Table, TableBody, TableCell,
  TableHead, TableHeader, TableRow,
} from "@/components/ui/Table";
import { useState } from "react";
import { ValueSkeleton } from "@/components/skeletons";
import { Button } from "@/components/ui/Button";
import { ArrowUpDown } from "lucide-react";

interface DataTableProps<TData, TValue> {
  /** Array of column definitions for the table */
  columns: ColumnDef<TData, TValue>[];
  /** Array of data to be displayed in the table */
  data: TData[];
  /** Boolean indicating if data is still loading */
  loading: boolean;
  /** Number of rows to display as a skeleton loader */
  displayAmount: number;
}

/**
 * DataTable component to render a table with pagination and sorting capabilities.
 *
 * @template TData - The type of data being rendered.
 * @template TValue - The type of the column values.
 * @param {DataTableProps<TData, TValue>} props - The component props.
 * @returns {JSX.Element} The rendered DataTable component.
 * @example
 * ```jsx
 * const columns = [
 *   {
 *     header: 'Name',
 *     accessorKey: 'name',
 *   },
 *   {
 *     header: 'Age',
 *     accessorKey: 'age',
 *   },
 * ];
 *
 * const data = [
 *   { name: 'Alice', age: 25 },
 *   { name: 'Bob', age: 30 },
 * ];
 *
 * <DataTable
 *   columns={columns}
 *   data={data}
 *   loading={false}
 *   displayAmount={5}
 * />
 * ```
 */
export function DataTable<TData, TValue>({ columns, data, loading, displayAmount}: DataTableProps<TData, TValue>): JSX.Element {

  // States
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const table = useReactTable({
    data,
    columns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnVisibility,
    },
  });

  return (
    <div className="rounded-md border overflow-y-scroll">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} className="text-base font-bold select-none h-8 text-gray-800 dark:text-white">
                    {header.isPlaceholder
                      ? null
                      :
                      flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )
                    }
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody key={`data-table-${Math.random()}`} className="dark:text-white">
          {
            loading ?
            // Show loading skeleton if data is still loading
              [...Array(displayAmount)].map((row) => (
                <TableRow key={row}>
                  {table.getAllColumns().map((cell) => (
                    <TableCell key={cell.id} className="text-sm">
                      <ValueSkeleton className="max-h-[20px]" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
              :
              // Render data if array not empty
              table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    className="odd:bg-gray-100 dark:odd:bg-gray-800"
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell className="text-sm" key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
                // Show no data available message if array is empty
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              )}
        </TableBody>
      </Table>
    </div>
  );
}

export const ActionButton = <TData, _TValue>({ dataObj, fn, text }: {dataObj: TData, fn:(_obj: TData) => void, text?: string}) => {
  return(
    <Button
      size={"xs"}
      className="dark:text-white"
      variant="ghost"
      onClick={() => fn(dataObj)}
    >
      {text ?? "Details"}
    </Button>
  );
};
export const SortingButton = <TData, TValue>({ column}: {column: Column<TData, TValue>}) => {
  return(
    <Button
      className={"ml-2 px-1 py-1"}
      variant="ghost"
      onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    >
      <ArrowUpDown className="h-4 w-4" />
    </Button>
  );
};

export const TableColumnHeader = ({ text, column }: {text: string, column: any}) => {

  return (
    <div className="flex items-center">
      {text}
      <SortingButton
        column={column}
      />
    </div>
  );
};
