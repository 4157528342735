import { EthNetworkConfiguration, Magic } from "magic-sdk";

const MAGIC_API_KEY: string = import.meta.env.VITE_MAGIC_API_KEY as string;
const RPC_URL: string = import.meta.env.VITE_RPC_URL as string;
const NETWORK_ID: number = import.meta.env.VITE_NETWORK_ID as number;

const customNodeOptions: EthNetworkConfiguration | undefined = {
  rpcUrl: RPC_URL,
  chainId: NETWORK_ID,
};

export const magic = new Magic(MAGIC_API_KEY, {
  network: customNodeOptions
});

// Starts downloading the static assets required to render the Magic iframe context.
const preloadAssets = () => (magic.preload);
preloadAssets();

export default magic;
