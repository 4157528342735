import {
  AssetFinancingPoolEdge,
  AssetFinancingStatus,
  GetPaginatedAssetFinancingPoolsQueryVariables,
  useGetPaginatedAssetFinancingPoolsQuery,
  GetPaginatedAssetFinancingPoolsQuery,
} from "@/graphql/__generated__/graphql-operations";
import PortfolioCard from "./PortfolioCard";
import { Pagination, PaginationContentScrollable } from "@/components/shared";
import SPCardSkeleton from "@/components/skeletons/SPCardSkeleton";
import { useEffect, useRef } from "react";
import { usePaginationState } from "@/hooks/cache/appState/usePaginationState";
import { PaginationState } from "@/types";

interface PaginationRef {
  triggerHandleFilterSubmit: (
    _newVariables: Partial<GetPaginatedAssetFinancingPoolsQueryVariables>
  ) => void;
}

/**
 * Component for displaying a list of Service Providers (SPs) that are open to investments.
 * The component uses pagination to fetch and display data in a scrollable format.
 *
 * @component
 *
 * @example
 * ```tsx
 * import SPsOpenToInvest from './SPsOpenToInvest';
 *
 * function MyApp() {
 *   return (
 *     <div>
 *       <SPsOpenToInvest />
 *     </div>
 *   );
 * }
 *
 * export default MyApp;
 * ```
 */
function SPsOpenToInvest() {
  // Pagination
  const paginationRef = useRef<PaginationRef | null>(null);
  const paginationName = "paginatedAssetFinancingPools";
  const paginationState: PaginationState["paginatedAssetFinancingPools"] =
    usePaginationState("paginatedAssetFinancingPools");

  /**
   * Handles submission of filter variables for pagination.
   *
   * @param {GetPaginatedAssetFinancingPoolsQueryVariables} variables - The variables to submit for filtering.
   */
  const onFilterSubmit = (
    variables: GetPaginatedAssetFinancingPoolsQueryVariables
  ) => {
    paginationRef.current?.triggerHandleFilterSubmit(variables);
  };

  useEffect(() => {
    // Prevent refetching data if already on the correct status
    if (paginationState.filters.status === AssetFinancingStatus.Open) return;

    onFilterSubmit({
      first: 10,
      status: AssetFinancingStatus.Open,
    });
  }, []);

  return (
    <div className="mf-flex-y-fill bg-white dark:bg-mfdarklight rounded-md shadow-lg p-4">
      <h1 className="pb-2 font-semibold text-lg select-none dark:text-white">
        Available Investments
      </h1>

      <Pagination<
        AssetFinancingPoolEdge,
        GetPaginatedAssetFinancingPoolsQuery,
        GetPaginatedAssetFinancingPoolsQueryVariables
      >
        ref={paginationRef}
        props={{
          queryHook: useGetPaginatedAssetFinancingPoolsQuery,
          paginationName,
          className: "mt-2",
        }}
      >
        {(dataSlice, loading, displayAmount) => (
          <PaginationContentScrollable>
            {loading
              ? // Show loading skeletons if data loading
              [...Array(displayAmount).keys()].map((index) => {
                return <SPCardSkeleton key={`spSkeleton:${index}`} />;
              })
              : dataSlice.map((afp) => {
                return (
                  <PortfolioCard
                    key={afp.node._id}
                    assetFinancingPool={afp.node}
                  />
                );
              })}
          </PaginationContentScrollable>
        )}
      </Pagination>
    </div>
  );
}

export default SPsOpenToInvest;
