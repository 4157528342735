import React, { ButtonHTMLAttributes } from "react";
import { motion, MotionProps } from "framer-motion";

// Combine ButtonHTMLAttributes and MotionProps
type FramerButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & MotionProps;
/**
 * `FramerButton` is a custom React button component that combines the
 * HTML button attributes with `framer-motion` animation props.
 * It allows you to add motion effects such as hover and tap animations.
 *
 * The button animates by changing its opacity on hover and shrinking slightly on tap.
 *
 * @property {React.ReactNode} [children] - The content inside the button.
 * @property {ButtonHTMLAttributes<HTMLButtonElement>} ...props - Any additional button HTML attributes.
 * @property {MotionProps} ...props - Props from `framer-motion` for animation controls.
 *
 * @param {FramerButtonProps} props - The props for the FramerButton.
 * @returns {JSX.Element} The animated button component.
 *
 * @example
 * // Basic usage
 * <FramerButton onClick={() => console.log('Clicked!')}>
 *   Click Me
 * </FramerButton>
 *
 * @example
 * // With custom motion props
 * <FramerButton
 *   onClick={() => console.log('Clicked!')}
 *   whileHover={{ scale: 1.1 }}
 *   whileTap={{ scale: 0.9 }}
 * >
 *   Hover Me
 * </FramerButton>
 */
const FramerButton: React.FC<FramerButtonProps> = ({ children, ...props }: FramerButtonProps): JSX.Element => {
  return (
    <motion.button
      whileHover={{ opacity: 0.7 }}
      whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
      {...props} // Spread the remaining props here
    >
      {children}
    </motion.button>
  );
};

export default FramerButton;
