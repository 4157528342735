import { z } from "zod";

export const LoginSchema = z.object({
  mail: z.string().email("Please enter a valid email address"),
  password: z.string({required_error: "Password is required"})
});

export const LoginDefaultValues = {
  mail: "",
  password: "",
};

// Infer the TypeScript type from the Zod schema
export type TLoginSchema = z.infer<typeof LoginSchema>;
