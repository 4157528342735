import * as Apollo from "@apollo/client";
import { calculateAmountToFetch, shouldFetchMore,shouldFetchNext } from "../utils/pagination";
import { useState } from "react";
import { FetchMoreResult, PaginationState, UseGeneratedQueryOptions, UsePaginatedQueryResult } from "@/types/pagination";
import { usePaginationState } from "./cache/appState/usePaginationState";
import { updatePaginationState } from "@/cache/appstate/WriteQueries";

/**
 * Hook for managing pagination in a React component using Apollo Client.
 *
 * This hook provides functions for handling pagination, including next/previous page navigation, changing the display amount, and applying filters. It also manages the pagination state and updates the data accordingly.
 *
 * @template TEdge The type of the edges in the paginated data.
 * @template TData The type of the paginated data.
 * @template TVariables The type of variables for the GraphQL query.
 * @param {UseGeneratedQueryOptions<TData, TVariables>} options The options for the generated query hook.
 * @param {string} paginationName The name of the pagination state in the cache.
 * @param {boolean} reversePagination Optional flag indicating if the pagination should be reversed (default is false).
 * @returns {UsePaginatedQueryResult<TEdge, TVariables>} An object containing pagination-related functions and data.
 *
 * @example
 * ```javascript
 * const { data, handleNextPage, handlePreviousPage, ...otherPaginationProps } = usePaginatedQuery(
 *   { queryHook: useQuery(GET_PRODUCTS_QUERY) },
 *   "productsPagination"
 * );
 *
 * // ... see {@/components/shared/Pagination.tsx} for full usage
 *
 * <div>
 *   <button onClick={handlePreviousPage}>Previous</button>
 *   <button onClick={handleNextPage}>Next</button>
 * </div>
 * ```
 */
export function usePaginatedQuery<TEdge, TData, TVariables extends Apollo.OperationVariables>
(options: UseGeneratedQueryOptions<TData, TVariables>, paginationName: string, reversePagination: boolean = false)
    : UsePaginatedQueryResult<TEdge, TVariables> {
  const { queryHook } = options;
  const state: PaginationState = usePaginationState("");
  const { data, loading, fetchMore, subscribeToMore } = queryHook({
    variables: {
      ...state[paginationName].filters,
      first: state[paginationName].first,
      after: "",
    },
    fetchPolicy: "cache-first"
  });

  const [ loadingMoreData, setLoadingMoreData ] = useState(false);

  /**
 * Calculates the maximum page visited based on the current data and display amount.
 *
 * @param {FetchMoreResult<typeof paginationName>} data - The data result from the fetch operation.
 * @returns {number} - The maximum page number visited.
 * @example
 * ```javascript
 * const maxPage = getMaxPageVisited(data);
 * console.log(maxPage); // Output: 4 (for example, if 4 pages have been visited).
 * ```
 */
  const getMaxPageVisited = (data: FetchMoreResult<typeof paginationName>): number => {
    if (!data) return 0;
    const displayAmount = state[paginationName].first;
    return Math.ceil(data[paginationName].edges.length / displayAmount) - 1;
  };

  /**
 * Handles the action when the next page button is clicked.
 *
 * @example
 * ```javascript
 * handleNextPage(); // Moves to the next page if available and fetches more data if necessary.
 * ```
 */
  const handleNextPage = () => {
    const currentData = data as FetchMoreResult<typeof paginationName>;
    const maxPageVisited = getMaxPageVisited(currentData);
    const hasNextPage = currentData[paginationName].pageInfo.hasNextPage;
    const currentPage = state[paginationName].currentPage;

    if (!hasNextPage && currentPage >= maxPageVisited) return;

    updatePaginationState({ [paginationName]: { currentPage: currentPage + 1 } });

    const should = shouldFetchNext(maxPageVisited, currentPage, hasNextPage);

    if (!should) return;

    const endCursor = currentData[paginationName].pageInfo.endCursor;

    setLoadingMoreData(true);
    fetchMore({
      variables: {
        ...state[paginationName].filters,
        first: state[paginationName].first,
        after: endCursor,
      },
      updateQuery: (previousResult: FetchMoreResult<string>, { fetchMoreResult }: any) => {
        setLoadingMoreData(false);
        if (!fetchMoreResult) return previousResult;
        const result = fetchMoreResult as FetchMoreResult<typeof paginationName>;
        const prevResult = previousResult as FetchMoreResult<typeof paginationName>;

        return {
          [paginationName]: {
            __typename: prevResult[paginationName].__typename,
            edges: [
              ...prevResult[paginationName].edges,
              ...result[paginationName].edges,
            ],
            pageInfo: result[paginationName].pageInfo,
          }
        };
      }
    });
  };

  /**
 * Handles the action when the previous page button is clicked.
 *
 * @example
 * ```javascript
 * handlePreviousPage(); // Moves to the previous page if not on the first page.
 * ```
 */
  const handlePreviousPage = () => {
    const currentPage = state[paginationName].currentPage;
    if (currentPage === 0) return;
    updatePaginationState({ [paginationName]: { currentPage: currentPage - 1 } });
  };

  /**
 * Handles the change in the display amount of items per page.
 *
 * @param {number} newAmount - The new display amount.
 * @example
 * ```javascript
 * handleDisplayAmount(50); // Changes the display amount to 50 and fetches more data if necessary.
 * ```
 */
  const handleDisplayAmount = (newAmount: number) => {
    const displayAmount = state[paginationName].first;
    if (newAmount === displayAmount) return;

    const currentData = data as FetchMoreResult<typeof paginationName>;
    const length = currentData[paginationName].edges.length;
    const difference = calculateAmountToFetch(newAmount, length, displayAmount);
    const hasNextPage = currentData[paginationName].pageInfo.hasNextPage;

    setLoadingMoreData(false);

    const should = shouldFetchMore(difference, hasNextPage);

    updatePaginationState({ [paginationName]: { first: newAmount, currentPage: 0 } });

    if (!should) return;

    const endCursor = currentData[paginationName].pageInfo.endCursor;
    setLoadingMoreData(true);
    fetchMore({
      variables: {
        ...state[paginationName].filters,
        first: difference,
        after: endCursor,
      },
      updateQuery: (previousResult: FetchMoreResult<string>, { fetchMoreResult }: any) => {
        setLoadingMoreData(false);
        if (!fetchMoreResult) return previousResult;
        const result = fetchMoreResult as FetchMoreResult<typeof paginationName>;
        const prevResult = previousResult as FetchMoreResult<typeof paginationName>;

        if (result[paginationName].edges.length === 0) {
          return previousResult;
        }

        return {
          [paginationName]: {
            __typename: prevResult[paginationName].__typename,
            edges: [
              ...prevResult[paginationName].edges,
              ...result[paginationName].edges,
            ],
            pageInfo: result[paginationName].pageInfo,
          }
        };
      }
    });
  };

  /**
 * Handles the submission of filter variables.
 *
 * @param {TVariables} variables - The filter variables to apply.
 * @example
 * ```javascript
 * handleFilterSubmit({ status: 'active' }); // Applies the filter and fetches data accordingly.
 * ```
 */
  const handleFilterSubmit = (variables: TVariables) => {
    const { first } = state[paginationName];

    // Update the pagination state with the new filters and reset the current page.
    updatePaginationState({ [paginationName]: { currentPage: 0, first, filters: variables } });
    setLoadingMoreData(true);
    fetchMore({
      variables: {
        ...variables,
        first,
        after: ""
      },
      updateQuery: (previousResult: FetchMoreResult<string>, { fetchMoreResult }: any) => {
        setLoadingMoreData(false);
        if (!fetchMoreResult) return previousResult;
        const result = fetchMoreResult as FetchMoreResult<typeof paginationName>;
        const prevResult = previousResult as FetchMoreResult<typeof paginationName>;

        return {
          [paginationName]: {
            __typename: prevResult[paginationName].__typename,
            edges: [
              ...result[paginationName].edges,
            ],
            pageInfo: result[paginationName].pageInfo,
          }
        };
      }
    });
  };

  /**
 * Slices the paginated data based on the current page and display amount.
 *
 * @param {TData} data - The data to slice.
 * @returns {TEdge[]} - The sliced data based on the current page and display amount.
 * @example
 * ```javascript
 * const slicedData = slicePaginatedResult(data);
 * console.log(slicedData); // Outputs the data for the current page.
 * ```
 */
  const slicePaginatedResult = (data: TData): TEdge[] => {
    if (!data) return [];
    const displayAmount = state[paginationName].first;
    const currentPage = state[paginationName].currentPage;
    const start = currentPage * displayAmount;
    const end = start + displayAmount;
    const dataSlice = (data as FetchMoreResult<typeof paginationName>)[paginationName].edges.slice(start, end);
    return reversePagination ? dataSlice.reverse() as TEdge[] : dataSlice as TEdge[];
  };

  /**
 * Gets the total amount of rows fetched.
 *
 * @returns {number} - The total amount of rows fetched.
 * @example
 * ```javascript
 * const totalRows = getAmountRowsFetched();
 * console.log(totalRows); // Output: 120, the total number of rows fetched so far.
 * ```
 */
  const getAmountRowsFetched = (): number => {
    if (!data) return 0;
    return (data as FetchMoreResult<typeof paginationName>)[paginationName].edges.length;
  };

  /**
 * Gets the starting number of the items being shown based on the current page.
 *
 * @returns {number} - The starting number of the items being shown.
 * @example
 * ```javascript
 * const showingFrom = getShowingFrom();
 * console.log(showingFrom); // Output: 11, for example, if showing items 11-20.
 * ```
 */
  const getShowingFrom = (): number => {
    const currentPage = state[paginationName].currentPage;
    const displayAmount = state[paginationName].first;
    return currentPage === 0 ? 1 : currentPage * displayAmount + 1;
  };

  /**
 * Gets the ending number of the items being shown based on the current page.
 *
 * @returns {number} - The ending number of the items being shown.
 * @example
 * ```javascript
 * const showingTo = getShowingTo();
 * console.log(showingTo); // Output: 20, for example, if showing items 11-20.
 * ```
 */
  const getShowingTo = (): number => {
    const currentPage = state[paginationName].currentPage;
    const displayAmount = state[paginationName].first;
    return currentPage === getMaxPageVisited(data as FetchMoreResult<typeof paginationName>) ?
      getAmountRowsFetched()
      :
      (currentPage + 1) * displayAmount;
  };


  return {
    handleNextPage: reversePagination ? handlePreviousPage : handleNextPage,
    handlePreviousPage: reversePagination ? handleNextPage : handlePreviousPage,
    handleDisplayAmount,
    handleFilterSubmit,
    loading,
    loadingMoreData,
    displayAmount: state[paginationName].first,
    dataSlice: slicePaginatedResult(data as TData),
    subscribeToMore,
    showingFrom: getShowingFrom(),
    showingTo: getShowingTo(),
    rowsFetched: getAmountRowsFetched(),
  };
}
