import { z } from "zod";
import { toNormalCase } from "./helpers";
import { FieldValues, SubmitErrorHandler } from "react-hook-form";
import { passwordStrength as checkPasswordStrength} from "check-password-strength";

/**
 * Regular expression for validating domain names.
 *
 * @example
 * domainRegex.test("www.example.com") // true
 * domainRegex.test("invalid-domain") // false
 */
export const domainRegex = /(www\.)?[a-zA-Z0-9-]+\.[a-z]{2,}(\.[a-z]{2,})?$/i;

/**
 * Helper function to convert strings to numbers.
 *
 * @param val The value to be converted.
 * @returns The converted number or the original value if conversion fails.
 */
export const stringToNumber = (val: unknown) => (typeof val === "string" ? parseFloat(val) : val);

/**
 * Zod schema for non-empty strings.
 *
 * @param {string} fieldName The name of the field for error messages.
 * @returns A Zod schema object for non-empty strings.
 */
export const nonEmptyString = (fieldName: string, customMin: number = 2) =>
  z.string({
    required_error: `${fieldName} is required`,
    invalid_type_error: `${fieldName} must be a string`,
  }).min(customMin, `Length must be at least ${customMin} characters`);

/**
 * Zod schema for optional strings.
 *
 * @param {string} fieldName The name of the field for error messages.
 * @returns A Zod schema object for optional strings.
 */
export const optionalString = (fieldName: string) => z.string({
  invalid_type_error: `${fieldName} must be a string`,
}).optional();

/**
 * Zod schema for positive integers.
 *
 * @param {string} fieldName The name of the field for error messages.
 * @returns A Zod schema object for positive integers.
 */
export const positiveInteger = (fieldName: string) =>
  z.preprocess(
    stringToNumber,
    z.number({
      required_error: `${fieldName} is required`,
      invalid_type_error: `${fieldName} must be a positive non-decimal number`,
    }).int().positive(`${fieldName} must be a positive integer`)
  );

/**
 * Zod schema for non-negative integers (including zero).
 *
 * @param {string} fieldName The name of the field for error messages.
 * @returns A Zod schema object for non-negative integers.
 */
export const nonNegativeInteger = (fieldName: string) =>
  z.preprocess(
    stringToNumber,
    z.number({
      required_error: `${fieldName} is required`,
      invalid_type_error: `${fieldName} must be a positive non-decimal number`,
    })
      .int()
      .min(0, `${fieldName} must be a non-negative integer`)
  );

/**
 * Zod schema for non-negative integers (including zero) with a defined range.
 *
 * @param {string} fieldName The name of the field for error messages.
 * @param {number} min The minimum allowed value (defaults to 1900).
 * @param {number} max The maximum allowed value (defaults to current year).
 * @param {string} minErrorMessage The error message for exceeding the minimum value.
 * @param {string} maxErrorMessage The error message for exceeding the maximum value.
 *
 * @returns A Zod schema object for non-negative integers within a range.
 */
export const nonNegativeIntegerWithRange = (
  fieldName: string,
  min: number,
  max: number,
  minErrorMessage: string,
  maxErrorMessage: string,
) =>
  z.preprocess(
    stringToNumber,
    z.number({
      required_error: `${fieldName} is required`,
      invalid_type_error: `${fieldName} must be a positive non-decimal number`,
    })
      .int()
      .min(min, { message: minErrorMessage })
      .max(max, { message: maxErrorMessage }),
  );

/**
 * Zod schema for positive numbers.
 *
 * @param {string} fieldName The name of the field for error messages.
 * @returns A Zod schema object for positive numbers.
 */
export const positiveNumber = (fieldName: string) =>
  z.preprocess(
    stringToNumber,
    z.number({
      required_error: `${fieldName} is required`,
      invalid_type_error: `${fieldName} cannot be empty`,
    }).positive(`${fieldName} must be a positive number`)
  );

/**
 * Zod schema for non-negative numbers (including zero).
 *
 * @param {string} fieldName The name of the field for error messages.
 * @returns A Zod schema object for non-negative numbers.
 */
export const nonNegativeNumber = (fieldName: string) =>
  z.preprocess(
    stringToNumber,
    z.number({
      required_error: `${fieldName} is required`,
      invalid_type_error: `${fieldName} must be a number`,
    }).min(0, `${fieldName} must be a non-negative number`)
  );

/**
 * Zod schema for boolean values.
 *
 * @param {string} fieldName The name of the field for error messages.
 * @returns A Zod schema object for boolean values.
 */
export const booleanSchema = (fieldName: string) => z.boolean({
  required_error: `${fieldName} is required`,
  invalid_type_error: `${fieldName} must be a boolean`,
});

/**
 * Zod schema for enum values.
 *
 * @param {string} fieldName The name of the field for error messages.
 * @param {T} values An array of string literals representing allowed enum values.
 * @returns A Zod schema object for enum values.
 */
export const enumSchema = <T extends [string, ...string[]]>(fieldName: string, values: T) =>
  z.enum(values, {
    required_error: `${fieldName} is required`,
    invalid_type_error: `${fieldName} must be one of the following: ${values.join(", ")}`,
  });

/**
 * Zod schema for native enum types.
 *
 * @param {T} enumType An object representing the native enum type.
 * @param {string} fieldName The name of the field for error messages.
 * @returns A Zod schema object for native enum types.
 */
export const nativeEnumSchema = <T extends Record<string, string>>(enumType: T, fieldName: string) =>
  z.nativeEnum(enumType, {
    errorMap: () => ({ message: `${fieldName} type is invalid` }),
  });

/**
 * Zod schema for optional boolean values.
 *
 * @param {string} fieldName The name of the field for error messages.
 * @returns A Zod schema object for optional boolean values.
 */
export const optionalBoolean = (fieldName: string) =>
  z.boolean({
    invalid_type_error: `${fieldName} must be a boolean`,
  }).optional();

/**
 * Zod schema for passwords with minimum length and complexity requirements.
 *
 * @returns A Zod schema object for passwords.
 */
export const passwordSchema = z.string()
  .min(8, "Password must be at least 8 characters long")
  .refine((value) => /[A-Z]/.test(value), {
    message: "Password must contain at least one uppercase letter",
  })
  .refine((value) => /[0-9!@#$%^&*(),.?":{}|<>]/.test(value), {
    message: "Password must contain at least one number or special character",
  });

/**
 * Regular expression for platform-specific URL validation.
 *
 * @param {string} url The URL string to validate.
 * @param {string | string[]} platform The platform or array of platforms to check against.
 * @returns True if the URL matches the platform-specific pattern, false otherwise.
 */
export const platformUrlValidator = (url: string, platform: string | string[]) => {
  const platforms = Array.isArray(platform) ? platform.join("|") : platform;
  const platformPattern = new RegExp(`(www\\.)?(${platforms})\\.[a-z]{2,}/.*$`, "i");
  return platformPattern.test(url);
};

/**
 * Regular expression for validating basic URL formats.
 *
 * @param {string} url The URL string to validate.
 * @returns True if the URL matches the basic format, false otherwise.
 */
const basicUrlValidator = (url: string) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?(www\\.)?([a-z0-9-]+\\.)+[a-z]{2,}(\\/.*)?$",
    "i"
  );
  return urlPattern.test(url);
};

/**
 * Zod schema for URLs, allowing empty strings or basic URL formats.
 *
 * @returns A Zod schema object for URLs.
 */
export const urlSchema = () => z
  .string()
  .refine(
    (url) => url === "" || basicUrlValidator(url),
    { message: "Website must be a valid URL (e.g., https://example.com)" }
  );

/**
 * Zod schema for platform-specific URLs, allowing empty strings or platform-specific formats.
 *
 * @param {string} platform The platform for which the URL should be validated.
 * @returns A Zod schema object for platform-specific URLs.
 */
export const platformUrlSchema = (platform: string) => z
  .string()
  .refine(
    (url) => url === "" || platformUrlValidator(url, platform), // Validate against platformUrlValidator, allowing empty strings
    { message: `${toNormalCase(platform)} must be a valid URL (e.g., https://www.example.com)` }
  );

/**
 * Function used to clear a form after submission
 * @param {string} elementID ID of the form element
 */
export function clearForm(elementID: string) {
  const formElement = document.getElementById(elementID) as HTMLFormElement;

  formElement.reset();
}

/**
 * Very basic but powerful error handler for form validation
 * @param {unknown} errors
 *
 * @example
 * ```tsx
 * return (
 *  <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
 *   // ...
 *  </form>
 * )
 * ```
 * @returns
 */
export const onInvalid:SubmitErrorHandler<FieldValues> = (errors: unknown) => console.error(errors);

/**
 * Check the strength of a password and returns an object with the strength level and requirements
 * @param {string} password
 * @returns {{id: number, requirements: {minLength: boolean, hasNumberOrSpecial: boolean, hasUppercase: boolean}, valid: boolean}}
 */
export const getPasswordStrength = (password: string): { id: number; strength: string, requiredLength: boolean; hasNumberOrSpecial: boolean; hasUppercase: boolean; } => {
  const strength = checkPasswordStrength(password);

  const requirements = {
    id: strength.id,
    strength: strength.value,
    requiredLength: strength.length >= 8,
    hasNumberOrSpecial: strength.contains.includes("symbol") || strength.contains.includes("number"),
    hasUppercase: strength.contains.includes("uppercase"),
  };

  return requirements;
};
