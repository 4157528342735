import { useFragment } from "@apollo/client";
import {
  CompanyDetailsOnlyFragment,
  CompanyDetailsOnlyFragmentDoc
} from "@/graphql/__generated__/graphql-operations";

/**
 * Custom hook to retrieve and subscribe to company details for a specific company.
 *
 * This hook uses the `useFragment` hook from Apollo Client to automatically
 * subscribe to changes in the company's address data.
 *
 * @param {{string, string}} {id, typename} - The company from which to retrieve the company details.
 * @returns {CompanyDetailsOnlyFragment["companydetails"] | undefined} - The company details or undefined if not available.
 *
 * @example
 * const company = useCompanyData(); // Fetch or retrieve the company data
 * const companyDetails = useCompanyDetails({ id: company?._id, typename: company?.__typename });
 *
 * if (companyDetails) {
 *   console.log(companyDetails.website);
 * }
 */
export const useCompanyDetails = ({ id, typename }: {id: string, typename: string}): CompanyDetailsOnlyFragment["companydetails"] | undefined => {
  const { data } = useFragment({
    fragment: CompanyDetailsOnlyFragmentDoc,
    from: {
      __typename: typename,
      _id: id,
    }
  });

  return data?.companydetails;
};
