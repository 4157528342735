import { useTheme } from "@/context/ThemeProvider";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { motion, AnimatePresence } from "framer-motion"; // Import framer motion and AnimatePresence
import { FramerButton } from "../shared";

type BlankModalContentProps = {
  children: React.ReactNode | React.ReactNode[];
}

type BlankModalFooterProps = {
  children: React.ReactNode | React.ReactNode[];
}

type BlankModalProps = {
  className?: string;
  showModal?: boolean;
  setShowModal: (_value: boolean) => void;
  customContentCSS?: string;
  children: React.ReactNode | React.ReactNode[];
  title?: string;
  additionalCloseAction?: () => void;
  doNotShowCloseIcon?: boolean;
  titleCSS?: string;
  doNotShowTopContainer?: boolean;
}

function BlankModalContent(props: BlankModalContentProps) {
  return (
    <div data-cy="blankmodal-content" className="flex-1 pt-2 overflow-y-auto">
      {props.children}
    </div>
  );
}

function BlankModalFooter(props: BlankModalFooterProps) {
  return (
    <div data-cy="blankmodal-footer" className="pt-2">
      {props.children}
    </div>
  );
}

function BlankModal(props: BlankModalProps) {

  const { darkMode } = useTheme();

  /**
   * Handler function for closing the modal
   */
  const onClickClose = () => {
    props.setShowModal(false);
    if(props.additionalCloseAction) {
      props.additionalCloseAction();
    }
  };

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.3 } },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.3 } },
  };

  return (
    // Modal container - overlay
    <div data-cy="blankmodal-container" className={`mf-modal-main-container ${props.className} max-md:pb-[1rem]`}>
      <AnimatePresence>
        {/* Modal card with Framer Motion animation */}
        <motion.div
          data-cy="blankmodal-card"
          className={`max-md:min-w-full max-md:max-h-[90vh] flex flex-col ${props.customContentCSS ? props.customContentCSS : "mf-modal-inner-container"} relative`}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={modalVariants}
        >
          {/* Header */}
          {
            !props.doNotShowTopContainer && (
              <div data-cy="blankmodal-card-top-container" className={`w-full items-center flex mb-4 ${props.title ? "justify-between" : "justify-end"}`}>
                {
                  props.title && (
                    <p data-cy="modal-title" className={`${props.titleCSS ? props.titleCSS : "font-base font-semibold select-none"} mt-6 w-full dark:text-white`}>
                      {props.title}
                    </p>
                  )
                }
                {
                  !props.doNotShowCloseIcon && (
                    <FramerButton
                      whileHover={{ color: "#EE5300" }}
                      style={{ color: darkMode ? "#FFFFFF" : "#000000" }}
                      className="absolute top-3 right-3"
                    >
                      <XMarkIcon
                        onClick={onClickClose}
                        className="mf-modal-close-icon"
                      />
                    </FramerButton>
                  )
                }
              </div>
            )
          }
          {/* Content + Footer */}
          {props.children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export { BlankModal, BlankModalContent, BlankModalFooter };
