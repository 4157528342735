import { FramerButton, TwInput } from "@/components/shared";
import { InputGroup, typeStateCommon } from "@/types";
import { FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { passwordInputFields } from "./inputfields";
import { useTranslation } from "react-i18next";

type Props = {
  handleOnChange: (_event: typeStateCommon) => void;
  handleFormSubmit: (_event: FormEvent<Element>) => void;
  hasErrors: boolean;
  loading: boolean;
  confirmPasswordError: boolean | null;
}

function ResetPasswordComponent({ handleOnChange, handleFormSubmit, hasErrors, loading, confirmPasswordError }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const renderButtonContent = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center">
          <div className="w-4 h-4 border-2 border-gray-400 border-t-white mr-2 rounded-full animate-spin" />
          Changing Password
        </div>
      );
    }
    return <>Change Password</>;
  };

  return (
    <div className="mb-[50px] md:mb-0 mt-4 md:mt-8">
      {/* Header wrapper */}
      <div className="mb-4">
        <p className="text-xl md:text-2xl font-semibold mb-1">Set New Password</p>
        <p className="text-sm md:text-base">Enter your new password below to reset your password.</p>
      </div>

      <form
        className="flex flex-col gap-2"
        onSubmit={handleFormSubmit}
        id="loginForm"
      >
        {
          passwordInputFields.map(({
            className,
            required,
            placeholder,
            type,
            name,
            title,
            errorMessage,
            doNotUseRegex,
            autoFocus,
            usePasswordRequirements
          }: InputGroup) => (
            <TwInput
              key={`login-input-${name}`}
              className={`${className} text-gray-800`}
              required={required}
              onChange={handleOnChange}
              placeholder={placeholder}
              type={type}
              name={name}
              label={title}
              errorMessage={errorMessage}
              doNotUseRegex={doNotUseRegex}
              labelPlacement="stacked"
              autoFocus={autoFocus}
              useErrorText
              usePasswordRequirements={usePasswordRequirements}
              customHasError={name === "confirmNewPassword" ? confirmPasswordError : null}
              customErrorMessage={name === "confirmNewPassword" ? t("toasts:passwdsDoNotMatch") : null}
            />
          ))
        }
        <FramerButton
          className={`
            rounded-sm mf-primary text-white mf-button w-full
            ${loading ? "bg-opacity-50 cursor-wait" : "hover:bg-opacity-70"}
            ${hasErrors ? "cursor-not-allowed bg-gray-400" : ""}
          `}
          type={hasErrors ? "button" : "submit"}
          disabled={hasErrors}
        >
          {renderButtonContent()}
        </FramerButton>

        {/* Back to login */}
        <p className="mt-4 text-sm md:text-base">
          <span onClick={() => {navigate("/login");}} className="text-blue-400 cursor-pointer">Back to Login</span>
        </p>
      </form>
    </div>
  );
}

export default ResetPasswordComponent;
