import React, { AnchorHTMLAttributes } from "react";
import { motion, MotionProps } from "framer-motion";

// Combine AnchorHTMLAttributes and MotionProps
type FramerAnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & MotionProps;
/**
 * `FramerAnchor` is a custom React anchor component that combines the
 * HTML anchor attributes with `framer-motion` animation props.
 * It allows you to add motion effects such as hover and tap animations.
 *
 * The anchor animates by changing its opacity on hover and shrinking slightly on tap.
 *
 * @property {React.ReactNode} [children] - The content inside the anchor.
 * @property {AnchorHTMLAttributes<HTMLAnchorElement>} ...props - Any additional anchor HTML attributes.
 * @property {MotionProps} ...props - Props from `framer-motion` for animation controls.
 *
 * @param {FrameAnchorProps} props - The props for the FramerAnchor.
 * @returns {JSX.Element} The animated anchor component.
 *
 * @example
 * // Basic usage
 * <FramerAnchor onClick={() => console.log('Clicked!')}>
 *   Click Me
 * </FramerAnchor>
 *
 * @example
 * // With custom motion props
 * <FramerAnchor
 *   onClick={() => console.log('Clicked!')}
 *   whileHover={{ scale: 1.1 }}
 *   whileTap={{ scale: 0.9 }}
 * >
 *   Hover Me
 * </FramerAnchor>
 */
const FramerAnchor: React.FC<FramerAnchorProps> = ({ children, ...props }: FramerAnchorProps): JSX.Element => {
  return (
    <motion.a
      whileHover={{ opacity: 0.7 }}
      whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
      {...props} // Spread the remaining props here
    >
      {children}
    </motion.a>
  );
};

export default FramerAnchor;
