import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import countriesData from "@/assets/country-data/country-codes.json";
import { FormField } from "@/types";
import { FormGroupProps } from "../props";
import FormGroupWrapper from "./FormGroupWrapper";

/**
 * Renders the address form group.
 *
 * @template T - The type of field values.
 * @param {Props<T>} props - The component props.
 * @param {Register} props.register - The form register function.
 * @param {Record<string, FieldError>} props.errors - The form errors object.
 * @param {Watch} props.watch - The form watch function.
 * @returns {JSX.Element} - The rendered component.
 */
const Address = <T extends FieldValues>( { register, errors, watch }: FormGroupProps<T>): JSX.Element => {

  // Hooks
  const { t } = useTranslation();

  const formFields: FormField[] = [
    {
      key: "address-input-street",
      name: "address.street",
      type: "text",
      title: t("settings.address.street"),
      placeholder: "Long Street",
      required: true,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "address-input-housenumber",
      name: "address.housenumber",
      type: "text",
      title: t("settings.address.housenumber"),
      placeholder: "100",
      required: true,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "address-input-city",
      name: "address.city",
      type: "text",
      title: t("settings.address.city"),
      placeholder: "Berlin",
      required: true,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "address-input-zipcode",
      name: "address.zipcode",
      type: "text",
      title: t("settings.address.zipcode"),
      placeholder: "123",
      required: true,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "address-input-country",
      name: "address.country",
      isDropdown: true,
      title: t("settings.address.country"),
      placeholder: "Select a country",
      required: true,
      className: "mf-input-field md:min-w-[150px]",
      dropdownOptions: countriesData?.map(({ name }) => ({
        text: name,
        value: name,
      })),
    }
  ];

  return (
    <FormGroupWrapper
      title="Address"
      register={register}
      errors={errors}
      watch={watch}
      formFields={formFields}
    />
  );
};

export default Address;
