import { AssetType } from "@/graphql/__generated__/graphql-operations";
import { FormField } from "@/types";
import { useEffect } from "react";
import { FieldValues, Path, PathValue } from "react-hook-form";
import { FormGroupProps } from "../props";
import FormGroupWrapper from "./FormGroupWrapper";

/**
 * Renders the Asset Financing Pool form group.
 *
 * @template T - The type of field values.
 * @param {Props<T>} props - The component props.
 * @param {UseFormRegister<T>} props.register - The register function from react-hook-form.
 * @param {UseFormStateReturn<T>["errors"]} props.errors - The errors object from react-hook-form.
 * @param {UseFormWatch<T>} props.watch - The watch function from react-hook-form.
 * @param {UseFormSetValue<T>} [props.setValue] - The setValue function from react-hook-form.
 * @returns {JSX.Element} - The rendered component.
 */
const AssetFinancingPool = <T extends FieldValues>( { register, errors, watch, setValue }: FormGroupProps<T>): JSX.Element => {

  // Form constants
  const assetType = watch("serviceproviderinput.assetfinancingpool.assetType" as Path<T>);

  const formFields: FormField[] = [
    {
      key: "asset-financing-pool-input-asset-type",
      name: "serviceproviderinput.assetfinancingpool.assetType",
      isDropdown: true,
      title: "Asset Type",
      placeholder: "Select an asset type",
      required: true,
      className: "mf-input-field md:min-w-[150px]",
      dropdownOptions: [
        { text: "Inventory", value: AssetType.Inventory },
        { text: "Receivable", value: AssetType.Receivable },
      ]
    },
    {
      key: "asset-financing-pool-input-contract-cid",
      name: "serviceproviderinput.assetfinancingpool.contractCID",
      type: "text",
      title: "Contract CID",
      placeholder: "Contract CID",
      required: false,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "asset-financing-pool-input-unique-items",
      name: "serviceproviderinput.assetfinancingpool.uniqueitems",
      title: "Unique items",
      placeholder: "Unique items",
      isDropdown: true,
      required: assetType === AssetType.Inventory ? true : false,
      className: "mf-input-field md:min-w-[150px]",
      dropdownOptions: [
        { text: "Yes", value: true },
        { text: "No", value: false }
      ],
      visibility: [AssetType.Inventory]
    },
    {
      key: "asset-financing-pool-input-ceiling",
      name: "serviceproviderinput.assetfinancingpool.ceiling",
      type: "number",
      title: "Ceiling (BPS)",
      placeholder: "Ceiling (BPS)",
      required: assetType === AssetType.Inventory ? true : false,
      min: 0,
      className: "mf-input-field md:min-w-[150px]",
      visibility: [AssetType.Inventory]
    },
    {
      key: "asset-financing-pool-input-floor",
      name: "serviceproviderinput.assetfinancingpool.floor",
      type: "number",
      title: "Floor (BPS)",
      placeholder: "Floor (BPS)",
      required: assetType === AssetType.Inventory ? true : false,
      min: 0,
      className: "mf-input-field md:min-w-[150px]",
      visibility: [AssetType.Inventory]
    },
    {
      key: "asset-financing-pool-input-financing-limit",
      name: "serviceproviderinput.assetfinancingpool.financinglimit",
      type: "number",
      title: "Financing limit",
      placeholder: "Financing limit",
      required: true,
      min: 0,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "asset-financing-pool-input-interest",
      name: "serviceproviderinput.assetfinancingpool.interest",
      type: "number",
      title: "Interest (BPS)",
      placeholder: "Interest (BPS)",
      required: false,
      min: 0,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "asset-financing-pool-input-payout-period",
      name: "serviceproviderinput.assetfinancingpool.rewardpayoutperiod",
      type: "number",
      title: "Reward payout period",
      placeholder: "Reward payout period",
      required: false,
      min: 1,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "asset-financing-pool-input-days-locked",
      name: "serviceproviderinput.assetfinancingpool.dayslocked",
      type: "number",
      title: "Days locked",
      placeholder: "Days locked",
      required: false,
      min: 1,
      className: "mf-input-field md:min-w-[150px]",
    },
  ];

  // Filter fields based on visibility
  const visibleFields = formFields.filter((field) => {
    // If the field has no visibility property, always show it
    if (!field.visibility) return true;

    // Show the field only if the current assetType is in its visibility array
    return field.visibility.includes(assetType);
  });

  /**
   * Clear the inventory fields when the asset type is changed to receivable
   */
  useEffect(() => {
    if(assetType === AssetType.Receivable) {
      const keysToClear = [
        "serviceproviderinput.assetfinancingpool.uniqueitems",
        "serviceproviderinput.assetfinancingpool.ceiling",
        "serviceproviderinput.assetfinancingpool.floor"
      ] as Path<T>[];
      keysToClear.forEach((key) => setValue && setValue(key, undefined as PathValue<T, Path<T>>));
    }
  }, [assetType]);

  return (
    <FormGroupWrapper
      title="Asset Financing Pool"
      register={register}
      errors={errors}
      watch={watch}
      formFields={visibleFields}
    />
  );
};

export default AssetFinancingPool;
