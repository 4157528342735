/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { InvestmentTransactionModals, TxResult } from "@/constants/enums";
import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals/BlankModal";
import { FramerButton, ServiceProviderDetails, TextInfoGroupAlt } from "@/components/shared";
import { cacheEURMBalance } from "@/cache/Mutations";
import { deposit } from "@/blockchain";
import { formatNumberLocale, getContractURL } from "@/utils/helpers";
import { useTranslation } from "react-i18next";
import { useAddInvestmentMutationHook } from "@/hooks/mutations";
import { useCompanyData } from "@/hooks";
import { useIntl } from "react-intl";
import { propsInvestmentTransactionSummaryModal } from "../props";
import { Document, Page, pdfjs } from "react-pdf";
import { useEffect, useRef, useState } from "react";

// Importing CSS for react-pdf
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { handleBlockchainExplorerLink } from "@/blockchain/helpers";
import { useGetOffChainCodedTermsQuery } from "@/graphql/__generated__/graphql-operations";

// Importing worker for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

function InvestmentTransactionSummaryModal(props: propsInvestmentTransactionSummaryModal) {
  const [numPages, setNumPages] = useState(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  const { data } = useGetOffChainCodedTermsQuery({
    fetchPolicy: "network-only",
  });
  const offChainCodedTerms = data?.getOffChainCodedTerms;

  // Props
  const {
    state,
    assetFinancingPool,
    setShowModal,
    setTransactionHash,
    setResultModalType
  } = props;

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["modals"]);
  const company = useCompanyData();
  const { addInvestmentMutation } = useAddInvestmentMutationHook({financierID: company?._id as string});

  // Handle the investment into a Service Provider
  async function handleMakeInvestmentClick() {
    if (!state || !assetFinancingPool) return;

    //  show pending modal
    setShowModal(InvestmentTransactionModals.Loading);

    const [success, transactionHash] = await deposit(company!.publickey, state.pooladdress, state.amount);
    // If transaction fails
    if(!success) {
      setResultModalType(TxResult.ERROR);
      // Modals logic
      setShowModal(InvestmentTransactionModals.InvestmentTransactionResult);
      // Store transaction hash if available --> depends on type of error
      setTransactionHash(transactionHash);
      return;
    }

    // If transaction passess
    setResultModalType(TxResult.SUCCESS);
    // Store transaction hash
    setTransactionHash(transactionHash);
    // Modals logic
    setShowModal(InvestmentTransactionModals.InvestmentTransactionResult);

    addInvestmentMutation({
      variables: {
        id: company?._id as string,
        investmentInput: {
          amount: state.amount,
          assetfinancingpoolID: assetFinancingPool._id
        }
      }
    }).then(() => {
      cacheEURMBalance(company!.publickey);
    });
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleScroll = () => {
    const container = pdfContainerRef.current;
    if (container && !isScrolledToBottom) {
      const isAtBottom = container.scrollTop + container.clientHeight >= container.scrollHeight;
      if (isAtBottom) {
        setIsScrolledToBottom(true);
      }
    }
  };

  useEffect(() => {
    const container = pdfContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isScrolledToBottom]);

  return (
    <BlankModal
      setShowModal={() => { setShowModal(InvestmentTransactionModals.NoModal); }}
      className="px-6"
      customContentCSS="mf-modal-content w-[80vw] h-[90vh]"
      title={t("investmenttransactionsummary.title")}
      titleCSS="text-lg dark:text-white"
    >
      <BlankModalContent>
        <div className="flex flex-col lg:flex-row gap-4 dark:text-white">
          <div className="flex flex-col gap-4 lg:w-[50%]">
            {/* Subtext */}
            <div>
              <p>
                {t("investmenttransactionsummary.textbefore")}
                <span className="pl-1 font-semibold">
                  {formatNumberLocale(intl, state.amount, "currency")}
                </span> {t("investmenttransactionsummary.textafter")}:
              </p>
            </div>

            {/* SP Details */}
            <ServiceProviderDetails
              serviceProviderId={assetFinancingPool!.spdetails._id}
            />

            {/* Disclaimer */}
            <div className="rounded-md bg-mfpurple p-4 text-sm">
              <p className="text-white font-semibold">
                {t("investmenttransactionsummary.note.title")}:
              </p>
              <p className="text-white">
                {t("investmenttransactionsummary.note.text")}
              </p>
            </div>

            <div className="flex flex-col gap-2">
              <div className="font-semibold">{t("investmenttransactionsummary.contract.title")}</div>

              <div className="text-sm">
                {t("investmenttransactionsummary.contract.subtext")}
              </div>

              <TextInfoGroupAlt
                isLink
                label={t("investmenttransactionsummary.contract.contract")}
                contentCSS="break-all"
                linkText="View"
                content={getContractURL(assetFinancingPool?.contractCID ?? "")}
              />

              <TextInfoGroupAlt
                isLink
                label={t("investmenttransactionsummary.contract.afpaddress")}
                linkText={assetFinancingPool?.pooladdress as string}
                content={handleBlockchainExplorerLink("address", assetFinancingPool?.pooladdress as string)}
              />

              <TextInfoGroupAlt
                isLink
                label={t("investmenttransactionsummary.contract.offchaincode")}
                linkText="Download files"
                content={offChainCodedTerms}
              />

              <div className="text-sm">
                {t("investmenttransactionsummary.contract.acknowledge")}
              </div>
            </div>
          </div>

          {/* Contract */}
          <div className="flex flex-col gap-2 lg:w-[60%]">
            <div className="font-semibold">{t("investmenttransactionsummary.contract.contract")}</div>

            <div
              ref={pdfContainerRef}
              className="overflow-y-scroll h-[65vh] border border-gray-300 dark:border-gray-700"
            >
              <Document
                file={getContractURL(assetFinancingPool?.contractCID ?? "")}
                onLoadSuccess={onDocumentLoadSuccess}
                externalLinkRel="noreferrerer"
                externalLinkTarget="_blank"
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div>

            <div>
              <a
                className="text-sm text-blue-400"
                href={getContractURL(assetFinancingPool?.contractCID ?? "")}
                rel="noreferrer"
                target="_blank"
              >
                {t("investmenttransactionsummary.contract.viewexternal")}
              </a>
            </div>
          </div>
        </div>
      </BlankModalContent>

      <BlankModalFooter>
        {/* Buttons */}
        <div className="flex items-center justify-between">
          <FramerButton
            onClick={() => {
              setShowModal(InvestmentTransactionModals.MakeInvestment);
            }}
            className="mf-btn-action-small-primary-outline"
          >
            {t("common:buttons.back")}
          </FramerButton>
          <FramerButton
            disabled={!isScrolledToBottom}
            onClick={() => handleMakeInvestmentClick()}
            className={!isScrolledToBottom ? "mf-btn-action-small-disabled" : "mf-btn-action-small-primary-filled"}
          >
            {t("common:buttons.submit")}
          </FramerButton>
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default InvestmentTransactionSummaryModal;
