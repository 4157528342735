import { FormField } from "@/types";
import { FieldValues } from "react-hook-form";
import { FormGroupProps } from "../props";
import FormGroupWrapper from "./FormGroupWrapper";

/**
 * Component for rendering the form fields for company details.
 *
 * @template T - Type parameter for FieldValues.
 * @param {Props<T>} props - The props containing register, errors, and watch functions.
 * @param {UseFormRegister<T>} props.register - The register function from react-hook-form.
 * @param {UseFormStateReturn<T>["errors"]} props.errors - The errors object from react-hook-form.
 * @param {UseFormWatch<T>} props.watch - The watch function from react-hook-form.
 * @returns {JSX.Element} - The rendered component.
 */
const CompanyDetails = <T extends FieldValues>( { register, errors, watch }: FormGroupProps<T>): JSX.Element => {

  const formFields: FormField[] = [
    {
      key: "company-details-input-company-number",
      name: "serviceproviderinput.companydetails.industry",
      type: "text",
      title: "Industry",
      placeholder: "Industry",
      required: false,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "company-details-input-vat-number",
      name: "serviceproviderinput.companydetails.description",
      type: "text",
      isMultiline: true,
      title: "Description",
      placeholder: "Description",
      required: false,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "company-details-input-legal-form",
      name: "serviceproviderinput.companydetails.yearlyrevenue",
      type: "number",
      title: "Yearly revenue",
      placeholder: "Yearly revenue",
      required: false,
      className: "mf-input-field md:min-w-[150px]",
    },
    {
      key: "company-details-input-website",
      name: "serviceproviderinput.companydetails.foundingyear",
      type: "number",
      title: "Founding year",
      placeholder: "Founding year",
      required: false,
      className: "mf-input-field md:min-w-[150px]",
    },
  ];

  return (
    <FormGroupWrapper
      title="Company Details"
      register={register}
      errors={errors}
      watch={watch}
      formFields={formFields}
    />
  );
};

export default CompanyDetails;
