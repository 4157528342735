import { motion } from "framer-motion";

type RadioOption = {
  /**
   * The label to display for the radio button option.
   */
  label: string;
  /**
   * The value of the radio button option. Can be a string, boolean, or number.
   */
  value: string | boolean | number;
}

type RadioButtonProps = {
  /**
   * The radio button option to display.
   */
  option: RadioOption;
  /**
   * Boolean indicating whether the radio button is checked.
   */
  checked: boolean;
  /**
   * Callback function triggered when the radio button value changes.
   *
   * @param _value - The new value of the radio button.
   */
  onChange: (_value: string | boolean | number) => void;
}

/**
 * A radio button component that allows the user to select an option.
 *
 * @param {RadioButtonProps} props - The properties for the radio button component.
 * @returns {JSX.Element} The rendered radio button component.
 *
 * @example
 * ```tsx
 * import { useState } from "react";
 * import RadioButton from "./RadioButton";
 *
 * function MyComponent() {
 *   const [selectedValue, setSelectedValue] = useState<string | boolean | number>("");
 *
 *   const handleChange = (value: string | boolean | number) => {
 *     setSelectedValue(value);
 *   };
 *
 *   return (
 *     <div>
 *       <RadioButton
 *         option={{ label: "Option 1", value: "option1" }}
 *         checked={selectedValue === "option1"}
 *         onChange={handleChange}
 *       />
 *       <RadioButton
 *         option={{ label: "Option 2", value: "option2" }}
 *         checked={selectedValue === "option2"}
 *         onChange={handleChange}
 *       />
 *     </div>
 *   );
 * }
 * ```
 */
function RadioButton({
  option,
  checked,
  onChange
}: RadioButtonProps): JSX.Element {
  return (
    <div data-cy="radiobutton">
      <label data-cy="radiobutton-label" className="cursor-pointer">
        <motion.input
          whileHover={{
            opacity: 0.7,
          }}
          whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
          data-cy="radiobutton-input"
          className="appearance-none ring-1 rounded-full w-3 h-3 ring-gray-300 mr-2
                    checked:bg-mforange checked:ring-mforange
                    cursor-pointer"
          type="radio"
          value={option.value.toString()}
          checked={checked}
          onChange={() => onChange(option.value)}
        />
        {option.label}
      </label>
    </div>
  );
}

export default RadioButton;
