import { BlankModal, BlankModalContent } from "@/components/modals";
import { SetWalletSetupModals } from "@/constants/enums";
import { useWeb3 } from "@/context/Web3Context";
import { CompanyDetailsFragment } from "@/graphql/__generated__/graphql-operations";
import { useEditCompanyMutationHook } from "@/hooks/mutations";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import myronLogo from "@/assets/MYRON-icon-transparent.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { FramerButton } from "@/components/shared";

type Props = {
  setShowModal: Dispatch<SetStateAction<SetWalletSetupModals>>;
  company: CompanyDetailsFragment | null;
}

function SetWalletPublicKeyModal({ setShowModal, company }: Props) {

  // Hooks
  const { wallet } = useWeb3();
  const { t } = useTranslation(["modals"]);

  const { editCompany } = useEditCompanyMutationHook();

  // Save wallet address to database
  function handleSubmit() {
    if (wallet) {
      editCompany({
        id: company!._id,
        companyInput: {
          publickey: wallet
        }}, {}, "PublicKey", wallet
      ).finally(() => setShowModal(SetWalletSetupModals.NoModal));
    }
  }

  return (
    <BlankModal
      setShowModal={() => {setShowModal(SetWalletSetupModals.NoModal);}}
      className="md:px-10 lg:px-40 xl:px-72"
      doNotShowCloseIcon
      doNotShowTopContainer
    >
      <BlankModalContent>
        {/* Image container */}
        <div className="flex justify-center mb-8 py-8">
          <LazyLoadImage
            alt={"Myron logo"}
            className="h-16"
            src={myronLogo}
            effect="opacity"
          />
        </div>

        {/* Content container */}
        <div className="mb-4">
          <h1 className="font-bold text-xl mb-4 text-green-400">{t("initialisepublickey.publickey.title")}</h1>
          <p>{t("initialisepublickey.publickey.label")}</p>
          <div className="rounded-sm px-4 py-2 bg-gray-100 dark:bg-mfdarklighter mb-4">
            <p className="text-gray-600 dark:text-gray-200 font-semibold break-all">{wallet}</p>
          </div>
          <p>{t("initialisepublickey.publickey.text")}</p>
        </div>

        {/* Buttons container */}
        <div className="flex justify-end">
          <FramerButton
            onClick={handleSubmit}
            className="mf-btn-action-large-primary-filled"
          >
            {t("common:buttons.submit")}
          </FramerButton>
        </div>

        {/* Stepper */}
        <div className="flex justify-center mt-8">
          <div className="flex items-center">
            <div className="h-2 w-2 bg-gray-300 rounded-full" />
            <div className="h-2 w-2 bg-gray-300 rounded-full mx-2" />
            <div className="h-2 w-2 bg-mforange rounded-full" />
          </div>
        </div>
      </BlankModalContent>
    </BlankModal>
  );
}

export default SetWalletPublicKeyModal;
