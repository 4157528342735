import { FramerButton, TwInput } from "@/components/shared";
import { useForgotPasswordMutationHook } from "@/hooks/mutations";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { PreloginLayout, PreloginLayoutContent, PreloginLayoutFooter} from "@/layouts/PreloginLayout";

function useCountdown(initialTime: number) {
  const [remainingTime, setRemainingTime] = useState(initialTime);

  useEffect(() => {
    if (remainingTime > 0) {
      const timerId = setTimeout(() => {
        setRemainingTime(remainingTime - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    }
  }, [remainingTime]);

  return [remainingTime, setRemainingTime] as const;
}

function ForgotPasswordComponent() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);
  const [canSend, setCanSend] = useState(true);
  const [email, setEmail] = useState("");

  const [remainingTime, setRemainingTime] = useCountdown(0);

  const navigate = useNavigate();
  const { forgotPasswordMutation } = useForgotPasswordMutationHook();

  useEffect(() => {
    if (remainingTime === 0 && !canSend) {
      setCanSend(true);
    }
  }, [remainingTime, canSend]);

  function handleFormSubmit(event: React.FormEvent) {
    event.preventDefault();
    setCanSend(false);
    setRemainingTime(120); // 2 minutes

    // fire the mutation if can send and email valid
    if (canSend && email !== "") {
      setLoading(true);

      forgotPasswordMutation({ variables: { mail: email } })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    // get the updated user input
    const { value, isValid } = event;
    if(value === "") {
      setEmail("");
      setHasErrors(true);
    }
    else if(isValid) {
      setEmail(value);
      setHasErrors(false);
    }
    else {
      setHasErrors(true);
    }
  }

  return (
    <PreloginLayout>
      {/* Form Container */}
      <PreloginLayoutContent>
        {/* Header wrapper */}
        <div className="mb-4">
          <p className="text-xl md:text-2xl font-semibold mb-1">Request Password Reset</p>
          <p className="text-sm md:text-base">Please provide the email address associated with your account below to receive a password reset link.</p>
        </div>
        <form
          className="flex flex-col gap-2"
          onSubmit={handleFormSubmit}
          id="loginForm"
          noValidate
        >
          <TwInput
            className="mf-input-field text-gray-800"
            required
            onChange={handleOnChange}
            placeholder="Enter your email address"
            type="email"
            name="mail"
            label="Enter your email address"
            errorMessage="Email is invalid"
            labelPlacement="stacked"
            autoFocus
            useErrorText
          />
          {
            !canSend &&
              <span>
                Send again in&nbsp;
                <span className="font-semibold">
                  {Math.floor(remainingTime / 60)}:{(remainingTime % 60).toString().padStart(2, "0")}
                </span>
              </span>
          }

          <FramerButton
            className={`
              rounded-sm mf-primary text-white mf-button w-full
              ${loading ? "bg-opacity-50 cursor-wait" : ""}
              ${hasErrors || !canSend ? "cursor-not-allowed bg-gray-400" : ""}
            `}
            type={hasErrors ? "button" : "submit"}
            disabled={hasErrors || !canSend}
          >
            {
              loading ?
                <div className="flex justify-center items-center">
                  <div className="w-4 h-4 border-2 border-gray-400 border-t-white mr-2 rounded-full animate-spin" />
                  Sending
                </div>
                : <>Send Reset Link</>
            }
          </FramerButton>

          {/* Forgot password */}
          <p className="mt-4 text-sm md:text-base">
            <span onClick={() => {navigate("/login");}} className="text-blue-400 cursor-pointer">Back to Login</span>
          </p>
        </form>
      </PreloginLayoutContent>

      {/* Request demo */}
      <PreloginLayoutFooter>
        <h1 className="text-base md:text-lg font-semibold mb-2">Don&apos;t have an account?</h1>
        <a href="https://calendly.com/myron-finance/myron-demo"
          target="_blank" rel="noreferrer"
          className="inline-block rounded-sm mf-secondary text-white mf-button hover:bg-opacity-70"
        >
          Request Demo
        </a>
      </PreloginLayoutFooter>
    </PreloginLayout>
  );
}

export default ForgotPasswordComponent;
