import { nonEmptyString } from "@/utils/form";
import { z } from "zod";

// Define the Zod schema
export const BankDetailsSchema = z.object({
  beneficiaryID: z.string().uuid("Beneficiary ID must be a valid UUID"), // Using Zod's built-in UUID validation
  iban: nonEmptyString("IBAN").regex(
    /^[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}$/, // Validate IBAN using regex
    "Invalid IBAN"
  ),
  bic: nonEmptyString("BIC").regex(
    /^[A-Z]{6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3})?$/, // Validate BIC using regex
    "Invalid BIC"
  ),
  name: nonEmptyString("Bank name"), // Simple non-empty string validation
});

// Default values for BankDetails
export const BankDetailsDefaultValues = {
  beneficiaryID: "",
  iban: "",
  bic: "",
  name: "",
};

// Infer the TypeScript type from the Zod schema
export type TBankDetailsSchema = z.infer<typeof BankDetailsSchema>;
