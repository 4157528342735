import { FormInput } from "@/components/shared";
import { useUserData } from "@/hooks";
import { useEditPasswordMutationHook } from "@/hooks/mutations";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import FormWrapper from "./FormWrapper";
import { PasswordDefaultValues, PasswordSchema, TPasswordSchema } from "@/models";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormField } from "@/types";

function Password() {

  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<TPasswordSchema>({
    resolver: zodResolver(PasswordSchema),
    defaultValues: PasswordDefaultValues,
    mode: "onChange",
  });

  // Hooks
  const user = useUserData();
  const { t } = useTranslation();

  // Mutation hooks
  const { editPasswordMutation } = useEditPasswordMutationHook();

  /**
  * Handler for change password form submit event
  * @param {TPasswordSchema} data Form data
  */
  const onSubmit:SubmitHandler<TPasswordSchema> = (data: TPasswordSchema) => {

    if(!user || !isValid) {
      return toast.error("Unable to submit data", { toastId: "addressSubmitError"});
    }

    if(isDirty) {
      try {
        editPasswordMutation(
          {
            variables: {
              id: user._id,
              passwordInput: {
                currentPassword: data?.password as string,
                newPassword: data?.newPassword as string,
              },
            }
          }
        );
      }
      catch (err) {
        toast.error(err.message, { toastId: "editPasswordError" });
      }
      finally {
        reset();
      }
    }
  };

  const formFields: FormField[] = [
    {
      title: "Current Password",
      type: "password",
      required: true,
      name: "password",
      className: "mf-input-field",
      placeholder: t("settings.password.currentpassword.placeholder"),
      autoComplete: "current-password"
    },
    {
      title: "New Password",
      type: "password",
      required: true,
      name: "newPassword",
      className: "mf-input-field",
      placeholder: t("settings.password.newpassword.placeholder"),
      usePasswordRequirements: true,
      autoComplete: "new-password"
    },
    {
      title: "Confirm New Password",
      type: "password",
      required: true,
      name: "confirmNewPassword",
      className: "mf-input-field",
      placeholder: t("settings.password.confirmpassword.placeholder"),
      autoComplete: ""
    },
  ];

  return (
    <div>
      <div className="mb-4 select-none">
        <h2 className="font-semibold text-lg mb-2">{t("settings.password.title")}</h2>
        <p>{t("settings.password.subtitle")}</p>
      </div>

      <FormWrapper
        id="password-form"
        onClickReset={() => reset()}
        handleSubmit={handleSubmit(onSubmit)}
      >
        <>
          <div className="w-full md:w-1/2 md:pr-2">
            <FormInput
              field={formFields[0]}
              register={register}
              errors={errors}
            />
          </div>

          <div className="flex flex-col md:flex-row justify-between md:gap-4">
            {formFields.slice(1).map((field) => (
              <div key={field.name} className="w-full">
                <FormInput
                  field={field}
                  register={register}
                  errors={errors}
                  watch={watch}
                  usePasswordRequirements={field.usePasswordRequirements}
                />
              </div>
            ))}
          </div>
        </>
      </FormWrapper>
    </div>
  );
}

export default Password;
