import { nonEmptyString } from "@/utils/form";
import { z } from "zod";

export const AddressSchema = z.object({
  street: nonEmptyString("Street"),
  housenumber: nonEmptyString("House number", 1),
  zipcode: nonEmptyString("Zip code"),
  city: nonEmptyString("City"),
  country: nonEmptyString("Country"),
});

export const AddressDefaultValues = {
  street: "",
  housenumber: "",
  zipcode: "",
  city: "",
  country: "",
};

export type TAddressSchema = z.infer<typeof AddressSchema>;
