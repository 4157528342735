import { z } from "zod";
import { CompanyType } from "@/graphql/__generated__/graphql-operations";

// Define regex patterns
const phoneRegex = /^[+]?[0-9]{10,13}$/; // Phone number: allows 10-13 digits with an optional "+" prefix
const vatNumberRegex = /^[A-Z]{2}[0-9]{9}$/; // VAT number: must be two uppercase letters followed by 9 digits

// General Information Schema
export const GeneralInformationSchema = z.object({
  companytype: z.nativeEnum(CompanyType, {
    errorMap: () => ({ message: "Company type is invalid" })
  }),
  name: z.string().min(1, "Company name is invalid"),
  phone: z.string().regex(phoneRegex, "Phone number is invalid, example +1234567890"), // Enforcing phone number pattern
  vatnumber: z.string().regex(vatNumberRegex, "VAT number is invalid, example VA123456789"), // Enforcing VAT number pattern
});

export const GeneralInformationDefaultValues = {
  companytype: "",
  name: "",
  phone: "",
  vatnumber: "",
};

export type TGeneralInformationSchema = z.infer<typeof GeneralInformationSchema>;
