import { useCreateCompanyMutationHook } from "@/hooks/mutations";
import { TCreateCompanySchema, CreateCompanySchema, CreateCompanyDefaultValues } from "@/models";
import { onInvalid } from "@/utils/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import Address from "./FormGroups/Address";
import AssetDocumentary from "./FormGroups/AssetDocumentary";
import AssetFinancingPool from "./FormGroups/AssetFinancingPool";
import BankDetails from "./FormGroups/BankDetails";
import GeneralInformation from "./FormGroups/GeneralInformation";
import InvoiceDocumentary from "./FormGroups/InvoiceDocumentary";
import WebPresence from "./FormGroups/WebPresence";
import { CompanyType } from "@/graphql/__generated__/graphql-operations";
import CompanyDetails from "./FormGroups/CompanyDetails";
import { useEffect } from "react";
import { FramerButton } from "@/components/shared";

function CreateCompany() {

  // Init form
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<TCreateCompanySchema>({
    resolver: zodResolver(CreateCompanySchema),
    defaultValues: CreateCompanyDefaultValues,
    mode: "onChange",
  });

  const { createCompanyMutation } = useCreateCompanyMutationHook(reset);

  // Form constants
  const companyType = watch("companytype");

  // Reset `serviceproviderinput` when companytype changes to Financier
  useEffect(() => {
    if(companyType === CompanyType.Financier) {
      setValue("serviceproviderinput", undefined);
    }
  }, [companyType]); // watch for changes in companyType

  /**
   * OnSubmit handler for create company form
   * @param {TCreateCompanySchema} data
   */
  const onSubmit: SubmitHandler<TCreateCompanySchema> = (data: TCreateCompanySchema) => {
    // Destructure data
    const { serviceproviderinput, ...restData } = data;
    if(isDirty) {
      try {
        const companyInput = companyType === CompanyType.Financier ? restData : { ...restData, serviceproviderinput };
        createCompanyMutation({
          variables: {
            companyInput
          }
        });
      }
      catch (error) {
        return console.log("Error creating company", error);
      }
    }
  };

  // Share props
  const formGroupProps = {
    register,
    errors,
    watch,
    setValue
  };

  return (
    <div className="mb-[8rem]">
      <form
        id="create-company-form"
        onSubmit={handleSubmit(onSubmit, onInvalid)}
      >
        {/* Form fields container */}
        <div className="mb-4 mx-4 md:mx-0 mt-6 mf-create-company-main-container">
          {/* Both Financier and SP */}
          <>
            {/* General information form */}
            <GeneralInformation {...formGroupProps} />
            {/* Address */}
            <Address {...formGroupProps} />
            {/* Bank Details */}
            <BankDetails {...formGroupProps} />
          </>

          {/* SP Only */}
          {
            companyType === CompanyType.Serviceprovider && (
              <>
                {/* Company Details */}
                <CompanyDetails {...formGroupProps} />
                {/* Web Presence */}
                <WebPresence {...formGroupProps} />
                {/* Asset Financing Pool */}
                <AssetFinancingPool {...formGroupProps} />
                {/* Asset Documentary */}
                <AssetDocumentary {...formGroupProps} />
                {/* Invoice Documentary */}
                <InvoiceDocumentary {...formGroupProps} />
              </>
            )
          }
        </div>
        <div className="flex justify-end">
          <FramerButton
            type="submit"
            className="mf-btn-action-large-primary-filled"
          >
            Submit
          </FramerButton>
        </div>
      </form>
    </div>
  );
}

export default CreateCompany;
