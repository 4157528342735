import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { FormInput, FormSelect, FramerButton } from "@/components/shared";
import { handleTableCardTagColour } from "@/components/shared/table/utils";
import { DeployPageModals } from "@/constants/enums";
import { AssetFinancingPool, AssetFinancingPoolFragment, AssetFinancingStatus, AssetType } from "@/graphql/__generated__/graphql-operations";
import { useServiceProviderData } from "@/hooks";
import { useDeployAssetFinancingPoolContractMutationHook, useEditAssetFinancingPoolMutationHook, useAddAssetFinancingPoolMutationHook } from "@/hooks/mutations";
import { TAssetFinancingPoolSchema, AssetFinancingPoolSchema, AssetFinancingPoolDefaultValues } from "@/models";
import { calculateClosingDate } from "@/pages/Portolio/utils";
import { FormField } from "@/types";
import { onInvalid } from "@/utils/form";
import { convertBpToPercentage, formatDateLocale, formatDaysString, formatNumberLocale, toPascalCase } from "@/utils/helpers";
import { zodResolver } from "@hookform/resolvers/zod";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

type Props = {
  currentSpId: string;
  setShowModal: Dispatch<SetStateAction<DeployPageModals>>;
}

/**
 * Modal component for displaying and editing AFP details of a Service Provider.
 *
 * @component
 * @example
 * ```tsx
 * import { ServiceProviderDetailsModal } from "@/pages/DeploySmartContracts/modals";
 *
 * const [showModal, setShowModal] = useState<DeployPageModals>(DeployPageModals.NoModal);
 *
 * return (
 *  {showModal === DeployPageModals.ServiceProviderDetailsModal && (
 *    <ServiceProviderDetailsModal
 *      setShowModal={setShowModal}
 *      currentSpId={currentSpId}
 *    />)
 *  }
 * )
 * ```
 *
 * @param {Object} props - The component props.
 * @param {Dispatch<SetState<DeployPageModals>>} props.setShowModal - Function to control the visibility of the modal.
 * @param {string} props.currentSpId - The ID of the current service provider.
 * @returns {JSX.Element} The rendered ServiceProviderDetailsModal component.
 */
function ServiceProviderDetailsModal(props: Props): JSX.Element {

  // Init form
  const {
    handleSubmit,
    register,
    // reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<TAssetFinancingPoolSchema>({
    resolver: zodResolver(AssetFinancingPoolSchema),
    defaultValues: AssetFinancingPoolDefaultValues,
    mode: "onChange",
  });
  // Form constants
  const assetType = watch("assetType");

  // Props
  const { setShowModal, currentSpId } = props;

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation();
  const spData = useServiceProviderData(currentSpId);

  // Mutation hooks
  const { deployMutation } = useDeployAssetFinancingPoolContractMutationHook();
  const { editAssetFinancingPoolMutation }  = useEditAssetFinancingPoolMutationHook();
  const { addAssetFinancingPoolMutation } = useAddAssetFinancingPoolMutationHook();

  // Constants
  const afp = spData?.assetfinancingpool;
  const isClosed = afp?.status === AssetFinancingStatus.Closed;
  const isOpen = afp?.status === AssetFinancingStatus.Open;
  const isOnboarding = afp?.status === AssetFinancingStatus.Onboarding;
  const isActive = afp?.status === AssetFinancingStatus.Active;
  const isReceivable = afp?.assetType === AssetType.Receivable;
  const poolAddress = afp?.pooladdress;

  // States
  const [editMode, setEditMode] = useState<boolean>(false);

  // Update form value when asset type changes
  useEffect(() => {
    if(currentSpId) {
      setValue("assetType", afp?.assetType as AssetType);
    }
  }, [currentSpId]);

  /**
   * OnClick handler for Deploying smart contract
   *
   * @param {AssetFinancingPoolFragment} afp
   */
  async function handleOnDeployClick(afp: AssetFinancingPoolFragment) {
    // Prevent Deployment until AFP details has been updated in the DB
    if(afp.pooladdress !== "0x0" && afp.status === AssetFinancingStatus.Closed) {
      return null;
    }

    if(currentSpId !== "" ){
      toast.loading(t("toasts:deployLoadingToast"), {toastId: "deployLoadingToast"});
      await deployMutation(afp._id, currentSpId);
    }
    toast.dismiss("deployLoadingToast");
  }

  /**
   * OnSubmit handler for form
   * @param {TAssetFinancingPoolSchema} data
   */
  const onSubmit: SubmitHandler<TAssetFinancingPoolSchema> = (data: TAssetFinancingPoolSchema) => {
    toast.loading("Submitting data", {toastId: "editLoadingToast"});
    // Remove Inventory only fields incase user has changed type
    if(isReceivable) {
      setValue("ceiling", undefined);
      setValue("floor", undefined);
      setValue("uniqueitems", undefined);
    }
    try {
      // If AFC is closed, add new AFC
      if (isClosed) {
        addAssetFinancingPoolMutation({
          variables: {
            id: currentSpId,
            assetFinancingPoolInput: data
          }
        });
      }
      // Edit existing AFC if it is onboarding
      else {
        editAssetFinancingPoolMutation({
          variables: {
            id: currentSpId,
            assetFinancingPoolInput: data
          }
        });
      }
    }
    catch (error) {
      console.log("Error creating company", error);
    }
    finally {
      // Close loading toast and set edit mode to false
      toast.dismiss("editLoadingToast");
      setEditMode(false);
    }
  };

  const dateFields = [
    {
      label: t("deployafp.labels.amountinvested"),
      value: formatNumberLocale(intl, afp?.amountinvested as number, "currency"),
    },
    {
      label: "Activation date",
      value: isOpen || isOnboarding ? "Not available": formatDateLocale(intl, afp?.activationdate ?? null),
    },
    {
      label: "Closing date",
      value: calculateClosingDate(intl, afp?.activationdate, afp?.dayslocked as number),
    },
  ];

  const formFields: FormField[] = [
    {
      key: "asset-financing-pool-input-financing-limit",
      name: "financinglimit",
      type: "number",
      title: "Financing limit",
      placeholder: "Financing limit",
      required: true,
      min: 0,
      className: "mf-input-field",
      displayValue: formatNumberLocale(intl, afp?.financinglimit as number, "currency"),
      defaultValue: afp?.financinglimit,
    },
    {
      key: "asset-financing-pool-input-contract-cid",
      name: "contractCID",
      type: "text",
      title: "Contract CID",
      placeholder: "Contract CID",
      required: false,
      className: "mf-input-field md:min-w-[150px]",
      displayValue: afp?.contractCID ?? "Not available",
      defaultValue: afp?.contractCID ?? "",
    },
    {
      key: "asset-financing-pool-input-unique-items",
      name: "uniqueitems",
      title: "Unique items",
      placeholder: "Unique items",
      isDropdown: true,
      required: assetType === AssetType.Inventory ? true : false,
      className: "mf-input-field",
      dropdownOptions: [
        { text: "Yes", value: true },
        { text: "No", value: false }
      ],
      visibility: [AssetType.Inventory],
      displayValue: afp?.uniqueitems ? "Yes" : "No",
      defaultValue: afp?.uniqueitems ? "Yes" : "No",
    },
    {
      key: "asset-financing-pool-input-ceiling",
      name: "ceiling",
      type: "number",
      title: "Ceiling (BPS)",
      placeholder: "Ceiling (BPS)",
      required: assetType === AssetType.Inventory ? true : false,
      min: 0,
      className: "mf-input-field",
      visibility: [AssetType.Inventory],
      displayValue: formatNumberLocale(intl, convertBpToPercentage(afp?.ceiling ?? 0), "percent"),
      defaultValue: afp?.ceiling ?? "",
    },
    {
      key: "asset-financing-pool-input-floor",
      name: "floor",
      type: "number",
      title: "Floor (BPS)",
      placeholder: "Floor (BPS)",
      required: assetType === AssetType.Inventory ? true : false,
      min: 0,
      className: "mf-input-field",
      visibility: [AssetType.Inventory],
      displayValue: formatNumberLocale(intl, convertBpToPercentage(afp?.floor ?? 0), "percent"),
      defaultValue: afp?.floor ?? "",
    },
    {
      key: "asset-financing-pool-input-interest",
      name: "interest",
      type: "number",
      title: "Interest (BPS)",
      placeholder: "Interest (BPS)",
      required: false,
      min: 0,
      className: "mf-input-field",
      displayValue: (convertBpToPercentage(afp?.interest ?? 0)*100).toFixed(2)+"%",
      defaultValue: afp?.interest ?? "",
    },
    {
      key: "asset-financing-pool-input-payout-period",
      name: "rewardpayoutperiod",
      type: "number",
      title: "Reward payout period",
      placeholder: "Reward payout period",
      required: false,
      min: 1,
      className: "mf-input-field",
      displayValue: `Every ${afp?.rewardpayoutperiod} days`,
      defaultValue: afp?.rewardpayoutperiod ?? "",
    },
    {
      key: "asset-financing-pool-input-days-locked",
      name: "dayslocked",
      type: "number",
      title: "Days locked",
      placeholder: "Days locked",
      required: false,
      min: 1,
      className: "mf-input-field",
      displayValue: formatDaysString(afp?.dayslocked as number),
      defaultValue: afp?.dayslocked ?? "",
    },
  ];

  return (
    <BlankModal
      setShowModal={() => {
        setShowModal(DeployPageModals.NoModal);
      }}
      title={spData?.name}
      titleCSS="text-lg font-semibold"
      className="px-6"
      customContentCSS="mf-modal-content max-md:w-full md:min-w-[400px] lg:min-w-[580px]"
    >
      <BlankModalContent>
        <div className="md: max-h-[30%]">
          {/* Top container */}
          <div className="flex items-center justify-between -mt-2">
            {/* Tags */}
            <div className="flex items-center gap-2">
              <div className={`rounded-md text-sm border-[1px] p-1 select-none ${handleTableCardTagColour(afp?.status as string, "Company")}`}>
                {toPascalCase(afp?.status as string)}
              </div>
              <div className={`rounded-md text-sm border-[1px] p-1 select-none ${handleTableCardTagColour(afp?.assetType as string, "")}`}>
                {toPascalCase(afp?.assetType as string)}
              </div>
            </div>
          </div>

          {/* Addresses */}
          <div className="my-2">
            <div>
              <p className="text-sm dark:text-gray-400">Pool Address</p>
              <p className="break-all">{afp?.pooladdress}</p>
            </div>
            <div>
              <p className="text-sm dark:text-gray-400">Token Address</p>
              <p className="break-all">{afp?.tokenaddress}</p>
            </div>
          </div>

          {/* AFP Details Form */}
          <form onSubmit={handleSubmit(onSubmit, onInvalid)} className="space-y-2">
            {
            // Action buttons
              <div className="flex space-x-2 justify-end mb-4">
                {editMode ? (
                  <>
                    {/* Cancel button */}
                    <FramerButton
                      type="button"
                      onClick={() => setEditMode(false)}
                      className="mf-btn-action-small-primary-outline"
                    >
                      Cancel
                    </FramerButton>
                    {/* Submit button */}
                    <FramerButton
                      type="submit"
                      className="mf-btn-action-small-primary-filled"
                    >
                      Submit
                    </FramerButton>
                  </>
                ) : (
                // Edit Button
                  <FramerButton
                    type="button"
                    onClick={() => setEditMode(true)}
                    // Button is disabled if AFP is ACTIVE or OPEN
                    disabled={isActive || isOpen}
                    className={`${isActive || isOpen ? "mf-btn-action-small-disabled" : "mf-btn-action-small-primary-filled"}`}
                  >
                    Edit
                  </FramerButton>
                )}
              </div>
            }
            {dateFields.map((field) => (
              <div key={`spInfoField-${field.label}`} className="flex items-center justify-between">
                <p className="text-sm font-semibold pr-2 text-gray-800 dark:text-gray-100">{field.label}</p>
                <p className="text-sm text-gray-800 dark:text-gray-100">{field.value}</p>
              </div>
            ))}

            {/* Input fields OR text info */}
            <div className={`${editMode ? "md:flex md:flex-wrap md:gap-x-4" : "space-y-2"}`}>
              {formFields.map((field) => {
                const isFieldVisible = !field.visibility || field.visibility.includes(afp?.assetType as AssetType);
                return (
                  isFieldVisible && (
                    <div key={`sp-info-field-${field.title}`} className={`${editMode ? "max-md:m∂in-w-full md:min-w-[48%] max-w-min" : ""}`}>
                      {editMode ? (
                        <div className="w-full">
                          {field.isDropdown ? (
                            <FormSelect
                              key={field.key}
                              register={register}
                              errors={errors}
                              field={field}
                              watch={watch}
                            />
                          ) : (
                            <FormInput
                              key={field.key}
                              register={register}
                              errors={errors}
                              field={field}
                              watch={watch}
                            />
                          )}
                        </div>
                      ) : (
                        <div className="flex items-center justify-between w-full">
                          <p className="text-sm font-semibold pr-2 text-gray-800 dark:text-gray-100">{field.title}</p>
                          <p className="text-sm text-gray-800 dark:text-gray-100">{field.displayValue}</p>
                        </div>
                      )}
                    </div>
                  )
                );
              })}
            </div>
          </form>
        </div>
      </BlankModalContent>
      <BlankModalFooter>
        {
          // Only show button if AFP Status is Closed or Onboarding
          // Hide button during edit mode
          (isClosed || isOnboarding) && !editMode &&
            // Action buttons
            <div className="flex w-full items-center justify-center mt-4">
              <FramerButton
                disabled={isClosed && poolAddress !== "0x0"}
                onClick={() => handleOnDeployClick(afp as AssetFinancingPool)}
                className={`${isClosed && poolAddress !== "0x0" ?"mf-btn-action-small-disabled":"mf-btn-action-small-primary-filled"}`}
              >
                {"Deploy"}
              </FramerButton>
            </div>
        }
      </BlankModalFooter>
    </BlankModal>
  );
}

export default ServiceProviderDetailsModal;
