import { CompanyType, CreateCreditLimitRequestInput, CreditLimitRequest, CreditLimitRequestCreatedDocument, CreditLimitRequestCreatedSubscription, CreditLimitRequestEdge, GetPaginatedCreditLimitRequestsQuery, GetPaginatedCreditLimitRequestsQueryVariables, useCreditLimitRequestCreatedSubscription, useGetPaginatedCreditLimitRequestsQuery } from "@/graphql/__generated__/graphql-operations";
import { FramerButton, Pagination, PaginationContentScrollable, ScrollToTopButton } from "@/components/shared";
import { useEffect, useRef, useState } from "react";
import { CLRDetailsModal, CLRFilterComponent } from "@/pages/CreditLimitRequests";
import { CLRPageModals } from "@/constants/enums";
import useMdBreakpoint from "@/hooks/useMdBreakpoint";
import { TwTableCardList, DataTable } from "@/components/shared/table";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCompanyData, useEmployeeData } from "@/hooks";
import { CreditLimitNewRequest } from "../CustomerOverview/modals";
import { useIntl } from "react-intl";
import { getColumns } from "./columns";
import { getColumnHeaderValues } from "@/components/shared/table/utils";
import { ActionButton } from "@/components/shared/table/Table";
import useScrollToTop from "@/hooks/useScrollToTop";

interface PaginationRef {
  triggerHandleFilterSubmit: (_newVariables: Partial<GetPaginatedCreditLimitRequestsQueryVariables>) => void;
  getSubscribeToMore: any;
}

function CreditLimitRequestPage() {
  // Ref use for Scroll To Top
  const scrollRef = useRef<HTMLDivElement>(null);

  // Hooks
  const mdBreakpoint = useMdBreakpoint();
  const navigate = useNavigate();
  const company = useCompanyData();
  const user = useEmployeeData();
  const { t } = useTranslation(["tables"]);
  const intl = useIntl();
  const { isVisible, scrollToTop } = useScrollToTop({ ref: scrollRef });

  // URL params
  const [searchParams] = useSearchParams();
  const customerIDParam = searchParams.get("customerID");
  const customerNameParam = searchParams.get("customerName");

  // Pagination config
  const paginationRef = useRef<PaginationRef | null>(null);
  const paginationName = "paginatedCreditLimitRequests";

  // States
  const [showModal, setShowModal]  = useState<CLRPageModals>(CLRPageModals.NoModal);
  const [currentCLR, setCurrentCLR] = useState<CreditLimitRequest|undefined>(undefined);
  const [input, setInput] = useState<CreateCreditLimitRequestInput|{ [x: string]: string|undefined; }|null>(null);

  /**
   * Handler function for filter submit for pagination
   * @param {GetPaginatedCreditLimitRequestsQueryVariables} variables
   */
  const onFilterSubmit = (variables: GetPaginatedCreditLimitRequestsQueryVariables) => {
    // When the filter is submitted in the PageComponent,
    // this will call the handleFilterSubmit inside Pagination.
    paginationRef.current?.triggerHandleFilterSubmit(variables);
  };

  const { data: subscriptionData, loading: subscriptionLoading } = useCreditLimitRequestCreatedSubscription({});

  useEffect(() => {
    // Subscribe to updates of 'paginatedCreditLimitRequests'
    if(paginationRef.current?.getSubscribeToMore !== undefined || null){
      const subscribe = paginationRef.current?.getSubscribeToMore({
        document: CreditLimitRequestCreatedDocument,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const newRequest = (subscriptionData.data as CreditLimitRequestCreatedSubscription).creditLimitRequestCreated;
          return {
            paginatedCreditLimitRequests: {
              ...prev.paginatedCreditLimitRequests,
              edges: [
                {
                  __typename: "CreditLimitRequestEdge",
                  node: newRequest as CreditLimitRequest,
                  cursor: newRequest!.createdAt,
                },
                ...prev.paginatedCreditLimitRequests.edges,
              ],
            },
          };
        },
      });

      // Cleanup subscription on component unmount
      return () => subscribe();
    }
  }, [paginationRef.current?.getSubscribeToMore, subscriptionLoading, subscriptionData]);

  /**
   * OnClick handler for the details button
   * @param {CreditLimitRequest} clr
   */
  const handleCLRClick = (clr: CreditLimitRequest) => {
    setCurrentCLR(clr);
    setShowModal(CLRPageModals.CLRDetails);
  };

  /**
   * OnClick handler for the new request button
   */
  function handleNewRequestClick() {
    setInput({customerID: customerIDParam ?? ""});
    setShowModal(CLRPageModals.NewRequest);
  }

  /**
   * OnClick handler for back button
   */
  const handleBackClick = () => {
    // Reset the filter
    onFilterSubmit({first: 10, customerID: null, status: null });
    // Go back to the previous page
    navigate(-1);
  };

  useEffect(() => {
    // Remove status filter
    onFilterSubmit({first: 10, customerID: customerIDParam });
  }, [customerIDParam]);

  /**
   * Get action buttons for TwTableCardList
   * @param {number} index
   * @param {CreditLimitRequest} dataObj
   * @returns {JSX.Element[]} - Array of action buttons
   */
  const getActionButtons = (index: number, dataObj: CreditLimitRequest): JSX.Element[] => {
    return [
      <ActionButton key={"clr-action-btn-"+index} dataObj={dataObj} fn={handleCLRClick} text={t("common:buttons.details")} />
    ];
  };

  // Table headers and sort fields for TwTableCardList
  const columnHeaderValues = getColumnHeaderValues(getColumns, intl, t, handleCLRClick);

  return (
    <div
      className="mf-clr-main-container mf-flex-y-fill mt-6"
    >
      <p className="mf-text-primary text-lg font-semibold mx-4 mt-2 mb-2">
        {customerNameParam}
      </p>
      <div className="">
        {
          company?.companytype === CompanyType.Serviceprovider && customerIDParam &&
          <div className="mx-4 flex flex-wrap gap-4 items-center justify-between mb-4">
            <FramerButton
              onClick={handleNewRequestClick}
              className="mf-btn-action-large-primary-filled"
            >
              {t("translation:customeroverview.labels.newRequest")}
            </FramerButton>

            <FramerButton
              className="mf-btn-action-small-primary-outline"
              onClick={handleBackClick}
            >
              {t("common:buttons.back")}
            </FramerButton>
          </div>
        }
        <CLRFilterComponent
          onFilterSubmit={onFilterSubmit}
        />
      </div>

      <Pagination<CreditLimitRequestEdge, GetPaginatedCreditLimitRequestsQuery, GetPaginatedCreditLimitRequestsQueryVariables>
        ref={paginationRef} props={{queryHook: useGetPaginatedCreditLimitRequestsQuery, paginationName, className: "mx-4 mb-4"}}
      >
        {(dataSlice, loading, displayAmount) => {
          return (
            <PaginationContentScrollable ref={scrollRef}>
              <div className="mx-4">
                {
                  mdBreakpoint ?
                    <DataTable
                      loading={loading}
                      displayAmount={displayAmount as number}
                      data={dataSlice.map(edge => edge.node) ?? []}
                      columns={getColumns(intl, t, handleCLRClick)}
                    />
                    :
                    <TwTableCardList
                      dataArray={dataSlice.map(edge => edge.node)}
                      displayAmount={displayAmount as number}
                      actionButtons={getActionButtons}
                      defaultSortField="createdAt"
                      tableHeaders={columnHeaderValues}
                      dataType="CreditLimitRequest"
                      dataLoading={loading}
                    />
                }
              </div>
            </PaginationContentScrollable>
          );
        }}
      </Pagination>

      {/* Modals */}
      {
        showModal === CLRPageModals.NewRequest &&
          <CreditLimitNewRequest
            setShowModal={setShowModal}
            serviceProviderID={user!.employer._id}
            clrInput={input}
            setInput={setInput}
          />
      }
      {
        showModal === CLRPageModals.CLRDetails &&
          <CLRDetailsModal
            setShowModal={setShowModal}
            currentCLR={currentCLR as CreditLimitRequest}
          />
      }

      <ScrollToTopButton scrollToTop={scrollToTop} isVisible={isVisible} />
    </div>
  );
}

export default CreditLimitRequestPage;
