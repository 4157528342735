import { useLoginUserLazyQueryHook } from "@/hooks/queries";
import { LoginDefaultValues, LoginSchema, TLoginSchema } from "@/models/Login";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { PreloginLayout, PreloginLayoutContent, PreloginLayoutFooter } from "@/layouts/PreloginLayout";
import { FormField } from "@/types";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import FormInput from "@/components/shared/form/FormInput";
import { onInvalid } from "@/utils/form";
import { FramerButton } from "@/components/shared";

// Constants
const inputFields: FormField[] = [
  {
    placeholder: "Enter your email",
    type: "email",
    required: true,
    name: "mail",
    title: "Email",
    className: "mf-input-field",
    autoComplete: "email"
  },
  {
    placeholder: "Enter your password",
    type: "password",
    required: true,
    name: "password",
    title: "Password",
    className: "mf-input-field",
    autoComplete: "current-password"
  },
];

function Login() {

  // Init form object
  const { handleSubmit, register, formState: { errors }} = useForm<TLoginSchema>({
    resolver: zodResolver(LoginSchema),
    defaultValues: LoginDefaultValues,
    mode: "onChange"
  });

  const hasErrors = Object.keys(errors).length > 0;

  // Hooks
  const navigate = useNavigate();
  // Need to use "useLazyQuery" as only this hook and useMutation
  // return a function that can be executed on button press
  const { loginQuery, loading, error } = useLoginUserLazyQueryHook();

  /**
   * OnSubmit handler function for the form
   *
   * @param {TLoginSchema} data
   */
  const onSubmit: SubmitHandler<TLoginSchema> = (data: TLoginSchema) => {
    loginQuery({ variables: data });
  };

  return (
    <PreloginLayout>
      {/* Form container */}
      <PreloginLayoutContent>
        {/* Header wrapper */}
        <div className="mb-4">
          <p className="text-xl md:text-2xl font-semibold mb-1">Welcome back</p>
          <p className="text-sm md:text-base">Please enter your details to sign in.</p>
        </div>
        <form
          className="flex flex-col gap-2"
          onSubmit={handleSubmit(onSubmit, onInvalid)}
          id="loginForm"
        >
          {
            inputFields.map((field: FormField) => (
              <FormInput
                key={`login-input-${field.name}`}
                field={field}
                errors={errors}
                register={register}
              />
            ))
          }

          <FramerButton
            data-cy="signInButton"
            className={`
              rounded-sm mf-primary text-white mf-button w-full ${loading ? "bg-opacity-50 cursor-wait" : "bg-mfpurple hover:bg-opacity-70"}
              ${hasErrors ? "cursor-not-allowed bg-gray-400" : ""}`}
            type={hasErrors ? "button" : "submit"}
            disabled={hasErrors ? true : false}
          >
            {
              loading ?
                <div className="flex justify-center items-center">
                  <div className="w-4 h-4 border-2 border-gray-400 border-t-white mr-2 rounded-full animate-spin" />
                  Signing In
                </div>
                : <>Sign In</>
            }
          </FramerButton>

          {error && <p className="flex items-center text-red-500 mt-2"><ExclamationTriangleIcon className="w-5 h-5 mr-2" />{error.message}</p>}

          {/* Forgot password */}
          <p data-cy="forgotPasswordLink" className="mt-4 text-sm md:text-base">
            <span onClick={() => {navigate("/forgotpassword");}} className="text-blue-400 cursor-pointer">Forgot password?</span>
          </p>
        </form>
      </PreloginLayoutContent>

      {/* Request demo */}
      <PreloginLayoutFooter>
        <h1 className="text-base md:text-lg font-semibold mb-2">Don&apos;t have an account?</h1>
        <a data-cy="requestDemoButton"
          href="https://calendly.com/myron-finance/myron-demo"
          target="_blank" rel="noreferrer"
          className="inline-block rounded-sm mf-secondary text-white mf-button hover:bg-opacity-70"
        >
          Request Demo
        </a>
      </PreloginLayoutFooter>
    </PreloginLayout>
  );
}

export default Login;
