import { CompanyType } from "@/graphql/__generated__/graphql-operations";

export const emptyBlockchainData = {
  initialized: false,
  loading: true,
  balance: 0,
  serviceProvider: {
    currentlyOwedInterestAmount: 0,
    nextOwedInterestAmount: 0,
    nextPaymentDate: 0,
    interestPaymentDue: false,
    lastPaymentDate: 0,
  },
  inventoryProvider: {
    criticalInventoryValue: 0,
    isEndPaymentDue: false,
    lastInventoryValue: 0,
  },
  receivablesProvider: {
    residualCash: 0,
  },
  admin: {
    totalSupply: 0,
  },
};

export const emptyAdminState = {
  balanceOf: {address: "", output: ""},
  totalSupply: 0,
  addAuthorizedUser: { address: "", output: "" },
  removeAuthorizedUser: { address: "", output: "" },
  authorizationStatusOf: { address: "", output: "" },
  creditLimitRequestInput: {
    status: "",
    requestedAmount: 0,
    reasonForRequest: "",
    reasonForDenial: ""
  },
};

export const emptyPaginationState = {
  paginatedAssets: {
    first: 10,
    currentPage: 0,
    filters: {
      from: null,
      to: null,
      isFinanced: null,
      filter: null,
      assetfinancingpoolId: null,
    }
  },
  paginatedCreditLimitRequests: {
    first: 10,
    currentPage: 0,
    filters: {
      customerID: null,
      status: null,
      from: null,
      to: null,
    }
  },
  paginatedCustomers: {
    first: 10,
    currentPage: 0,
    filters: {
      filter: null
    }
  },
  paginatedAssetFinancingRounds: {
    first: 10,
    currentPage: 0,
    filters: {
      poolId: ""
    }
  },
  paginatedTokenTransactions: {
    first: 10,
    currentPage: 0,
    filters: {
      status: null,
      type: null,
    }
  },
  paginatedUsers: {
    first: 10,
    currentPage: 0,
    filters: {
      filter: null
    }
  },
  paginatedAssetFinancingPools: {
    first: 5,
    currentPage: 0,
    filters: {
      status: null,
      companyname: ""
    }
  },
  paginatedInvestments: {
    first: 5,
    currentPage: 0,
    filters: {
      status: null,
      companyName: ""
    }
  },
  paginatedCompanies: {
    first: 10,
    currentPage: 0,
    filters: {
      status: null,
      companytype: CompanyType.Serviceprovider
    }
  }
};

export const emptyCustomersState = {
  creditLimitRequestInput: {
    customerID: "",
    reasonForRequest: "",
    requestedAmount: 0,
    serviceproviderID: ""
  },
};

export const emptyInvestmentState = {
  serviceproviderID: "",
  amount: 0,
  pooladdress: "",
};
